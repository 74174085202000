a {
  text-decoration: none;
  color: $c_main;
  @include addTransition();
}

li {
  list-style-type: none;
}

.text-styles {

  // Alignement
  &.aligncenter {
    margin-left: auto;
    margin-right: auto;

    > * {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.alignright {
    margin-left: auto;
    margin-right: 0;

    > * {
      margin-left: auto;
      margin-right: 0;
    }
  }

  // Gestion des alignements et du max-width des éléments
  > * {
    position: relative;

    &:first-child {
      margin-top: 0 !important;
    }

    &:last-child {
      margin-bottom: 0 !important;

      .btn {
        margin-bottom: 0;
      }
    }

    &.aligncenter, &[style*="text-align: center"] {
      margin-left: auto;
      margin-right: auto;
    }

    &.alignright, &[style*="text-align: right"] {
      margin-left: auto;
      margin-right: 0;
    }

    &[style*="text-align: justify"] {
      margin-left: 0;
      margin-right: 0;
      max-width: 100% !important;
    }
  }

  .surtitre {
    @include ft($f_txt, $s_p, 600, $c_main, 1.11);
    text-transform: uppercase;
    display: block;
    margin-top: 35px;
    margin-bottom: 12px;
    @include letter-spacing(100);

    @include mobile {
      font-size: 14px;
      line-height: 20px;
    }

    & + h2,
    & + .title-h2 {
      margin-top: -7px;

      @include mobile {
        margin-top: -7px;
      }
    }

    & + h3,
    & + .title-h3 {
      margin-top: 0;
    }
  }

  // Styles de textes
  h1, .title-h1 {
    position: relative;
    display: block;
    margin: 0 0 15px 0;
    @include ft($f_hx, $s_h1, 700, $c_txt, 70px);

    & + p {
      margin-top: 10px;
    }

    @include tablet-portrait {
      font-size: 50px;
      line-height: 0.8;
    }

    @include mobile {
      font-size: 40px;
      line-height: 0.88;
      margin-bottom: 10px;
    }
    @include mobile-small {
      font-size: 30px;
      line-height: 0.93;
      margin-bottom: 10px;
    }
  }

  h2, .title-h2 {
    position: relative;
    @include ft($f_hx, $s_h2, 700, $c_txt, 55px);
    display: block;
    margin-top: 35px;
    margin-bottom: 20px;

    @include tablet-portrait {
      font-size: 40px;
      line-height: 0.95;
    }
    @include mobile {
      font-size: 30px;
      line-height: 1;
    }

    @include mobile-small {
      font-size: 28px;
      line-height: 0.82;
    }

    & + .surtitre {
      margin-top: -5px;
      margin-bottom: 25px;

      @include mobile {
        margin-bottom: 20px;
      }
    }

    & + h3,
    & + .title-h3 {
      margin-top: -10px;

      @include tablet-portrait {
        margin-top: 0;
      }
    }

    & + .btn-primary,
    & + .btn {
      margin-top: 10px;
    }
  }

  h3, .title-h3 {
    position: relative;
    @include ft($f_hx, $s_h3, 700, $c_txt, 1);
    display: block;
    margin-top: 35px;
    margin-bottom: 16px;
    font-style: italic;

    @include tablet-portrait {
      font-size: 28px;
      line-height: 0.9;
    }
    @include mobile {
      font-size: 22px;
      line-height: 24px;
    }
    @include mobile-small {
      font-size: 20px;
      line-height: 22px;
    }

    & + .surtitre {
      margin-top: -11px;
      margin-bottom: 25px;

      @include tablet-portrait {
        margin-top: -10px;
      }
      @include mobile {
        margin-bottom: 20px;
      }
    }

    & + .btn-primary, & + .btn {
      margin-top: 10px;
    }
  }

  p:not([class]) {
    @include ft($f_txt, $s_p, 400, $c_txt, 26px);
    display: block;
    margin-bottom: 18px;

    @include mobile-small {
      font-size: 14px;
      line-height: 1.43;
    }

    & + .btn-primary,
    & + .btn {
      margin-top: 10px;
    }
  }

  /*li:not([class]) {
      max-width: 800px;

      &[style*="text-align: center"] {
          margin-left: auto;
          margin-right: auto;
      }

      &[style*="text-align: right"] {
          margin-left: auto;
          margin-right: 0;
      }
  } */

  ul:not([class]) {
    padding: 0;
    margin-bottom: 15px;
    list-style-type: none;

    li {
      @include ft($f_txt, $s_p, 300, $c_txt, 24px);
      position: relative;
      margin: 15px 0;
      padding: 0 0 0 30px;

      @include mobile {
        margin: 10px 0;
        padding-left: 20px;
      }

      &[style*="text-align: right"] {
        padding: 0 30px 0 0;

        @include mobile {
          padding-right: 20px;
        }

        &:before {
          left: auto;
          right: 0;
        }
      }

      &[style*="text-align: center"] {
        padding: 7.5px 0;
        margin: 0;

        &:before {
          display: none;
        }

        &:after {
          content: "";
          display: block;
          width: 12px;
          height: 2px;
          background: $c_main;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          bottom: -7px;
        }

        &:last-of-type {
          &:after {
            display: none;
          }
        }
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 2px;
        height: 100%;
        background: $c_main;
      }
    }
  }

  ol:not([class]) {
    padding: 0;
    margin-bottom: 15px;
    list-style-position: inside;

    li {
      @include ft($f_txt, $s_p, 300, $c_txt, 1.5);
      position: relative;
      padding: 7.5px 0;
      list-style-type: decimal;

      &::marker {
        color: $c_main;
      }
    }
  }

  a:not([class]) {
    color: $c_main;
    text-decoration: underline;

    &:hover, &:focus {
      body:not(.no-hover) & {
        color: darken($c_main, 10%);
      }
    }
  }

  blockquote {
    @include ft($f_txt, $s_p, 300, $c_txt, 26px);
    position: relative;
    margin: 35px 0 35px 0;
    border-left: 0;

    @include mobile {
      margin: 30px 0;
    }

    &:before {
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      background-color: $c_main;
      position: absolute;
      left: 0;
      top: 0;
    }

    p {
      @include ft($f_txt, $s_p, 300, $c_txt, 26px);
      position: relative;
      padding: 0 12px 0 30px;

      @include mobile {
        padding-left: 20px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .btn {
    margin-top: 15px;
    margin-bottom: 20px;

    @include mobile {
      margin-bottom: 5px;
    }
  }


  // Gestion iFame
  iframe:not(.instagram-media) {
    width: 100%;
    height: 430px;

    @include mobile {
      height: 100%;
    }
  }

  .instagram-media {
    margin: 30px auto !important;
  }

  // Gestion des éléments dans le Wysiwyg
  p, .wp-caption {
    margin-bottom: 20px;

    iframe {
      margin-bottom: 0;
    }

    &.alignleft {
      float: left;
      margin: 15px auto;
      margin-right: 40px;

      @include mobile {
        float: none;
        margin: 10px auto;
      }
    }

    &.aligncenter {
      display: block;
      margin: 30px auto;
    }

    &.alignright {
      float: right;
      margin: 30px auto;
      margin-left: 40px;

      @include mobile {
        float: none;
        margin: 10px auto;
      }
    }

    &.alignnone {
      margin: 30px 0;
    }

    img {
      height: auto;
      margin: 15px auto;
      border-radius: $r_small;

      &.alignleft {
        float: left;
        padding-right: 40px;

        @include mobile {
          float: none;
          margin: 10px 0;
          padding: 0;
        }
      }

      &.aligncenter {
        display: block;
        margin: 30px auto;
      }

      &.alignright {
        float: right;
        padding-left: 40px;

        @include mobile {
          float: none;
          margin: 10px 0;
          padding-left: 0;
        }
      }
    }
  }

  .wp-caption {
    max-width: 100%;
    img {
      width: 100%;
      margin: 0;
    }
  }
  //Les images du wysiwyg (caption, pas caption peut-importe
  img{
    max-width: 100%;
    height: auto;
  }
}


// Additionals
// ==========================================================================

.location {
  @include ft($f_txt, 14px, 400, $c_white, 24px);
  position: relative;
  display: block;
  padding-left: 19px;

  &:before {
    @include icomoon($icon-ico-boussole);
    font-size: 14px;
    color: $c_white;
    position: absolute;
    top: 6px;
    left: 0;
  }
}


.auteur {
  @include ft($f_txt, 14px, 400, $c_white, 24px);
  position: relative;
  display: block;
  padding-left: 19px;

  &:before {
    @include icomoon($icon-ico-person);
    font-size: 14px;
    color: $c_white;
    position: absolute;
    top: 6px;
    left: 0;
  }
}