// Fil d'ariane
// ==========================================================================
.breadcrumbs {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin: 34px 0 30px 0;
	text-overflow: ellipsis;

	@include tablet-portrait {
		margin-top: 20px;
	}
	@include mobile {
		margin: 15px 0;
	}

	.breadcrumbs__item {
		position: relative;
		padding: 0;

		&:not(:last-of-type) {
			&:after {
				@include icomoon();
				content: $icon-ico-arrow-right-slider;
				position: absolute;
				right: 12px;
				top: 50%;
				font-size:7px;
				display: inline-block;
				color:$c_white;
				vertical-align: middle;
				transform: translateY(-50%);
			}
		}

		&:first-of-type {
			.breadcrumbs__link {
				padding-left: 0;
			}
		}

		&:last-of-type {
			.breadcrumbs__link {
				padding-right: 0;
			}
		}

		.breadcrumbs__link,
		span {
			position: relative;
			@include ft('', 14px, 400, rgba($c_txt, 0.50), 24px);
			display: inline-block;
			padding: 0 35px 0 0;
			white-space: nowrap;
		}

		.breadcrumbs__link {
			@include addTransition();

			@include can-hover {
				color: $c_main;
			}
		}

		a {
			color: $c_txt;
		}
	}


	// --modifiers
	&.--text-white {
		.breadcrumbs__item {
			&:after {
				color: $c_white;
			}

			.breadcrumbs__link {
				color: $c_white;
				a {
					color: $c_white;
				}
				@include can-hover {
					color: $c_main;
				}
			}

			span {
				color: rgba($c_white, 0.50);
			}
		}
	}
}
