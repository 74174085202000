// Container avec colonnes
// NOTE IMPORTANTE : Vu qu'on peut avoir deux tailles de gouttières dans le site (mais dans votre projet vous pouvez même avoir plus)
// On a décidé d'avoir une mixin qui prend comme argument la taille de la gouttière
// On a la premiere taille utilisé par défaut partout dès qu'on a un wrapper-columns
// Et une deuxième taille dès qu'on a la classe "small-gap"
// La mixin utilisé est dans le fichier scss "mixins"
// ==========================================================================
.wrapper-columns:not(.small-gap) {
	@include wrapper-columns($grid-gap);
}

// Si on a la classe "small-margin" au même niveau que le "wrapper-columns" on utilise la variable $small-margin que dans le framework est égale à 20px.
// L'objectif est d'avoir les mêmes calculs mais effectués avec une taille de gouttière plus petite
.wrapper-columns.small-gap {
	@include wrapper-columns($small-margin);
}

// Grille en colonnes
// ==========================================================================
.col-16, .col-20, .col-25, .col-33, .col-50, .col-75, .col-66, .col-100 {
	display: block;
	box-sizing: border-box;
}

.col-20 {
	width: calc(20% - 20px);
	@include tablet-landscape {
		width: calc(25% - 20px);
	}
}

.col-25 {
	width: 25%;
}

.col-33 {
	width: 33.33333%;
	@include tablet-small {
		width: 50%;
	}
}

.col-50 {
	width: 50%;
}

.col-66 {
	width: 66.66666%;
	@include tablet-small {
		width: 50%;
	}
}

.col-75 {
	width: 75%;
}

.col-100 {
	width: 100%;
}

@include tablet-portrait {
	.col-25, .col-75 {
		width: 50%;
	}
}

@include mobile {
	.col-20, .col-25, .col-33, .col-50, .col-66, .col-75 {
		float: none;
		clear: both;
		margin: 0 auto;
		width: 100%;
	}
}