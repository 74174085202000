.overlay-carte-interactive {
    max-width: 100vw;

    .btn-filtres, .btn-close-overlay {
        @include ft($f_txt, 14px, 400, $c_white, 20px);
        display: flex;
        align-items: center;
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 20px 40px 20px 40px;
        background: $c_txt;
        z-index: 2;
        cursor: pointer;
        border-radius: 0;
        border: none;

        @include mobile {
            padding: 17px;
        }

        span {
            @include mobile {
                display: none;
            }
        }

        &:before {
            color: $c_white;
            margin-right: 10px;
            @include mobile {
                font-size: 10px;
                margin-right: 0;
            }
        }

        &:hover, &:active {
            background: $c_main;
        }
    }

    .btn-close-overlay {
        &:before {
            @include icomoon($icon-ico-close);
        }
    }

    .btn-filtres {
        @include ft($f_txt, 14px, 400, white, 20px);
        top: auto;
        bottom: 20px;

        &:before {
            @include icomoon($icon-ico-filtres);
        }
    }

    &__map {
        position: relative;
        height: 100%;
        width: 100%;
        z-index: 1;

        .maps {
            width: 100%;
            height: 100%;

            .leaflet-marker-pane {

                .leaflet-marker-icon {
                    width: 33px !important;
                    height: 33px !important;
                }
            }
        }
    }

    &__content {
        width: 500px;
        height: 100%;
        background: white;
        padding: 40px;
        position: absolute;
        z-index: 3;
        top: 0;
        right: 0;
        box-shadow: -10px 0px 25px -15px rgba(0, 0, 0, 0.25);
        transition: transform 250ms ease-in-out;
        transform: translateX(0%);

        @include mobile {
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
        }

        .content__header {
            width: 100%;
            display: flex;
            position: relative;
            justify-content: space-between;

            @include mobile {
                align-items: center;
                flex-direction: row-reverse;
            }

            .btn-close {
                position: relative;
                top: auto;
                right: auto;
                opacity: 1;
                transition: opacity 250ms linear;
            }

            .title-h2 {
                font-size: 38px;
                color: $c_txt;
                margin: 0;

                @include mobile {
                    font-size: 32px;
                }
            }
        }

        .content__form {
            margin-top: 40px;
            max-height: calc(100% - 65px);
            overflow-y: auto;
            margin-right: -40px;
            padding-right: 40px;

            fieldset {
                margin-bottom: 30px;
                position: relative;

                @include mobile {
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                }

                &:last-of-type {
                    margin-bottom: 0;
                    border-bottom: none;
                }

                legend {
                    position: relative;
                    width: 100%;
                    cursor: pointer;
                    border: 1px solid rgba($c_main, 0.3);
                    padding: 30px 40px 30px 40px;
                    border-radius: 4px;

                    &:after {
                        @include icomoon($icon-ico-arrow-down);
                        position: absolute;
                        right: 20px;
                        top: 50%;
                        font-size: 10px;
                        color: $c_txt;
                        transform: rotate(0deg) translateY(-50%);
                    }
                }

                i {
                    font-style: normal;
                }

                .frm_checkbox {
                    display: none;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 40px;

                    label {
                        flex-shrink: 1;
                        padding-left: 40px;
                    }

                    figure {
                        flex-shrink: 0;
                        height: 33px;
                        width: 33px;
                        background: none;

                        img {
                            height: 100%;
                            width: auto;
                            max-width: unset;
                        }
                    }
                }

                &.is-active {
                    legend {
                        margin-bottom: 10px;

                        &:after {
                            top: calc(50% - 8px);
                            transform: rotate(180deg) translateY(-50%);
                        }
                    }

                    .frm_checkbox {
                        display: flex;
                    }
                }
            }
        }

        &.is-hidden {
            transition: transform 250ms ease-in-out;
            transform: translateX(100%);

            .btn-close {
                transition: opacity 250ms linear;
                opacity: 0;
            }
        }
    }
}