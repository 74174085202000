#pagination {
	display: flex;
	align-items: center;
	margin-top: 50px;
	text-align: center;
	clear: both;
	background: $c_white;
	flex-wrap: wrap;
	justify-content: space-between;

	@include mobile {
		justify-content: center;
		padding-top: 40px;
		text-align: center;
		margin-top: 40px;
	}

	&:empty {
		display: none;
	}

	> a, strong, span {
		@include ft($f_txt, 10px, 900, $c_txt, 1.5);
		position: relative;
	}

	.btn-square {
		font-size: 0;
		z-index: 4;
		&:before {
			font-size:12px;
		}

		&.prev {
			&:before {
				content: $icon-ico-arrow-left-slider;
			}
		}

		&.next {
			&:before {
				content: $icon-ico-arrow-right-slider;
			}
		}

		@include can-hover {
			cursor: pointer;
		}
	}

	.pagination-right {
		display: flex;
		align-items: center;
	}

	.pagination-number {
		position: relative;
		z-index: 10;

		> * {
			padding: 0 14px;
			font-size: 16px;
			font-weight: 400;
			color: $c_txt;
			text-align: center;
			display: inline-block;
			vertical-align: middle;

			@include mobile {
				padding: 0 7px;
			}
			@include mobile-small {
				padding: 0 2px;
			}

			&.active {
				color: $c_main;
				font-weight: 700;
			}
		}
		a {
			margin: 0;
			@include addTransition();

			&:hover, &:focus {
				body:not(.no-hover) & {
					color: $c_main;
				}
			}
		}

		> span {
			vertical-align: bottom;
		}
	}

	.resultats {
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;

		@include mobile {
			width: 100%;
			flex-shrink: 0;
			margin-bottom: $small-margin;
		}

		span {
			font-size: 16px;
			line-height: 24px;
			font-weight: 400;
		}
	}
}