// Langues
// ==========================================================================
// Dropdown en css (donc pas un select field) des langues du site
.lang-dropdown {
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  a {
    @include ft($f_txt, $s_link_small, 700, $c_white, 17px);
    text-align: center;
    text-transform: uppercase;
    padding: 0 10px;
    display: block;

    &:hover, &:focus {
      color: $c_main;
    }
  }

  > a {
    font-weight: 600;
    &:after {
      @include icomoon($icon-ico-arrow-down);
      display: inline-block;
      margin-left: 6px;
      font-size: 10px;
    }
  }

  &.one-language {
    > a {
      &:after {
        content:"";
      }
    }
  }


  ul {
    display: flex;
    align-items: center;
    background: $c_white;
    width: auto;
    padding: 0 0;
    overflow: hidden;
    transform-origin: top;
    height: 100%;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 100%;
    @include addTransition();

    @include mobile {
      flex-direction: column;
      transform: scaleY(0);
      transform-origin: top;
      opacity: 0;
      position: absolute;
      left: 0;
      top: 90%;
    }

    li {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;

      @include mobile {
        height: 40px;
      }

      a {
        color:$c_txt;
        &:hover {
          color: $c_main;
        }
      }
    }

    &.open {
      opacity: 1;
      transform: scaleY(1);
    }
  }
}

.lang-select {
  position: relative;
  display: none;
  color: $c_txt;
  &:after {
    @include icomoon($icon-ico-arrow-down);
    font-size: 7px;
    font-weight: 700;
    color: $c_sub;
    display: block;
    position: absolute;
    right: 10px;
    top: 46%;
  }

  @include tablet_portrait {
    display: none;
  }

  .selectric-wrapper {
    position: relative;
    @include ft($f_txt, 14px, 700, $c_txt, 14px);
    text-transform: capitalize;
    height: 100%;
    text-align: center;
    padding: 0 10px 0 0;

  }
  #lang-dropdown-mobile {
    text-transform: capitalize;
  }
}
