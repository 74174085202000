// Style General - Overlay - All site
// ==========================================================================
.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: z-index('overlay');
	overflow: hidden;
	width: 100vw;
	max-width: 800px;
	background-color: $c_white;
	transition: transform 400ms $transition-overlay-easing, visibility 400ms $transition-overlay-easing;
	visibility: hidden;
	transform: translateX(100%);

	@include mobile {
		max-width: 100%;
	}

	&:not(.overlay-absolute) {
		.overlay-load & {
			display: block;
		}
	}

	&.open {
		visibility: initial;
		transform: initial;
	}

	// Arrivée du haut de l'écran
	&.--from-top {
		transform: translateY(-100%);

		&.open {
			transform: initial;
		}
	}

	// Arrivée du bas de l'écran
	&.--from-bottom {
		transform: translateY(100%);

		&.open {
			transform: initial;
		}
	}

	.error-container {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 75%;
		transform: translate(-50%, -50%);
		text-align: center;
		z-index: 999999;

		@include mobile {
			width: 100%;
		}
	}

	.scroller {
		padding: 40px 80px 80px 80px;
		height: 100%;
		overflow-y: auto;

		@include mobile {
			padding: 40px 40px 40px 40px;
		}
		@include mobile {
			padding: 40px 20px 40px 20px;
		}
	}

	.title-overlay {
		@include ft($f_hx, 40px, 700, $c_txt, 1);

		+ * {
			margin-top: 40px;
		}
	}

	.btn-close {
		position: absolute;
		top: 40px;
		right: 40px;
		z-index: 10;

		@include mobile {
			top: 20px;
			right: 20px;
		}
	}

	// layout général des overlays
	.overlay__inner {
		--padding-horizontal: 40px;
		display: flex;
		flex-direction: column;
		height: 100%;

		@include mobile {
			--padding-horizontal: 20px;
		}
	}

	.overlay__footer {
		display: flex;
		justify-content: space-between;
		padding: var(--padding-horizontal);
		box-shadow: 10px 0 20px rgba(#EBE4DA, 0.30);
	}

	.overlay__body {
		flex-grow: 1;
		overflow: auto;
		padding: var(--padding-horizontal);
	}

	.btn-reset {
		@include ft('', $s_p, 300, $c_txt, 1.25);
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 10px 0;
		cursor: pointer;
		@include addTransition();

		@include mobile {
			font-size: 13px;
		}

		@include can-hover {
			&:before {
				transform: rotate(180deg);
			}
		}

		&:before {
			@include icomoon($icon-ico-refresh);
			display: block;
			margin-right: 10px;
			font-size: 20px;
			color: $c_txt;
			@include addTransition();
		}
	}

}

// Overlay - focus SIT
// ==========================================================================
.overlay-focus-sit {
	.barre {
		position: sticky;
		bottom: 0;
		left: 0;
		right: 0;
		padding-right: 40px;
		display: flex;
		align-items: center;
		min-height: 100px;
		background-color: $c_white;
		box-shadow: 0 -30px 60px rgba($c_dark, 0.10);

		@include mobile {
			padding-right: 20px;
			min-height: 50px;
		}

		[data-content] {
			position: relative;
			width: 60px;
			height: 60px;
			border: 1px solid $c_sub;
			margin-right: 10px;
			display: flex;
			justify-content: center;
			align-items: center;

			@include mobile {
				width: 30px;
				height: 30px;
			}

			&:before {
				content: "";
				@include icomoon();
				display: block;
				color: $c_dark;
				font-size: 20px;

				@include mobile {
					font-size: 12px;
				}
			}

			&:hover, &:focus {
				body:not(.no-hover) & {
					.tooltiptext {
						visibility: visible;
					}
				}
			}

			&[data-content="telephone"] {
				&:before {
					content: $icon-ico-phone;
				}
			}

			&[data-content="email"] {
				&:before {
					content: $icon-ico-email;
				}
			}

			&[data-content="site"] {
				&:before {
					content: $icon-ico-link;
				}
			}
		}

		.lien {
			max-width: 248px;
			padding: 42px 70px 42px 80px;
			font-size: 16px;
			white-space: nowrap;
			margin-right: auto;

			@include tablet-portrait {
				padding: 42px 40px 42px 40px;
			}
			@include mobile {
				font-size: 12px;
				padding: 22px 20px 22px 20px;
			}
		}

		.btn, .btn-primary, .btn {
			max-width: 142px;

			@include mobile {
				position: absolute;
				bottom: 56px;
				width: 100%;
				max-width: none;
				height: auto;
				font-size: 12px;
			}
		}
	}
}


// Overlay - map
// ==========================================================================
.overlay-map {
	max-width: none;

	.btn-close {
		z-index: 450;
	}

	// cards
	.wrapper-cards {
		padding: 30px 0;
		background-color: $c_white;
		position: relative;
		height: 200px;

		.th-slider {
			padding: 0 40px;
		}

		.card {
			max-width: 380px;
			margin-right: 20px;
			margin-bottom: 20px;
		}
	}

	.th-slider {
		@include min-mobile {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.nav-buttons {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		z-index: 9999;
		transform: translate(0, -50%);

		button {
			position: absolute;
			background-color: $c_white;
			width: 64px;
			height: 64px;
			border-radius: 50%;
			transform: translate(0, -50%);
			display: flex;
			align-items: center;
			justify-content: center;

			&:first-child {
				left: 0;
			}

			&:last-child {
				right: 0;
			}
		}
	}

	// map
	.maps {
		height: calc(100% - 200px);
	}
}


// Overlay - Filtres
// ==========================================================================
.overlay-filtres {
	overflow: initial; // pour corriger un bug sur les classements et les switch

	// styles communs des groupes de filtres
	.group-filtres, .group-date {
		padding-bottom: 40px;
		margin-bottom: 40px;
		border-bottom: 1px solid rgba($c_main, 0.30);

		@include mobile {
			padding-bottom: 20px;
			margin-bottom: 20px;
		}

		&:last-of-type {
			margin-bottom: 0;
			border-bottom: none;
		}
	}

	.group-switch {
		+ .group-switch, & {
			border-bottom: 0;
			padding: 0;

			@include min-mobile {
				margin-bottom: 30px;
			}
		}
	}

	.group-dates {
		position: relative;

		.group-checkbox {
			display: none;
		}

		.date-picker-wrapper {
			position: absolute;
			left: 0;
			top: 80px;
			margin-right: 0;
			box-shadow: 5px 5px 20px rgba($c_dark, 0.2);
			padding: 0 20px 20px;

			@include mobile {
				transform: scale(0.65);
			}


			// règles pour forcer la largeur à rentrer dans l'overlay filtres
			.gap {
				visibility: hidden !important;
			}

			.month-wrapper {
				width: 660px !important;
				display: flex;

				.time {
					display: none;
				}
			}

			.month-wrapper table {
				width: calc(50% - 15px);
			}

			.month-wrapper table .day {
				padding: 8px;
			}
		}

	}

	.group-checkbox {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}


	/* Facette Select */
	.group-select {
		.selectric-wrapper {
			max-width: 100%;
		}

		.select {
			.selectric-open {
				z-index: 10;
			}
		}
	}
}


// Overlay - Agenda
// ==========================================================================
.overlay-agenda {
	.container {
		overflow-y: auto;
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
	}

	.scroller {
		padding: 40px;
		@include mobile {
			padding: 20px;
		}
	}

	.location {
		margin-bottom: 10px;
		margin-top: 10px;
		color: $c_txt;

		&:before {
			color: $c_txt;
		}
	}

	.dtstart {
		@include ft($f_hx, $s_medium, 700, $c_txt, 22px);
		font-style: italic;
	}

	.bottom-part {
		box-shadow: 10px 0 20px rgba(#EBE4DA, 0.30);
		background-color: $c_white;
		text-align: right;
		padding: 40px 40px 40px 80px;
		@include mobile {
			padding: 20px 40px 20px 40px;
		}
	}

	.contacts ul li a {
		display: flex;
		align-items: center;
		text-decoration: none;

		span {
			margin-right: 10px;
		}
	}
}


// Overlay - Shadow
// ==========================================================================
.shadow-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: z-index('overlay-shadow');
	background-color: rgba($c_txt, 0.5);
	opacity: 0;
	visibility: hidden;
	transition: opacity 400ms $transition-overlay-easing, visibility 400ms $transition-overlay-easing;

	&.open {
		opacity: initial;
		visibility: initial;
	}
}