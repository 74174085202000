// style barre sticky page Edito

.barre {
  left: 0;
  bottom: 25px;
  position: sticky;
  margin-top:30px;
  max-width:1090px;
  @include addTransition();
  z-index: z-index('barre-sticky');

  @include tablet-portrait {
    top:0;
    right: 0;
    bottom:auto;
    position: fixed;
    visibility:hidden;
    margin-top:0;
    opacity:0;
    @include addTransition();

    .is-scrolled & {
      visibility:visible;
      opacity:1;
      @include addTransition();
    }
  }


  .container {
    display: flex;
    align-items: center;
    background-color: $c_bg;
    border-radius: 0 $r_small $r_small 0;
    height: 86px;
    padding: 32px 40px 32px 80px;

    @include tablet-portrait {
      background-color: $c_bg;
      padding-left: 40px !important;
      padding-right: 40px !important;
      height: 80px;
      border-radius:0;
    }

    @include mobile {
      border-radius: 0;
      height: 70px;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }

  .barre-sticky__inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items:center;
    margin-left:auto;
    margin-right: 0;
    flex-shrink: 0;
  }



  // buttons
  .btn-favoris {
    position: relative;
    display: block;
    color: $c_white;
    padding: 20px;

    @include mobile {
      padding: 20px 10px 20px 10px;
    }

    .tooltiptext {
      top: calc(-100% + 40px);
    }

    &:hover, &:focus {
      body:not(.no-hover) & {
        color: $c_main;

        .tooltiptext {
          visibility: visible;
        }
      }
    }
  }

  .social-share {
    margin-left: 15px;

    .toggle {
      color: $c_white;
    }

  }
}
