// Styles générales pour le bouton de base
button {
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
  @include addTransition();

  &:focus {
    outline: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

// Styles pour la classe btn et btn-primary.
.btn {
  display: inline-block;
  width: auto;
  @include ft($f_txt, $s_p, 500, $c_white, 1.25);
  padding: 10px 25px;
  background-color: $c_main;
  min-height: 42px;
  border-radius: 50px;
  border: 1px solid transparent;
  text-decoration: none;
  max-width: 100%;
  //min-width: 100px;
  text-align: center;
  @include addTransition();

  @include mobile-small {
    padding: 12px 10px;
    min-height: 40px;
  }

  &:hover, &:focus {
    body:not(.no-hover) & {
      cursor: pointer;
      background-color: darken($c_main, 10%);
      border-color: darken($c_main, 10%);
    }
  }

  // btn font blanc avec une bordure noire
  &.btn-primary {
    background-color: $c_white;
    border: 1px solid $c_bg;
    color: $c_txt;

    &:hover, &:focus {
      body:not(.no-hover) & {
        cursor: pointer;
        color: $c_white;
        background-color: $c_main;
        border-color: $c_main;
      }
    }
  }


  // btn transparant
  &.btn-secondary {
    background-color: transparent;
    border: 1px solid $c_white;
    color: $c_white;

    &:hover, &:focus {
      body:not(.no-hover) & {
        color: $c_txt;
        background-color: $c_white;
        border: 1px solid $c_white;
      }
    }
  }


  & + .btn {
    margin-top: 0;
  }

}

// Styles pour le bouton des favoris
.btn-favoris {
  position: absolute;
  padding: 20px;
  font-size: 20px;
  color: $c_white;
  z-index: 5;
  cursor: pointer;

  &:hover, &:focus {
    body:not(.no-hover) & {
      color: $c_main;
    }
  }

  &.favourite {
    &:before {
      content: $icon-ico-coeur-rempli;
    }
  }
}

.btn-filtres {
  @extend .btn;
  @extend .btn-secondary;
  min-height: initial;

  .filter-counter:not(:empty) {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $c_main;
    padding: 2px;
    @include ft($f_txt, 8px, bold, $c_white, 10px);
    text-align: center;
    position: absolute;
    right: -10px;
    top: 50%;
    border-radius: 50%;
    transform: translateY(-50%);
    border: 1px solid transparent;
    @include addTransition();
  }
}

.btn-carte {
  @extend .btn;
  min-height: initial;
}


// Bouton pour ouvrir le menu de navigation fullscreen
.btn-menu {
  margin: 0 auto;
  width: 100%;
  color: $c_white;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include tablet-portrait {
    flex-direction: row;
  }

  &:hover, &:focus {
    body:not(.no-hover) & {
      cursor: pointer;
      color: $c_txt;

      .btn-menu-titre {
        color: $c_txt;
      }
    }
  }

  [class^="icon-"], [class*=" icon-"] {
    transition: none;
    @include tablet-portrait {
      padding: 0 !important;
      padding-right: 8.5px !important;
      font-size: 14px !important;
    }
  }

  .btn-menu-titre {
    text-transform: uppercase;
    padding-top: 8px;
    @include ft($f_txt, 10px, bold, $c_white, 14px);
    @include tablet-portrait {
      padding-top: 0;
    }

    @include mobile-small {
      display: none;
    }
  }

}

// style pour le btn rond : réseaux sociaux
.btn-plus {
  width: 64px;
  height: 64px;
  color: $c_white;
  display: flex;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  background-color: $c_main;
  justify-content: center;

  &:before {
    @include icomoon();
    content: $icon-ico-plus;
  }

  &.btn-plus-secondary {
    background-color: $c_bg;
  }
}


// style pour le btn rond : réseaux sociaux
.btn-round {
  position: relative;
  width: 40px;
  height: 40px;
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid $c_main;
  color: $c_main;
  @include addTransition();

  @include mobile {
    width: 40px;
    height: 40px;
  }

  [class^="icon-"], [class*=" icon-"] {
    display: block;
    font-size: 16px;
    @include addTransition();
  }

  &:hover, &:focus {
    &:not([disabled]) {
      body:not(.no-hover) & {
        border-radius: 50%;
        color: $c_white;
        cursor: pointer;
        background-color: $c_main;

        [class^="icon-"], [class*=" icon-"] {
          color: $c_white;
        }
      }
    }
  }

}

// Styles pour les boutons et les dots utilisés dans les sliders et dans la pagination
.btn-square {
  position: relative;
  width: 60px;
  height: 40px;
  border-radius: 0;
  font-size: 0;
  z-index: z-index('btn-slider');
  @include addTransition();

  @include mobile {
    width: 40px;
    height: 40px;
  }

  &:before, &:after {
    @include icomoon();
    font-size: 16px;
    color: $c_txt;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include addTransition();

    @include mobile {
      font-size: 10px;
    }
  }

  &:hover, &:focus {
    &:not([disabled]) {
      body:not(.no-hover) & {
        cursor: pointer;

        &:before {
          color: $c_main;
        }
      }
    }
  }

}


.container-nav {
  margin-bottom: 25px;
  align-items: center;
}

.nav-buttons {
  .btn-square {
    &:first-of-type {
      &:before {
        content: $icon-ico-arrow-left-slider;
      }
    }

    &:last-of-type {
      &:before {
        content: $icon-ico-arrow-right-slider;
      }
    }

    &[disabled] {
      opacity: 0.25;
    }
  }
}

.nav-dots {
  display: flex;
  align-items: center;

  button {
    position: relative;
    width: 50px;
    height: 60px;
    margin-right: 10px;
    background: transparent;
    border-radius: 0;
    border: none;

    @include mobile {
      width: 20px;
    }

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 4px;
      background-color: rgba($c_white, 0.25);
      border-radius: 0;
      left: 0;
      margin-left: 0;
      margin-right: 0;
      transition-delay: 0ms;
    }

    &:hover {
      body:not(.no-hover) & {
        cursor: pointer;
        background-color: transparent;

        &:after {
          background-color: rgba($c_main, 0.25);
        }
      }
    }

    &.active {
      &:after {
        background-color: $c_white;
      }

    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &.dots-v2 {
    button {
      &:after {
        background-color: rgba($c_main, 0.25);
      }

      &.active {
        &:after {
          background-color: $c_main;
        }
      }
    }
  }
}

.btn-view-more {
  display: none;
  position: relative;
  width: 30px;
  height: 30px;
  background-color: $c_white;
  border: 1px solid $c_sub;
  text-align: right;
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;

  &:after {
    @include icomoon($icon-ico-arrow-down);
    font-size: 12px;
    color: $c_dark;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include addTransition();
  }

  &:hover, &:focus {
    body:not(.no-hover) & {
      background-color: $c_dark;
      border: 1px solid transparent;;

      &:after {
        color: $c_white;
      }
    }
  }
}

// Bouton pour ouvrir l'overlay recherche sur desktop et sur mobile
.btn-search {
  margin: 0 auto;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  [class^="icon-"], [class*=" icon-"] {
    font-size: 20px;
    color: $c_white;

    @include mobile {
      font-size: 14px;
    }
  }

  &:hover, &:focus {
    body:not(.no-hover) & {
      [class^="icon-"], [class*=" icon-"] {
        color: $c_txt;
      }
    }
  }
}

// BTN coeur dans le menu desktop et mobile
.btn-coeur {
  position: relative;

  .counter {
    @include ft($f_txt, 8px, bold, $c_white, 10px);
    position: absolute;
    right: 10px;
    background: $c_main;
    border-radius: 50%;
    padding: 2px;
    width: 14px;
    height: 14px;
    top: 8px;

    @include tablet-portrait {
      top: 13px;
    }

    @include mobile {
      right: 0;
    }

    &.empty {
      visibility: hidden;
    }
  }
}

.btn-close {
  @include reset-appearance;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin: 0;
  padding: 0;
  border-radius: $r_small;
  color: $c_white;
  cursor: pointer;
  background-color: $c_main;
  @include addTransition();

  &:before {
    @include addTransition();
    @include icomoon($icon-ico-close);
    font-size: 16px;
  }

  @include can-hover {
    cursor: pointer;

    background-color: darken($c_main, 10%);
  }

  @include mobile {
    width: 40px;
    height: 40px;
    &:before {
      font-size: 14px;
    }
  }

  span {
    display: block;
  }

  // btn font blanc
  &.btn-close-primary {
    background-color: $c_white;
    border: 1px solid $c_white;
    color: $c_main;

    &:hover, &:focus {
      body:not(.no-hover) & {
        cursor: pointer;
        color: $c_white;
        background-color: $c_bg;
        border-color: $c_bg;
      }
    }
  }
}