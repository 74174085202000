.bloc-carte {
	position: relative;

	/*&:after {
		content: "";
		display: block;
		width: 80px;
		height: 160px;
		background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
		position: absolute;
		right: 0;
		bottom: 50px;
		z-index: 1;
		pointer-events: none;
		@include mobile {
			bottom: 25px;
		}
	} */

	.maps {
		position: relative;
		height: 515px;
		z-index:1;

		@include mobile {
			height: 370px;
		}

		.leaflet-popup-content {
			display: flex;
			align-items: center;
			width: auto;

			.image {
				margin-bottom: 0;
				flex-shrink: 0;
			}
		}

		.leaflet-top.leaflet-right {
			@include mobile {
				top: 20px;
				right: 20px;
			}
		}
	}

	.maps-popup {
		display: none;
	}

	.nav-buttons {
		text-align:right;
		@include tablet-landscape {
			display: none;
		}
	}

	#results {
		position: relative;
		margin-top: 20px;

		.card {
			position: relative;
			max-width: 380px;
			min-height: auto;
			padding: 10px 10px 10px 0;
			margin-left: 23px;

			@include mobile {
				width: calc(100% - 40px);
			}
		}
	}

	.scroll-mode-parent {
		@include desktop {
			margin: 0;
			max-width: none;
			width: 100%;
		}
	}

	.cards-horizontales {
		padding-bottom: 20px;
		@include tablet-portrait {
			padding-bottom: 0;
		}
	}
}