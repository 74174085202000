.bloc-vente {
  background-color:$c_bg;
  padding:60px;

  @include mobile {
    padding:40px 20px;
  }

  .bloc-vente-inner {
    display:flex;


    @include mobile {
      flex-direction:column-reverse;
    }

    @include min-mobile {
      align-items:center;
      > * {
        width: 50%;
      }
    }

    .image {
      position: relative;
      max-height:265px;

      @include aspect-ratio(369, 265);

      @include min-mobile {
        max-width:369px;
      }
      @include mobile {
        width:100%;
      }

      > figure,
      > .no-photo {
        @include absolute-stretch();
        width: 100%;
        height: 100%;
        border-radius: $r_small;
      }

      img {
        border-radius: $r_small;
      }

      .no-photo {
        background-color:$c_main;
      }
    }

    .caption {
      padding-right:$grid-gap;
      @include mobile {
        padding-top: $grid-gap-mobile;
        padding-right:0;
      }

      .title-h3 {
        margin-top:0;
      }

      p, span {
        color:$c_white;
      }
    }


  }
}