.strate-switch {
	background-color:$c_bg;
	padding:20px 35px;
	border-radius:$r_small;
	@include addTransition();
	z-index: z-index('strate-switch');
	bottom:40px;
	position:fixed;
	right:16%;

	@include desktop {
		right:130px;
	}

	@include tablet-portrait {
		position: sticky;
		bottom: 0;
		right: 10px;
		left: 10px;
		width: calc(100% - 20px);
		margin-top: 40px;
		margin-bottom: 60px;
	}

	label {
		justify-content: center;
	}
}