.bloc-auteur-citation {
	blockquote {
		position: relative;
		@include ft($f_txt, $s_p, 300, $c_txt, 26px);
		margin: 35px 0 35px 0;
		border-left: 0;

		@include mobile {
			margin: 30px 0;
		}


		&:before {
			content: '';
			display: block;
			width: 2px;
			height: 100%;
			background-color: $c_main;
			position: absolute;
			left: 0;
			top: 0;
		}

		p {
			@include ft($f_txt, 20px, 300, $c_main, 26px);
			position: relative;
			font-style:italic;
			padding: 0 12px 0 30px;

			@include mobile {
				padding-left:20px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		& + span {
			margin-top: 20px;
		}
	}

	> span {
		display: block;
		width: 100%;
		text-align: left;
	}

	.author {
		@include ft($f_txt, $s_small_title, 700, $c_txt, 1.1);
	}

	.info {
		@include ft($f_txt, $s_link_small, 300, $c_txt, 1.16);
	}

	blockquote, .author, .info {
		& + .container-auteur {
			margin-top: 56px;
		}
	}

	.container-auteur {
		display: flex;
		align-items: center;

		@include tablet-portrait {
			flex-wrap: wrap;
		}

		.auteur {
			display: flex;
			align-items: center;
			flex-grow: 1;

			@include mobile {
				align-items: flex-start;
				flex-direction: column;
			}

			figure {
				flex-shrink: 0;
				background:none;

				& + .col-right {
					margin-top: $small-margin;

					@include min-mobile {
						margin-left: $small-margin;
						margin-top: 0;
					}
				}

				img {
					border-radius:50%;

					@include mobile {
						max-width:120px;
						max-height:120px;
					}
				}
			}

			.col-right {
				flex-grow: 1;
			}

			.social-links {
				margin-top: 0;
				margin-left: auto;
				@include tablet-portrait {
					margin-left: 0;
				}

				a {
					width: 40px;
					height: 40px;
					margin-top: 5px;
				}
			}

			p {
				@include ft($f_txt, 14px, 400, $c_txt, 26px);
			}

			.role {
				@include ft($f_txt, 16px, 400, $c_txt, 20px);
				margin-top:-5px;
			}

			& + .auteur {
				@include tablet-portrait {
					margin-top: $small-margin;
				}
				@include min-tablet-portrait {
					padding-left: $grid-gap;
				}
			}
		}

		.top {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			// Pour s'assurer que les liens sociaux ne soient pas attachés au texte lorsqu'ils vont automatiquement à la ligne (s'il y en a beaucoup)
			margin-top: -22px;
			@include tablet-portrait {
				flex-direction: column;
				align-items: flex-start;
			}


			> * {
				margin-top: 20px;
			}

			.text-wrapper {
				> * {
					display: block;
				}

				.author {
					@include ft($f_txt, $s_small_title, 700, $c_txt, 1.1);

					& + .surtitre {
						margin-top: 5px;
					}
				}

				.surtitre {
					margin-top: 0;
				}
			}

			& + p {
				margin-top: 20px;
			}

			.social-links {

				a {
					@include mobile {
						&:nth-child(n+8) {
							margin-top: 5px;
						}
					}

					&:hover, &:focus {
						body:not(.no-hover) & {
							color: $c_white;
						}
					}
				}

				span {
					&:before {
						@include icomoon();
						font-size: 15px;

						@include mobile {
							font-size: 12px;
						}
						@include mobile-small {
							font-size: 10px;
						}
					}
				}


				@include tablet-portrait {
					margin-top: 12px;
				}
			}
		}

		&.container-multi-auteurs {
			flex-wrap: wrap;
			align-items: flex-start;
			margin-top: 36px;
			margin-left: -10px;
			margin-right: -10px;
			@include tablet-portrait {
				flex-direction: column;
				margin-left: 0;
				margin-right: 0;
			}

			.auteur {
				flex-basis: calc(50% - 20px);
				margin-top: 20px;
				margin-right: 10px;
				margin-left: 10px;
				@include tablet-portrait {
					flex-basis: 100%;
					width: 100%;
					margin-left: 0;
					margin-right: 0;
				}

				& + .auteur {
					padding-left: 0;
				}

				.top, .text-wrapper {
					margin-top: 0;
				}

				.col-right {
					@include tablet-portrait {
						width: 100%;
					}
				}
			}

			.social-links {
				width: 100%;
				margin-top: 12px;
			}
		}
	}
}