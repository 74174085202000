.strate-footer-logos-grille {
	padding-bottom:45px;

	@include mobile {
		padding-bottom:30px;
	}

	.grid-logos {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		--item-height: 90px;
		--gap: 35px;

		@include min-mobile {
			margin-top:-35px;
		}
		@include mobile {
			--item-height: 70px;
		}

		> * {
			margin-right: var(--gap);
			margin-top: var(--gap);
		}
	}

	.item-logo {
		img {
			height: var(--item-height);
			width: auto;
		}
	}

}