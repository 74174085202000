// Les styles des facettes sont appliqués singulièrement au niveau de chaque facette,
// afin de pouvoir les avoir bien affichés dans l'overlay filtres mais aussi dehors



// Classe utilisée pour mettre en forme les select fields gérés par la librairie Selectric
.group-select {
	.selectric-wrapper {
		max-width: 280px;

		.selectric-items {
			.selectric-scroll {
				ul {
					li {
						max-width: none;
					}
				}
			}
		}

		.selectric {
			min-height: 60px;
			border-radius:$r_small;
			border: 1px solid rgba($c_main, 0.30);
			width: 100%;
			display: flex;
			align-items: center;
			padding: 20px;

			@include can-hover {
				border: 1px solid $c_main;
			}

			.label {
				font-size: 16px;
				line-height: 1.25;
				height: auto;
				padding: 0;

				&:after {
					right: 20px;
				}
			}
		}
	}
}

// Styles appliqués aux labels et des fois à la balise legend dans les fieldset
.title-facette {
	@include ft($f_hx, 18px, 700, $c_txt, 35px);
	display: block;
	font-style:italic;

	& + * {
		margin-top: 20px;
	}
}

// Styles pour les facettes checkbox
// -> styles communs dans _bloc-form
.facette-checkbox {

}


// Styles avec les labels actif / inactif
// label / titre pour la facette switch
.facette-switch {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;

	// div qui englobe le switch
	.frm_switch_block {
		display: inline-grid;
		grid-template-columns: auto auto;
		grid-template-areas: "label switch";
		align-items: center;
		//@include bd(red,1);
	}

	// Labels actifs / inactifs
	.frm_switch_opt {
		grid-area: label;
		@include ft('', 14px, 300, rgba($c_txt, 0.7));
		display: block;
		padding: 0 10px;
		white-space: normal;

		&.frm_on_label {
			color: $c_txt;
			display: none;
		}
	}

	.frm_switch_block input {
		position: absolute;
        opacity: 0;
	}

	// Toggle
	.frm_switch {
		grid-area: switch;
		position: relative;
		display: block;
		width: 40px;
		height: 20px;
	}

	.frm_slider {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $c_white;
		border:1px solid $c_sub;
		border-radius: 50px;
		transition: .25s;
	}

	.frm_slider:before {
		content: '';
		position: absolute;
		left: 5px;
		top: 3.2px;
		width: 12px;
		height: 12px;
		background-color: $c_main;
		border-radius: 50%;
		box-shadow: initial;
		transition: .25s;
	}

	input:checked + .frm_switch .frm_slider {
		background-color: $c_main;
		border:1px solid $c_main;

		&:before {
			transform: translateX(18px);
			background-color: $c_white;
		}
	}

	.frm_switch_block.is-checked {
		.frm_off_label {
			display: none;
		}
		.frm_on_label {
			display: block;
		}
	}
}


// Facette classement
.facette-classement {
	position: relative;
	display: flex;
	flex-direction: column;

	& + & {
		margin-top: 20px;
	}

	.title-group {
		display: block;
		margin-bottom: 5px;
		//@include ft("", calculateRem(14px), 500, $c_txt);
	}

	.total {
		@include ft('', 13px, '', $c_white);
		margin-left: 10px;
		padding: 4px 10px 5px 10px;
		background-color: $c_sub;
		display: none;
	}

	.classement-chk {
	}

	.classement-field {
		display: grid;
		grid-template-columns: 20px auto;
		gap: 10px;

		& + .classement-field {
			margin-top: 5px;
		}
	}

	.custom-checkbox { // faux radio input stylisable à souhait
		grid-column: 1;
		grid-row: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
		position:relative;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 20px;
			height: 20px;
			border: 1px solid $c_bg;
			border-radius: 2px;
			cursor: pointer;
			@include addTransition();
		}

		&:after {
			@include icomoon();
			content: $icon-ico-check;
			position: absolute;
			display: flex;
			align-items:center;
			justify-content:center;
			top: 0;
			left: 0;
			width: 20px;
			height: 20px;
			background: $c_main;
			opacity: 0;
			color: white;
			font-size: 10px;
			transform: scale(0);
			@include addTransition();
		}
	}

	label {
		grid-column: 1 / 3;
		grid-row: 1;
		display: inline-flex;
		align-items: center;
		width: max-content;
		padding-left: 30px;
		padding-right: 10px;
		font-size: 14px;
		cursor: pointer;

		span {
			color: $c_sub;

			&:first-child {
				margin-left: 5px;
			}

			& + span {
				margin-left: 4px;
			}
		}
	}

	label:hover ~ .custom-checkbox {
		&:before {
			border: 1px solid $c_main;
		}
	}

	input:checked ~ .custom-checkbox{
		&:before {
			border:1px solid $c_main;
		}

		&:after{
			opacity: 1;
			transform: scale(1);
		}
	}

	input:checked + label span {
		&.icon-ico-etoile {		color: $c_main;		}
		&.icon-ico-epis {		color: $c_main;		}
		&.icon-ico-cle {		color: $c_main;		}
		&.icon-toque {		color: $c_main;		}
	}

	input:not(:checked):hover + label span {
		&.icon-ico-etoile {		color: $c_main;		}
		&.icon-ico-epis {		color: $c_main;		}
		&.icon-ico-cle {		color:  $c_main;	}
		&.icon-toque {		color:  $c_main;		}
	}

	input.visuallyhidden {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
		height: auto;
	}

	.icon-ico-close {
		color: $c_sub;
		font-size: 16px;
		margin-right: 10px;

		&:hover, &:focus {
			body:not(.no-hover) & {
				color: $c_main;
			}
		}
	}
}


.group-date {
	position: relative;
	display: flex;
	@include mobile {
		flex-direction: column;
	}

	>div.wrapper-date {
		padding-left: 20px;
		position: relative;
		width: auto;
		flex-basis: auto;
		flex-shrink: 0;
		display: flex;
		align-items: center;

		&:first-of-type {
			border-radius:60px 0 0 60px;
			border: 1px solid $c_bg;
			label.date-label, input:not(.fakeinput){
			//	color: white;
			}
			@include mobile{
				border-radius: 30px 30px 0 0;
			}
		}

		&:not(:first-of-type){
			border: 1px solid $c_bg;
			border-radius:0 60px 60px 0;
			border-left: 0;
			@include mobile{
				border-top: 0;
				border-left: 1px solid $c_bg;
				border-radius: 0 0 30px 30px;
			}
		}

		[class^="icon-"], [class*=" icon-"] {
			font-size: 14px;
			margin-right: 10px;
		}

		label {
			display: inline-block;
			margin-bottom: 0;
			position: relative;
			top: 0;
			left: 0;
			flex-shrink: 0;
			font-size: 1rem;
			line-height: 20px;

			@include tablet-small {
				font-size:14px;
			}
		}
	}

	input:not(.fakeinput) {
		@include ft($f_txt, $s_link, 600, $c_txt, 1);
		padding: 15px 35px 15px 5px;
	//	border: 1px solid rgba($c_bg, 0.25);
		border:0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-transform: uppercase;
		-webkit-appearance: none;
		resize: none;
		-webkit-box-shadow: none;
		outline: none;
		margin: 0;
		box-shadow: none;
		border-radius: 0;
		background: none;
		min-height: 0 !important;
		text-align: left;

		@include tablet-small {
			font-size:12px;
		}

		@include mobile {
			width: 100%;
		}

		&::-webkit-date-and-time-value {
			text-align: left;
		}
	}

	.fakeinput {
		opacity: 0;
		position: absolute;
		left: 0;
		//width: 390px;
		margin-top: 20px;
		height: 60px;
		padding: 0;
	}
}


.group-select-autocomplete {
	border: 1px solid rgba($c_dark, 0.25);
	border-radius:5px;
	box-sizing: border-box;
	margin-top:15px;
	position:relative;
	padding:11px 0 0 0 !important;

	&:after {
		position:absolute;
		right:20px;
		top:32px;
		font-size:20px;
		font-family: icomoon !important;
		//@include font();
		content:"\e919";
		color:#d8374a;

	}

	.title-facette {
		margin-left:10px;
		margin-top: -21px;
		width: fit-content;
		background-color: white;
		padding:0 10px 0 10px;
	}

	.fake-input {
		border:none;
		width:100%;
		padding:20px 40px 20px 20px;
		margin-top: -10px !important;
		box-sizing: border-box;
		border-bottom:1px dashed rgba($c_txt, 0.25);
	}

	.current-filters {
		flex-wrap:wrap;
		padding:10px 20px 5px 20px;
		display:inline-flex;
		li {
			cursor:pointer;
			border:1px solid rgba($c_greydark, 0.25);
			position:relative;
			//border-radius:$radius_img;
			margin-right:5px;
			padding:5px 27px 5px 10px;
			margin-bottom:5px;
			text-transform:none;
			font-size:12px;
			color:rgba($c_txt, 0.60);

			&:after {
				content:"\e928";
				//@include font();
				color:#d8374a;
				font-family: icomoon !important;
				position:absolute;
				right:10px;
				font-size:8px;
				top:50%;
				transform: translate(0%, -50%);
			}
		}
	}

	.autocomplete-list {
		li {
			cursor:pointer;
			&:hover {
				color:$c_sub_light;
			}
		}
	}
}



