.bloc-pubs {


  .nav-buttons {
    text-align:right;
  }

  .image-wysiwyg {
    img {
      min-height: 500px;

      @include mobile {
        min-height: 420px;
      }
    }

    .img-wrapper {
      position: relative;
      display: block;
    }

    figure {
      background: initial;

      img {
        max-height: 500px;

        @include mobile {
          max-height: 420px;
        }
      }
    }

    .caption-wrapper {
      min-height: 500px;
      position: absolute;     // Surcharge de la position relative définie par défaut dans le mixin d'aspect-ratio
      top: 0;
      left: 0;
      width: 100%;
      @include mobile {
        bottom:0;
        top:auto;
        min-height: 420px;
      }

      // gradient sous le texte
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        opacity: 0.75;
        pointer-events: none;
        border-radius: $r_small;
        background-image: linear-gradient(to top right, rgba($c_bg, 1), rgba($c_bg, 0));
      }

      @include min-tablet-portrait {
        .caption {
          bottom:60px;
          left:60px;
        }
      }
    }
  }

  &.wrapper:not(.wrapper-small) {
    .th-slider {
      @include desktop {
        width: calc(((100vw - 100%) / 2) + 100%);
      }
      .image-wysiwyg {
        @include desktop {
          width: 100%;
        }
      }
    }
  }

  .image-carree {
    display:none;
    @include mobile {
      display:block;
    }
  }

  .image-rect {
    @include mobile {
      display: none;
    }
  }


  .th-slider {
    position:relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-left:0 !important;
    padding-right:0 !important;
    scroll-padding:0 !important;

    &::-webkit-scrollbar {
      display: none;
    }
    .image-wysiwyg {
      margin-left: ($small-margin / 2);
      margin-right: ($small-margin / 2);
      @include mobile {
        //width: calc(100% - 20px);
      }
    }
  }
}
