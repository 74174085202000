.bloc-form{
    &.--bg-main {
        padding:80px 0;

        @include mobile {
            padding:40px 0;
        }

        .form-field {
            .frm_opt_container {
                label:not(.star-rating):not(.frm_hidden):not(.star-rating):not(.frm_switch) {
                    &:hover {
                        body:not(.no-hover) & {
                            color: rgba($c_txt, 0.50);

                            span {
                                &:before {
                                    border-color: $c_white;
                                }
                            }
                        }
                    }
                }
            }

            // *** Style du champ toggle
            &.input-is-switch {
                .frm_switch_block{
                    input{
                        &:checked{
                            +.frm_switch{
                                .frm_slider{
                                    border:1px solid $c_white;
                                    background-color: $c_white;

                                    &:before {
                                        background-color:$c_main;
                                    }
                                }
                            }
                        }
                    }

                    .frm_switch{
                        .frm_slider{
                            border:1px solid $c_bg;
                            background-color: $c_bg;

                            &:before {
                                background-color:$c_white;
                            }
                        }
                    }
                }
            }


            /* Style for Radio & Checkbox */
            .frm_opt_container {
                /* Style for Radio */
                .frm_radio {
                    span {
                        &:before {
                            background-color: $c_white;
                            border: 1px solid $c_white;
                        }

                        &:after {
                            background: $c_bg;
                        }
                    }
                }

                /* Style for Checkbox */
                .frm_checkbox {
                    input {
                        display: none;
                        &:checked {
                            ~ span:after {
                                background: $c_bg;
                            }
                        }
                    }

                    label {
                        > span {
                            &:before {
                                background: $c_white;
                                border: 1px solid $c_main;
                            }
                            &:after {
                                background: $c_white;
                            }
                        }
                    }
                }

            }

            p a{
                color: $c_txt;
                text-decoration: underline;
                &:hover, &:focus {
                    body:not(.no-hover) & {
                        color: darken($c_main, 10%);
                    }
                }
            }
        }


        /* Style for Submit Button */
        .frm_submit {
            button {
                background-color:$c_white;
                border:1px solid $c_white;
                color:$c_txt;

                &:hover, &:focus {
                    body:not(.no-hover) & {
                        cursor: pointer;
                        border:1px solid $c_txt;
                        background-color:$c_txt;
                        color:$c_white;
                    }
                }
            }
        }
    }


    .text-styles {
        margin-bottom:40px;

        @include mobile {
            margin-bottom:20px;
        }
    }

    legend.frm_screen_reader{
        display: none;
    }

    .frm_error_style,
    .frm_success_style,
    .frm_message{
        padding: 16px;
        margin-bottom: $bloc-spacing;
        border: 1px solid $c_green;
        font-size: 14px;
        color: $c_green;
        background-color: rgba($c_green,0.1);
        border-radius: initial;

        p:last-child{
            margin-bottom: 0;
        }
    }

    .frm_error_style{ // message d'erreur global au formulaire. Différent des messages sous chaque champs
        border-color: $c_error;
        color: $c_error;
        background-color: rgba($c_error,0.1);
    }

    .frm_success_style{
        border-color: $c_green;
        color: $c_green;
        background-color: rgba($c_green,0.2);
    }

    .form-field {    // wrapper autour de chaque "bloc" / champs défini dans le BO
        position: relative;
        margin-bottom: 36px;
        width: 100%;

        @include mobile {
            margin-bottom:20px;
        }

        &.frm_section_heading {
            margin-top: 30px;
            margin-bottom: 0;
            padding-bottom: 0;
            >h3:first-child{
                margin-top: 0;
            }
            &:after{
                content: '';
                display: block;
                clear: both;
            }
        }

        &.focused:not(.vertical_radio):not(.horizontal_radio):not(.classic-label){
            label{
                top: 10px;
                font-size: 12px;
                line-height: 16px;
                @include mobile{
                    top: 8px;
                    font-size: 11px;
                    line-height: 14px;
                }
            }
        }


        /* Gestion des labels */
        label:not(.title-facette) {
            position: absolute;
            top: 20px;
            left: 30px;
            @include ft($f_txt, 16px, 400, $c_txt, 20px);
            box-sizing: border-box;
            display: block;
            clear: both;
            text-indent: 0;
            padding: 0;
            user-select: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 6px;
            @include addTransition();
            @include mobile{
                font-size: 13px;
            }
            a {
                color: $c_txt;
                text-decoration: underline;
                &:hover, &:focus {
                    body:not(.no-hover) & {
                        color: darken($c_main, 10%);
                    }
                }
            }
        }


        // Style par défaut des labels
        .frm_primary_label{
            @include ft($f_txt, 14px, 300, $c_txt, 20px);
            box-sizing: border-box;
            display: block;
            clear: both;
            text-indent: 0;
            padding: 0;
            user-select: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 6px;
        }

        // style par défaut des champs de saisie
        input[type="text"],
        input[type="email"],
        input[type="password"],
        input[type="number"],
        input[type="date"],
        input[type="tel"],
        textarea {
            @include ft($f_txt, 16px, 400, $c_txt, 20px);
            @extend %appearance;
            box-sizing: border-box;
            display: block;
            width: 100%;
            max-width: 100%;
            padding: 19px 30px;
            background: $c_white;
            border: 1px solid $c_main;
            text-overflow: ellipsis;
            border-radius: $r_small;
            @include addTransition();
            &::placeholder {
                color: $c_txt;
            }
            &:hover, &:focus {
                body:not(.no-hover) & {
                    border-color: $c_main;
                }
            }
        }

        input {
            &[type="date"]::-webkit-inner-spin-button {
                display: none;
            }
            &[type="date"]::-webkit-calendar-picker-indicator {
                display: none;
            }
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        input[type=number] {
            -moz-appearance: textfield; /* Firefox */
        }

        /*Change text in autofill textbox*/
        input:-webkit-autofill {
            -webkit-text-fill-color: $c_txt;
        }

        /* Change the white to any color ;) */
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 60px $c_white inset !important;
        }

        textarea {
            height: 200px;
        }


        // message d'erreur sous chaque champ (champ obligatoire, mail non valide...)
        .frm_error{
            padding: 8px 0;
            font-size: 14px;
            color: $c_error;
        }


        // --- wrapper des radios et checkbox ---
        // --------------------------------------
        &[class*="radio"],
        &.frm_two_col,
        &.frm_three_col,
        &.frm_four_col{
            margin-bottom: 20px;
        }

        // Corrections sur le comportement de la grille des radios et checkbox
        &.frm_two_col,
        &.frm_three_col,
        &.frm_four_col{
            // par défaut, elles font (par exemple) 50% de leur propore colonne, soit au final que 25% de large.
            .frm_radio{
                width: 100%;
            }
        }

        // absence par défaut de réorganisation des colonnes en responsive
        // un affichage 4 colonnes restait sur 4 colonnes en mobile... pas ouf
        &.frm_four_col .frm_opt_container,
        &.frm_three_col .frm_opt_container{
            @include tablet_portrait{
                grid-template-columns: repeat(2, 1fr);
            }
        }
        &.frm_four_col .frm_opt_container,
        &.frm_three_col .frm_opt_container,
        &.frm_two_col .frm_opt_container{
            @include mobile{
                grid-template-columns: repeat(1, 1fr);
            }
        }

        /* Style for Radio & Checkbox */
        .frm_opt_container {
            font-size: 0;
            margin-bottom: 0;
            @include mobile {
                display: block;
            }

            & + .frm_error {
                margin-top: 15px;
            }

            input {
                display: none !important;
            }

            label:not(.star-rating):not(.frm_hidden):not(.star-rating):not(.frm_switch) {
                position: relative;
                display: block;
                left: 0;
                top: 0;
                padding-left: 32px;
                margin: 0;
                @include ft($f_txt, 14px, 500, $c_txt, 20px);
                white-space: normal;
                overflow: visible;
                text-indent: 0;
                @include addTransition();
                @include mobile{
                    font-size: 13px;
                }
                &:hover {
                    cursor: pointer;
                    body:not(.no-hover) & {
                        color: $c_main;
                        span {
                            &:before {
                                border-color: $c_main;
                            }
                        }
                    }
                }
            }

            .frm_checkbox, .frm_radio {
                margin-bottom: 10px;
                @include mobile {
                    display: block;
                }
            }

            /* Style for Radio */
            .frm_radio {
                position: relative;
                input[type="radio"]:checked + span:after {
                    opacity: 1;
                    transform: scale(1);
                }

                span {
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        border-radius: 50px;
                        border: 1px solid $c_bg;
                        cursor: pointer;
                        @include addTransition();
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        top: 6px;
                        left: 6px;
                        background: $c_bg;
                        border-radius: 50%;
                        opacity: 0;
                        transform: scale(0);
                        @include addTransition();
                    }
                }
            }

            /* Style for Checkbox */
            .frm_checkbox {
                position: relative;
                input {
                    display: none;
                    &:checked {
                        ~ span:after {
                            opacity: 1;
                            transform: scale(1);
                        }
                    }
                }
                label {
                    > span {
                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 20px;
                            height: 20px;
                            border: 1px solid $c_bg;
                            border-radius: 2px;
                            cursor: pointer;
                            @include addTransition();
                        }
                        &:after {
                            @include icomoon();
                            content: $icon-ico-check;
                            position: absolute;
                            display: flex;
                            align-items:center;
                            justify-content:center;
                            top: 0;
                            left: 0;
                            width: 20px;
                            height: 20px;
                            background: $c_main;
                            opacity: 0;
                            color: white;
                            font-size: 10px;
                            transform: scale(0);
                            @include addTransition();
                        }
                    }
                }
            }


            /* Style for Scale */
            .frm_scale {
                position: relative;
                display: inline-block;
                width: 40px;
                @include mobile {
                    margin-bottom: 10px;
                }
                label {
                    padding: 0;
                    text-align: center;
                    &:hover {
                        body:not(.no-hover) & {
                            cursor: pointer;
                            input {
                                border-color: $c_main;
                            }
                        }
                    }
                }
                input {
                    position: relative;
                    display: block !important;
                    @extend %appearance;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background: $c_white;
                    border: 1px solid rgba($c_txt, 0.5);
                    box-shadow: 0 0 15px rgba($c_txt, 0.2);
                    padding: 0;
                    margin: 0 auto 4px auto;
                    box-sizing: content-box;
                    cursor: pointer;
                    @include addTransition();
                    &:after {
                        content: '';
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        top: 3px;
                        left: 3px;
                        background: $c_main;
                        border-radius: 50%;
                        opacity: 0;
                        transform: scale(0);
                        @include addTransition();
                    }
                    &:checked {
                        &:after {
                            opacity: 1;
                            transform: scale(1);
                        }
                    }
                    &:hover, &:focus {
                        body:not(.no-hover) & {
                            border-color: $c_txt;
                        }
                    }
                }
            }
        }



        /* Style for Selectric */
        .selectric-wrapper {
            .selectric {
                box-sizing: border-box;
                background: white;
                border:1px solid $c_main;
                border-radius: 5px;
                min-height: 60px;
                @include addTransition();
                &:hover {
                    body:not(.no-hover) & {
                        border-color: $c_main;
                        .label:after{
                            color: $c_main;
                        }
                    }
                }
                .label {
                    @include ft($f_txt, 16px, 400, $c_txt, 20px);
                    box-sizing: border-box;
                    display: block;
                    height: auto;
                    margin: 0;
                    padding: 19px 50px 19px 30px;
                    @include mobile{
                        font-size: 13px;
                    }
                    &:after {
                        content: "\e92a";
                        font-family:'icomoon';
                        font-size: 14px;
                        position: absolute;
                        color: $c_txt;
                        display: flex;
                        justify-content:center;
                        align-items: center;
                        background-color: transparent;
                        width: 14px;
                        height: 14px;
                        top: 50%;
                        right: 28px;
                        margin-top: -7px;
                        transform:none;
                        margin-left: 0;
                        @include addTransition();
                    }
                }
                .button {
                    display: none;
                }
            }
            .selectric-items {
                border: none;
                background: $c_white;
                box-shadow: 0 5px 20px rgba($c_txt, 0.5);
                .selectric-scroll {
                    ul {
                        padding: 0;
                        li {
                            @include ft($f_txt, 14px, 300, $c_txt, 20px);
                            display: block;
                            padding: 10px 20px;
                            margin-bottom: 0;
                            @include addTransition();
                            @include mobile{
                                padding: 10px 20px;
                                font-size: 13px;
                            }
                            &:hover {
                                background: #F5F5F5;
                            }
                            &.selected {
                                background: #EEE;
                            }
                        }
                    }
                }
            }

            &.selectric-open{
                .selectric{
                    border-color: $c_main;
                    .label:after{
                        color: $c_main;
                    }
                }
            }
        }



        // *** Style du champ toggle
        &.input-is-switch {
            label{
                font-size:14px;
                position: static;
                top: 0;
                left: 0;
                padding-left: 60px;
                color: $c_txt;
                white-space:normal;
                cursor: pointer;
                a {
                    color: $c_txt;
                }
            }

            .frm_switch_opt {
                display:none;
            }

            .frm_switch_block{
                position: static;
                input{
                    display: none;
                    &:checked{
                        +.frm_switch{
                            .frm_slider{
                                background-color: $c_main;
                                &:before{
                                    left:22px;
                                    background-color: $c_white;
                                }
                            }
                        }
                    }
                }
                .frm_switch{
                    position: absolute;
                    top: -1px;
                    left: 0;
                    width: 40px;
                    height: 20px;
                    .frm_slider{
                        border:1px solid $c_sub;
                        background-color: $c_white;
                        &:before{
                            width: 12px;
                            height: 12px;
                            top:3px;
                            background-color: $c_main;
                            left: 5px;
                        }
                    }
                }
            }
        }
    }

    /* Style for Submit Button */
    .frm_submit {
        margin-top:40px;
        text-align: right;
    }

    .frm_verify{
        display: none;
    }

    ////////////////////

    & .form-field .frm_radio,
    & .form-field .frm_checkbox, // wrapper de chaque option
    & .group-checkbox .frm_checkbox { // styles également appliqués aux facettes
        position: relative;
        width: 100%;

        & + .frm_radio,
        & + .frm_checkbox {
            margin-top: 20px;
        }

        input {
            display: none;
            &:checked {
                ~ span:after {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }

        label {
            position: relative;
            display: inline-block;
            padding-left: 30px;
            padding-right: 10px;
            padding-top: 0;
            @include ft('', 16px, 400, $c_txt, 1.25);
            @include addTransition();
            text-indent: 0;
            cursor: pointer;

            @include can-hover {
                color:$c_main;
                > span {
                    &:before {
                        border: 1px solid $c_main;
                    }
                }
            }

            // Styles spécifiques aux facettes
            &.facette-checkbox {
                padding-top: 1px;
                font-size: 16px;
                font-weight: 400;
            }

            > span {
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    border: 1px solid $c_bg;
                    border-radius: 2px;
                    cursor: pointer;
                    @include addTransition();
                }
                &:after {
                    @include icomoon();
                    content: $icon-ico-check;
                    position: absolute;
                    display: flex;
                    align-items:center;
                    justify-content:center;
                    top: 0;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    background: $c_main;
                    opacity: 0;
                    color: white;
                    font-size: 10px;
                    transform: scale(0);
                    @include addTransition();
                }
            }
        }

        // Classe injecté en js sur le parent pour pouvoir stylisé le label actif
        &.is-checked {
            label {
                color: $c_txt;
            }
        }
    }


    .form-field .frm_radio{
        span{ // faux radio input stylisable à souhait. Ajouté en JS.
            border-radius: 50%;

            &:after{
                border-radius: 50%;
            }
        }
    }

    //Time
    .input-is-time{
        .frm_time_wrap{
            .focused{
                display: flex;
                align-items: center;
                width: 100% !important;
                padding-right: 0;

                .selectric-wrapper{
                    width: 50% !important;
                }
                .select-custom{
                    position: absolute;
                }
                .frm_time_sep{
                    flex-shrink: 0;
                }
            }
        }
    }


    .form-field.horizontal_radio { // version horizontale (radio comme checkbox)
        .frm_radio,
        .frm_checkbox{ // wrapper de chaque option
            margin-right: 20px;
            width: auto;
        }
    }
}

.frm-g-recaptcha{
    visibility: hidden !important;
    opacity: 0 !important;
}
