.page-listing {
	header {
		& + * {
			margin-top: 80px;
		}
	}

	.nbresults_founded {
		display:none;
	}

	.listing-sans-carte {
		.wrapper-cards {
			.listing-no-results {
				grid-column: 1 / -1;		// stretch sur tout le wrapper
			}

			> * {
				margin-bottom: 0;
			}

			.card {
				@include mobile {
					@include card-horizontale();
				}
			}
		}
	}

	.listing-avec-carte {
		.wrapper-content {
			position: relative;
			display:flex;
			gap: 45px;
			padding-right: var(--border-gutter);

		/*	@include desktop {
				display: grid;
				grid-template-columns: minmax(350px, 3fr) minmax(0, 4fr);
				grid-template-areas: "map cards";
			} */

			@include tablet-landscape {
				grid-template-columns: 1fr;
				grid-template-areas: "cards";
				padding-left: var(--border-gutter);
			}

			.wrapper-sticky {
			//	grid-area: map;
				position: sticky;
				bottom: 0;
				top: 0;
				height: calc(100vh);

				@include min-wide {
					width:53% !important;
				}

				@include min-desktop {
					width:55% !important;
				}

				@include min-tablet-landscape {
					width:50%;
				}

				.maps {
					height: 100%;
				}
			}

			.wrapper-cards {
				grid-area: cards;
				min-height: 0;
				height: auto;
				margin-bottom: auto;

				@include min-wide {
					width:47% !important;
				}

				@include min-desktop {
					width:45% !important;
				}

				@include min-tablet-landscape {
					width:50%;
				}

				.card {
					margin-bottom:0;
					@include mobile {
						@include card-horizontale();
					}
				}
			}
		}
	}

	.listing-agenda {
		.container-date{
			margin-bottom: 30px
		}

		.agenda-inputs {
			input {
				display: none;
			}

			.choix-date {
				display:flex;
				overflow-x:auto;
				align-items: center;
				scroll-snap-type: x mandatory;
				overflow-y: hidden;

				> * {
					white-space: nowrap;
					scroll-snap-align: center;
				}
				> * + * {
					margin-left: 30px;
				}
			}


			@mixin set-choix-date-active {
				color:$c_main;
				@include addTransition();

				&:before {
					opacity:1;
					@include addTransition();
					visibility:visible;
				}
			}

			.choix-date-label {
				@include ft($f_spe, 16px, 700, rgba($c_main, 0.30), 24px);
				padding-bottom: 25px;
				text-transform:uppercase;
				cursor:pointer;
				position:relative;
				@include addTransition();

				&:hover {
					@include set-choix-date-active;
				}

				&:before {
					content:"";
					position:absolute;
					bottom:2px;
					width:12px;
					border-radius:50%;
					left:50%;
					height:12px;
					background-color:$c_main;
					opacity:0;
					transform: translate(-50%, 0);
					visibility:hidden;
					@include addTransition();
				}
			}

			@for $i from 0 through 3 {
				input#today-#{$i} {
					&:checked {
						~ .choix-date label[for="today-#{$i}"] {
							@include set-choix-date-active;
						}
					}
				}

				input#ce_week_end-#{$i} {
					&:checked {
						~ .choix-date label[for="ce_week_end-#{$i}"] {
							@include set-choix-date-active;
						}
					}
				}

				input#cette_semaine_j7-#{$i} {
					&:checked {
						~ .choix-date label[for="cette_semaine_j7-#{$i}"] {
							@include set-choix-date-active;
						}
					}
				}

			}
		}

		.container {
			&:first-of-type {
				.day-date {
					margin-top: 0;
				}
			}

			.day-date {
				@include ft($f_hx, $s_h3, 700, $c_txt, 1);
				display: block;
				font-style:italic;
				background-color: $c_white;
				width: 100%;
				height: auto;
				z-index: 10;
				margin-bottom: 30px;
				margin-top: 53px;

				@include tablet-portrait {
					font-size: 28px;
					line-height: 0.9;
				}
				@include mobile {
					font-size: 22px;
					line-height:24px;
				}

				@include mobile-small {
					margin-top: 30px;
					font-size: 20px;
					line-height:22px;
				}
			}

			.wrapper-cards {
				display: flex;
				//align-items: flex-start;
				flex-wrap: wrap;
				margin-top: 40px;
				margin-left:calc(var(--grid-gap-small)/2 * -1);
				margin-right:calc(var(--grid-gap-small)/2 * -1);

				@include mobile {
					margin-top:0;
				}

				.card {
					position: relative;
					padding: 0;
					margin-left:calc(var(--grid-gap-small)/2);
					margin-right:calc(var(--grid-gap-small)/2);
					width: calc(25% - var(--grid-gap-small));

					@include desktop {
						width: calc(33% - var(--grid-gap-small));
					}

					@include tablet-small {
						width: calc(50% - var(--grid-gap-small));
					}

					@include min-mobile {
						.image {
							height: 190px;
						}
					}

					@include mobile {
						margin-top: 0;
						@include card-horizontale();
						width:100%;
					}

				}
			}
		}
	}
}