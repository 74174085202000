.bloc-agenda {
	/* Boucle pour gérer l'affichage des blocs au click sur l'input de l'onglet
/* NOTE : le maximum à été mis à 3 parce qu'on a 3 onglets dans la maquette, mais si vous avez plus d'onglets il faut simplement ajouter un numéro en plus

*/

	> .container {
		display: flex;
		justify-content: space-between;
		padding-bottom:23px;
		align-items: self-end;
		flex-wrap:wrap;
		margin-bottom: ($small-margin * 2);
		@include tablet-portrait {
			flex-direction: column;
			align-items: start;
			justify-content: flex-start;
		}

		// Onglets
		.onglets {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			// Pour une meilleure expérience utilisateur, on a donné un padding top de 10px pour le rendre le label plus grand.
			// Afin d'éviter que le padding soit trop haut par rapport à la maquette il faut un margin-top négatif pour faire remonter de 10px
			// La chiffre est à modifié selon vos besoins
			margin-top: -10px;
			@include mobile {
				padding: 0 20px 5px 20px;
				margin-top: 0;
				overflow: auto;
				-webkit-overflow-scrolling: touch;
				white-space: nowrap;
				margin-left: -20px;
				margin-right: -20px;
				width: calc(100% + 40px);
				flex-wrap: wrap;
				font-size: 0;
				display: block;
			}

			a {
				@include ft($f_spe, $s_p, 700, $c_main, 24px);
				position: relative;
				z-index: 10;
				padding: 10px 20px;
				text-transform:uppercase;
				display: inline-block;
				cursor: pointer;
				@include addTransition();

				&:first-of-type {
					padding-left: 0;
				}

				&:hover, &:focus {
					body:not(.no-hover) & {
						opacity: 0.70;
					}
				}
			}

			& + a {
				@include tablet-portrait {
					margin-top: $small-margin;
				}
				@include mobile {
					margin-top: 0;
				}
			}
		}

		a {
			flex-shrink: 0;
			@include tablet-portrait {
				align-self: flex-start;
			}
		}

		& + .container-onglet {
			@include tablet-portrait {
				margin-top: $small-margin;
			}
		}
	}

	// Container
	.container-onglet {
		display: flex;
		flex-wrap: wrap;

		.card {
			width: calc(50% - 20px);
			margin-bottom: $small-margin;
			padding: 0;
			@include mobile {
				width: 100%;
			}

			.image {
				@include mobile {
					width: 140px;
					height: 140px;
				}
			}

			&:nth-of-type(odd) {
				@include min-mobile {
					padding-right: $grid-gap;
				}
			}

			&:last-of-type {
				@include mobile {
					margin-bottom: 0;
				}
			}
		}
	}

	// Affichage spéciale lorsqu'on a des cards en format horizontale
	.cards-horizontales {
		.card {
			min-height:170px;
			@include tablet-portrait {
				width: 100%;
				height:170px;
				min-height:auto;
			}

			.caption {
				.title-card {
					@include min-mobile {
						font-size: 22px;
					}
				}
			}

			.image {
				@include min-tablet-portrait {
					width: 170px;
					height: 170px;
				}

				.btn-favoris {
					padding:30px;
				}
			}
		}
	}


	&.bloc-temps-forts {
		.container-agenda {
			display: flex;
			justify-content: center;
			overflow: visible;

			@include tablet-portrait {
				flex-wrap: wrap;
			}

			.col-left {
				width:100%;
				max-width:660px;
			}

			.wrapper-cards {
				width:45%;
			}

			@include tablet-portrait {
				.col-left, .wrapper-cards  {
					width: 100%;
				}
			}

			.col-left {
				position: relative;
				overflow: visible;
				padding-right: 20px;

				@include tablet-portrait {
					padding-right: 0;
					margin-bottom: 40px;
				}

				.nav-buttons {
					display: flex;
					justify-content: space-between;
					position: absolute;
					top: calc(50% - 32px);
					//transform: translate(0, -50%);
					width: calc(100% + 40px);
					left: -32px;
					right: -32px;

					button {
						background-color:$c_white;
						border-radius:50%;
						width:64px;
						height:64px;
					}
				}

				.agenda-inner-content {
					overflow: hidden;
					height: 100%;

					@include tablet-portrait {
						margin-left: -40px;
						margin-right: -40px;
						width: calc(100% + 80px);
					}

					@include mobile {
						margin-left: -20px;
						margin-right: -20px;
						width: calc(100% + 40px);
					}

					.agenda-slider {
						height: 100%;

					}
				}

				.container-temps-fort {
					position: relative;
					//max-width: 100%;
					height: 100%;
					margin-right: 20px;
					max-width: 640px;
					max-height: 540px;

					@include mobile {
						margin-right: 10px;
						max-width: 335px;
						max-height: 335px;
					}

					&:last-of-type {
						margin-right: 0;
					}

					&:hover, &:focus {
						body:not(.no-hover) & {
							transform:none;
							.caption {
								.btn {
									background-color:$c_main;
									border:1px solid $c_main;
									color:$c_white;
								}
							}
						}
					}

					.caption {
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;

						.surtitre {
							font-size:12px;
						}

						.title-card {
							@include ft($f_hx, $s_h3, 700, $c_white, 1);
							display: block;
							font-style:italic;

							@include tablet-portrait {
								font-size: 28px;
								line-height: 0.9;
							}
							@include mobile {
								font-size: 22px;
								line-height:24px;
							}
							@include mobile-small {
								font-size: 20px;
								line-height:22px;
							}
						}

						.btn {
							margin-top:15px;
							background-color:$c_white;
							border:1px solid $c_white;
							color:$c_txt;
						}

						.meta-wrapper {
							display:none;
						}
					}

					.image {
						position: relative;
						height: 100%;

						@include mobile {
							width: 100%;
							object-fit: cover;
							height: 335px;
						}

						@include mobile_small {
							height: 335px;
						}

						&:after {
							content: "";
							display: block;
							width: 100%;
							height: 100%;
							background: linear-gradient(-90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.50));
							position: absolute;
							top: 0;
							left: 0;
							bottom: 0;
						}

						figure {
							width: auto;
							height: 100%;
						}

						figcaption {
							display: none;
						}

						.btn-favoris {
							padding:30px;
							right:0;
							top:0;
						}

						.credits {
							top:20px;
							left:20px;
						}
					}

				}
			}

			.wrapper-cards {
				padding-left: 20px;

				@include tablet-portrait {
					padding-left: 0;
				}

				.card {
					width: 100%;
					margin-bottom: 15px;
					padding-right: 0;

					@include mobile {
						margin-bottom: 20px;
					}

					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}

	}
}