.toolbar-mobile {
	position: sticky;
	bottom: 10px;
	left: 10px;
	right:10px;
	display: flex;
	border-radius:$r_small;
	align-items: center;
	justify-content: space-between;
	height: var(--toolbar-height);
	width: calc(100% - 20px);
	background-color: $c_bg;
	z-index: z-index('toolbar');

	@include mobile {
		justify-content: flex-start;
	}

	ul {
		display:flex;
		height: 100%;
		align-items:center;
	}

	.--bg-main {
		padding: 0 10px;
		border-radius:$r_small 0 0 $r_small;
	}

	.--bg-dark {
	//	padding:0 25px;
		border-radius:0 $r_small $r_small 0;

		@include mobile {
			margin: 0 auto;
			padding: 0 10px;
		}

		@include mobile-small {
			padding: 0 5px;
		}
	}

	.btn-menu {
		padding:0 25px;
		@include mobile {
			padding:0 10px;
		}
	}

	.btn-search {
		padding:0 25px;

		@include mobile {
			padding:0 10px;
		}
	}

	// Toolbar avec la météo, la webcam, le lien vers la billetterie, le panier, les favoris
	.toolbar {
		flex-direction: row;
	}
}
