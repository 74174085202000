// Toolbar avec la météo, la webcam, le lien vers la billetterie, le panier, les favoris sur desktop et sur mobile pour Loches
.toolbar {
  display: flex;
  align-items: center;
  margin-left: auto;
  flex-direction: column;
  justify-content: center;

  li {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;

    [class^="icon-"], [class*=" icon-"] {
      position: relative;
      font-size: 20px;
      color: $c_white;
    }

    &[data-hover]:before {
      content: "";
      display: block;
      border: solid;
      border-color: $c_main transparent;
      border-width: .4em .4em 0 .4em;
      position: absolute;
      top: 14px;
      left: 50%;
      transform: translateX(-50%);
      z-index: z-index('barre-navigation');
      opacity: 0;
      @include addTransition();
    }

    &[data-hover]:after {
      content: attr(data-hover);
      display: block;
      @include ft($f_txt, 9px, bold, $c_white, 1.11);
      background-color: $c_main;
      border-radius: 5px;
      padding: 5px 10px;
      position: absolute;
      top: -6px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      @include addTransition();
    }

    &:hover, &:focus {
      body:not(.no-hover) & {
        &:before, &:after {
          opacity: 1;
        }

        [class^="icon-"], [class*=" icon-"] {
          cursor: pointer;
          color: $c_main;

          &[data-counter] {
            &:after {
              transform: scale(1.1);
            }
          }
        }
      }
    }

    a, button {
      display: flex;
      align-items: center;
      height: 100%;
      cursor:pointer;
      @include addTransition();
      padding:15px 20px;

      @include mobile {
        padding: 16px;
      }

      @include mobile-small {
        padding:5px;
      }
    }
  }
}