.barre-navigation {
	position: fixed;
	top: 10px;
	right: 60px;
	z-index: z-index('barre-navigation');
	@include addTransition();
	@include wide {
		right: 40px;
	}
	.is-scrolled & {
		.container {
			.logo-light {
				visibility: visible;
				@include addTransition();
				opacity:1;
			}
		}
	}

	.container {
		display: flex;
		flex-direction:column;
		color:$c_white;

		.logo-light {
			visibility: hidden;
			@include addTransition();
			opacity:0;
		}

		.--bg-main {
			padding:25px 0;
			width: var(--toolbar-height);
			border-radius:$r_small $r_small 0 0;
		}

		.--bg-dark {
			padding:25px 0 15px 0;
			width: var(--toolbar-height);
			border-radius:0 0 $r_small $r_small;
		}

		// Bouton pour ouvrir l'overlay recherche
		.btn-search {
			margin-top: 30px;
		}

		.btn-menu {
			padding-top:8px;
		}
	}
}


