
.overlay-favoris-lies {
  max-width: 40%;
  button.reset {
    cursor: pointer;
  }
}

.overlay-favoris {
  max-width: none;
  display: flex !important;
  background:transparent;

  .overlay__inner {
    width: 34%;
    margin-left: auto;
    background: #FFF;
    @include desktop {
      width: 100%;
    }
  }

  .wrapper-cards {
    position: relative;

    .card {
      .btn-suppr {
        display: flex;
        box-shadow: none;
        position: absolute;
        left: 0;
        padding: 0;
        transform: translate(-50%, -50%);
        top: 50%;
        width: 25px;
        height: 25px;
        font-size: 10px;

        &:after {
          background-image: none;
        }

        @include mobile {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  
  .wrapper-map {
    height: 100vh;
    width: 66%;
    display: flex;
    @include tablet_small {
      display: none;
    }

    .maps {
      flex-grow: 1;
    }

    .card .btn-favoris {
      display: none;
    }
  }

  .nb-items {
    margin-left: 5px;

    &.empty {
      display: none;
    }
  }


  .empty-panier {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    cursor: pointer;
    @include addTransition();

    @include mobile {
      font-size: 14px;
      padding-left: 20px;
      max-width: 40%;
      text-align: left;
    }

    &:before {
      border: 1px solid $c_bg;
      border-radius: 50%;
      content: "\e928";
      padding: 10px;
      @include icomoon();
      font-size: 8px;
      display: block;
      color: $c_txt;
      margin-right: 10px;
      @include addTransition();
    }

    &:hover, &:focus {
      body:not(.no-hover) & {
        color: $c_sub;

        &:before {
          color: $c_sub;
          border: 1px solid $c_sub;
        }
      }
    }
  }


  // popup du formulaire
  .form-popup {
    display: none;
    position: absolute;
    z-index: 1000000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 40px);
    max-width: 500px;
    padding: 60px 60px 60px;
    background-color: $c_white;

    pointer-events: initial;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.2);

    @include mobile {
      top: 0;
      left: 0;
      transform: initial;
      z-index: 8000;
      width: 100%;
      height: calc(100vh - 80px);
      padding: 60px 20px 60px;
      border-radius: initial;
      box-shadow: initial;
    }

    &.open {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .close {
      @extend .btn-close;
      position: absolute;
      top: 20px;
      right: 20px;
      left: auto;
      transform: none;
      @include addTransition();

      &:hover {
        cursor: pointer;
      }
    }
  }
}


