// Le positionnement des éléments est fait avec la propriété ordre parce que
// pour l'accessibilité il faut toujours avoir les dots comme premiers éléments dans la structure html,
// donc on est obligé à jouer avec les ordres en css

.bloc-galerie {
	position: relative;
	display: flex;
	flex-direction: column;

	.wrapper {
		padding-bottom: 4px;

		&.scroll-mode-parent {
			@include desktop {
				margin: 0;
				width: 100%;
				max-width: none;
			}

			.nav-buttons {
				@include tablet-landscape {
					display: flex;
					margin-bottom: 20px;
				}

				button {
					&:first-of-type {
						@include desktop {
							left: 50px;
						}
						@include tablet-landscape {
							position: relative;
							left: 40px;
						}
					}

					&:last-of-type {
						@include desktop {
							right: 50px;
						}
						@include tablet-landscape {
							position: relative;
							left: 50px;
							right: auto;
						}
					}
				}
			}
		}
	}

	.th-slider {
		padding-bottom: 40px;
	}

	.slide {
		position: relative;
		margin-right: $small-margin;
		max-width: 100%;
		cursor: default;
		@include addTransition();

		@include mobile {
			max-width: calc(100% - 20px);
		}

		&:hover {
			@include rotate();
		}

		.img-wrapper {
			position: relative;
			cursor: pointer;

			&:hover, &:focus {
				body:not(.no-hover) & {
					&:before, &:after {
						opacity: 1;
					}

					&:after {
						transform: translate(-50%, -50%) scale(1);
					}
				}
			}

			&:before {
				content: "";
				display: block;
				width: 100%;
				height: 100%;
				background-color: rgba($c_bg, 0.25);
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				@include addTransition();
			}

			&:after {
				@include icomoon($icon-ico-agrandir);
				@include preserve-px-unit(40px); // font-size fixe pour les icônes
				display: block;
				color: $c_white;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) scale(1.2);
				opacity: 0;
				z-index: 2;
				@include addTransition();
			}
		}

		figure {
			display: table;
			border-radius:$r_small;
			background-color: transparent;

			img {
				height: 520px;
				border-radius:$r_small;

				@include mobile {
					height: 240px;
					width: auto;
				}
			}
		}

		figcaption {
			display:none;
		}
	}

}