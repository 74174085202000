@font-face {
    font-family: "iconvault";
    src: url("../../components/OverlayMeteo/fonts/iconvault_forecastfont.eot");
    src: url("../../components/OverlayMeteo/fonts/iconvault_forecastfont.eot?#iefix") format("embedded-opentype"),
    url("../../components/OverlayMeteo/fonts//iconvault_forecastfont.woff") format("woff"),
    url("../../components/OverlayMeteo/fonts/iconvault_forecastfont.ttf") format("truetype"),
    url("../../components/OverlayMeteo/fonts/iconvault_forecastfont.svg#iconvault") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^="meteo-"],
[class*=" meteo-"] {
    font-family: 'iconvault';
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    font-size: 18px;
    &:before, &:after{
        color: $c_main;
        font-size: 25px;
        font-weight: 400;

    }
}

@media only screen and (min-width: 479px) and (max-width: 678px) {
    [class^="meteo-"],
    [class*=" meteo-"] {
        font-family: 'iconvault';
        font-weight: normal;
        font-style: normal;
        text-decoration: inherit;
        -webkit-font-smoothing: antialiased;
    }
}

.data-icon {
    position: relative;
    min-width: 25px;
    height: 25px;

    > * {
        position: absolute;
        left: 0;
        @include addTransition();
    }
}
/* -----BaseCloud----- */

.meteo-basecloud {
    font-size: inherit;
}

.meteo-basecloud:before {
    font-family: 'iconvault';
    content: '\f105';
    position: relative;
    color: rgb(204, 204, 204);
}

/* -----windyraincloud----- */

.meteo-windyraincloud:before {
    font-family: 'iconvault';
    font-size: 6em;
    position: relative;
    color: rgb(204, 204, 204);
}

/* -----Windysnowcloud----- */

.meteo-windysnowcloud:before {
    font-family: 'iconvault';
    content: '\f109';
    position: relative;
    color: rgb(204, 204, 204);
}

/* -----Basethundercloud----- */

.meteo-basethundercloud:before {
    font-family: 'iconvault';
    content: '\f105';
    position: relative;

}

/* -----Thunder----- */

.meteo-thunder::before  {
    content: "\f114";
    position:relative;
    color:rgb(255, 165, 0);
}

/* -----Sunny----- */

.meteo-sunny::after {
    content: "\f101";
    color:rgb(255, 165, 0);
    position: relative;
}


/* -----Drizzle----- */

.meteo-drizzle::before  {
    content: "\f10a";
    color: #82b2e4;
    position: relative;
}

/* -----Hail----- */

.meteo-hail::before {
    content: "\f10f";
    position:relative;
    color:rgb(204, 204, 204);}

/* -----Showers----- */

.meteo-showers::before  {
    content: "\f104";
    position:relative;
    color:#82b2e4;
}

/* -----Rainy----- */

.meteo-rainy::before {
    content: "\f107";
    position:relative;
    color:#4681c3;
}

/* -----Snowy----- */

.meteo-snowy::before  {
    content: "\f10b";
    position:relative;
    color:#acd3f3;
}

/* -----Frosty----- */

.meteo-frosty::before {
    content: "\f102";
    position:relative;
    color:#85d8f7;
}

/* -----Windy----- */

.meteo-windy::before  {
    content: "\f115";
    position:relative;
    color:rgb(204, 204, 204);
}

/* -----WindyRain----- */

.meteo-windyrain::before {
    content: "\f10e";
    position:relative;
    color:#acd3f3;
}

/* -----WindySnow----- */

.meteo-windysnow::before {
    content: "\f103";
    position:relative;
    color:#acd3f3;
}

/* -----Sleet----- */

.meteo-sleet::before  {
    content: "\f10c";
    position:relative;
    color:#acd3f3;
}

/* -----Moon----- */

.meteo-moon::after {
    content: "\f10d";
    color:rgb(255, 165, 0);
    position: relative;
}

/* -----Night----- */

.meteo-night::after {
    content: "\f100";
    position:relative;
    color:rgb(255, 165, 0);
}


/* -----Sun----- */

.meteo-sun::after {
    content: "\f113";
    color:rgb(255, 165, 0);
    position: relative;
}

/* -----Cloud----- */

.meteo-cloud::after {
    content: "\f106";
    color:rgb(204, 204, 204);
    position: relative;
}


/* -----Sunrise----- */

.meteo-sunrise:before  {
    content: '\f112';
    color:rgb(255, 165, 0);
    position: relative;
}

/* -----Sunset----- */

.meteo-sunset:before  {
    content: '\f110';
    color:#f96f23;
    position: relative;
}

/* -----Mist----- */

.meteo-mist:before  {
    content: '\f108';
    color:rgb(204, 204, 204);
    position: relative;
}
