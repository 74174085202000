// Styles pour les headings
.header-edito {
  position: relative;
  height: 100vh;
  width: 100%;
  max-width: calc(100% - 160px);
  margin: 65px auto;

  @include tablet-portrait {
    margin: 40px auto;
    max-width: calc(100% - 80px);
  }

  @include mobile {
    margin: 20px auto;
    max-width: calc(100% - 40px);
  }

  &:before {
    content: "";
    background-color: $c_sub;
    height: 75%;
    position: absolute;
    z-index: -1;
    right: -80px;
    left: -80px;
    top: -65px;
    @include tablet-portrait {
      right: -40px;
      left: -40px;
    }
    @include mobile {
      right: -20px;
      left: -20px;
    }
  }

  .general-container {
    position: relative;
    height: calc(100vh - 80px);
    min-height: 200px;
    max-height: -webkit-fill-available;
    overflow: hidden;

    @include tablet-landscape {
      //height: 800px;
    }
    @include mobile {
      //height: 500px;
    }

    .video-couverture {
      height: 100%;

      video {
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
      }
    }
  }


  figure {
    height: auto;
    width: auto;
  }

  // Styles générales aux deux types de header
  .header-edito-wrapper {
    display: flex;
    flex-direction: column;
    width: auto;

    // Wrapper custom afin d'éviter d'avoir un header en format small dans les grands écrans
    &.wrapper-custom {
      position: relative;
      margin: 0 auto;
      max-width: $wrapper-width;

      @media screen and (max-width: $wrapper-width+$border-gutter+$border-gutter+0px) {
        margin: 0 $border-gutter;
      }
      @include wide {
        max-width: $wrapper-width !important;
      }
      @media screen and (min-width: $wrapper-small-width+$border-gutter+$border-gutter+$grid-gap) and (max-width: map-get($breakpoints, 'wide')) {
        max-width: $wrapper-small-width !important;
        margin: 0 auto !important;
      }
      @include tablet-landscape {
        margin-left: 40px;
        margin-right: 40px;
      }
      // Breakpoint mobile, on met les marge de l'écran pour tout
      @include mobile {
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    > .container {
      display: flex;
      align-items: center;
      margin-bottom: 7px;

      [class^="icon-"], [class*=" icon-"] {
        color: $c_white;
      }

      .classement {
        margin-left: 10px;
      }
    }

    .surtitre {
      color: $c_white;
      margin: 0;
    }

    .breadcrumbs {
      margin-bottom: 0;
      @include mobile {
        display: none;
      }
    }

    .btn {
      align-self: flex-start;
      margin-bottom: 0;
    }

    p {
      color: $c_white;
    }

    h1 {
      color: $c_white;
    }

    .meta-wrapper {
      margin: 0;
      margin-top: 10px;
      margin-left: 0;

      .tooltiptext {
        top: calc(-100%);
        border: 2px;
        left: 5%;
      }

      .meta {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        color: $c_txt;
        font-weight: 500;
        border: 1px solid $c_white;

        &:hover, &:focus {
          body:not(.no-hover) & {
            .tooltiptext {
              visibility: visible;
            }
          }
        }
      }
    }

    .date-event {
      @include ft($f_hx, $s_medium, 700, $c_white, 22px);
      font-style: italic;

      > * {
        color: $c_white;
        display: inline-block;
      }

      .date-event, .date-unique {
        display: inline-block;

        .day-and-month {
          display: inline-block;
        }
      }

      + .location {
        margin-top: 10px;
      }
    }

    .container-info-supp {
      margin-left: auto;
      margin-top: auto;

      @include min-tablet-portrait {
        position: absolute;
        bottom: 5px;
        right: 0;
      }

      @include tablet-portrait {
        margin-left: 0;
        margin-top: 10px;
      }

      .author {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @include tablet-portrait {
          flex-direction: row-reverse;
          margin-left: 0;
          margin-top: 20px;
        }
        @include mobile {
          margin-top: 0;
        }

        figure {
          width: 47px;
          height: 47px;
          border-radius: 50%;
          border: 2px solid $c_white;

          img {
            height: auto;
            border-radius: 50%;
          }
        }

        .author-info {
          @include ft($f_txt, $s_p, 300, $c_white, 1.25);
          text-align: right;

          @include tablet-portrait {
            text-align: left;
          }

          .author-name {
            display: block;
            color: $c_white;
            font-weight: 700;
          }

          & + figure {
            margin-left: 15px;

            @include tablet-portrait {
              margin-left: 0;
              margin-right: 15px;
            }
          }
        }
      }

      > * {
        display: block;
        color: $c_white;
        text-align: right;
      }


      .price {
        > * {
          display: block;
          color: $c_white;
          text-align: right;
        }

        .light-text {
          margin-bottom: 0;

          @include mobile {
            font-size: $s_link;
            line-height: 1;
            margin-top: 0;
          }
        }

        .second-text {
          font-size: 35px;
          line-height: 1;
          font-weight: 300;

          @include mobile {
            font-size: $s_link;
          }

          strong {
            font-weight: 700;
          }
        }
      }
    }
  }

  &:not(.header-full-image) {
    background-color: $c_bg;
    height: auto;
    border-radius: $r_small;
    padding: 100px 80px 80px;
    @extend %motif-cover;
    @media screen and (max-width: 1160px) {
      padding: 100px 40px 80px;
    }
    @include tablet-landscape {
      padding: 100px 40px 40px;
    }
    @include mobile {
      padding: 100px 25px 40px;
    }

    // bg rose
    &:before {
      height: 100%;
    }

    // motif cover
    &:after {
      right: 0;
      top: -1px;

      @include desktop {
        //	right: -40px;
        right: -10px;
      }

      @include tablet-small {
        right: -12px;
      }
    }

    .general-container {
      height: auto;

      @include mobile {
        padding-top: 30px;
      }

      > figure {
        display: none;
      }
    }

    .wrapper-custom {
      > * {
        max-width: 830px;
      }

      h1 {
        @include min-tablet-portrait {
          max-width: 80%;
        }
      }

    }

    & + .content {
      top: auto;

      .barre-single-sit {
        height: 0;
      }
    }
  }

  // Styles pour la version full-screen du header
  &.header-full-image {
    max-height: calc(100vh - 130px); // espace en haut (65px) + espace en bas (65px)
    .general-container {
      border-radius: $r_small;
      /*	@include mobile {
              height: 500px;
          } */

      > figure {
        position: relative;
        display: block;
        height: 100%;

        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
          background: linear-gradient(-90deg, rgba(13, 14, 39, 0), rgba(13, 14, 39, 0.40));
          pointer-events: none;
        }


      }

      .fit-cover {
        border-radius: $r_small;

        img, picture {
          border-radius: $r_small;
        }
      }
    }

    & + .content {
      .barre-single-sit {
        & + .bloc {
          padding-top: 60px;
        }
      }

      > div:last-of-type {
        @include min-tablet-landscape {
          padding-bottom: 150px;
        }
      }
    }

    .header-edito-wrapper {
      position: absolute;
      bottom: 110px;
      right: 0;
      left: 0;
      width: auto;
      z-index: 10;

      > * {
        @include min-tablet-portrait {
          max-width: 60%;
        }
      }
    }

  }
}


.header-edito-intro {
  padding: 0 0 80px 0;

  @include mobile {
    padding: 0 0 50px 0;
  }

  > * {
    font-size: 16px !important;
    line-height: 1.5 !important;
    font-weight: 700 !important;
  }
}