// ==============================================

//        VARIABLES SASS & CSS

// ==============================================


// Fonts
// ==========================================================================
$f_hx: 'capitolium-news-2', Sans-Serif;
$f_txt: 'noto-sans', Sans-Serif;
$f_spe: 'noto-sans-semicondensed', Sans-Serif;

// Colors
// ===========================================================================
$c_main: #D8374A;
$c_sub: #FBDBDF;
$c_sub_light: #FDEDEF;
$c_txt: #47322B;
$c_bg: #0D0E27;


$c_grey: #333333;
$c_light: #F5F5F5;
$c_dark: #000000;
$c_greydark: #707070;
$c_white: #FFFFFF;

$c_red: #F21D4E;
$c_error: #E91741;
$c_green: #00C754;

// Sizes
// ==========================================================================
$s_h1: 80px;
$s_h2: 65px;
$s_h3: 35px;
$s_small_title: 22px;
$s_medium: 18px;
$s_p: 16px;
$s_link: 16px;
$s_link_small: 12px;


// radius
$r_small: 4px;

// Gestion des valeurs des z-index
// ==========================================================================
$z-layers: (
		'overlay' : 200,
		'mega-menu': 150,
		'overlay-shadow': 100,
		'strate-info': 90,
		'barre-navigation': 80,
		'toolbar': 70,
		'barre-sticky' : 50,
		'social-share' : 40,
		'couverture' : 30,
		'strate-switch' : 21,
		'btn-slider' : 20,
		'credits-image' : 10,
		'default' : 1,
		'below' : -1,
);


// Width / Height / Spacing
// ==========================================================================


$small-margin: 20px;


// Wrappers
// ==========================================================================

$wrapper-width: 1120px; // /!\ anciennement $max-width, taille par défaut quand classe .wrapper seule
$wrapper-medium-width: 1080px; // /!\ anciennement $grid-medium
$wrapper-small-width: 900px; // /!\ anciennement $grid-small
$wrapper-extra-small-width: 710px; // /!\ anciennement $grid-extra-small
$wrapper-large-width: 1680px; // /!\ anciennement $max-width-large ($max-width-large: 1600px - $border-gutter - $border-gutter;)


// ---- nouvelles variables pour gérer le layout général -----

$border-gutter: 80px; // marges gauche et droite minimum autour des wrappers
$border-gutter-tablet: 40px;
$border-gutter-mobile: 20px;

$grid-gap: 30px; // /!\ anciennement $content-gutter
$grid-gap-small: 25px;
$grid-gap-mobile: 15px;

$section-spacing: 80px;
$section-spacing-mobile: 40px;

$bloc-spacing: 40px; // /!\ anciennement $content-spacing
$bloc-spacing-mobile: 30px;

$strate-spacing: 80px;
$strate-spacing-mobile: 50px;

$header-height: 80px;
$header-height-mobile: 80px;

$toolbar-height: 60px;
$toolbar-height-mobile: 60px;


// Breakpoints
// ==========================================================================

$breakpoints: (
		mobile-small : 320px,
		mobile : 599px,
		tablet-small : 768px,
		tablet-portrait : 980px,
		tablet-landscape: 1024px,
		desktop : 1280px,
		wide : 1600px
);

// Breakpoints (Ancien système sans map)
// ==========================================================================
$breakpoint_mo_small: 320px;
$breakpoint_mo: 599px;
$breakpoint_tablet-small: 768px;
$breakpoint_portrait: 980px;
$breakpoint_paysage: 1024px;
$breakpoint_desktop: 1280px;
$breakpoint_wd: 1680px;


// Transition
// ==========================================================================
$transition-overlay-easing: cubic-bezier(0.86, 0, 0.07, 1);


// CSS Variables
// ==========================================================================

// Changement dynamiques des CSS vars en fonction des breakpoints
:root {
	--border-gutter: #{$border-gutter};

	--section-spacing: #{$section-spacing};
	--bloc-spacing: #{$bloc-spacing};
	--strate-spacing: #{$strate-spacing};

	--grid-gap: #{$grid-gap};
	--grid-gap-small: #{$grid-gap-small};

	--header-height: #{$header-height};
	--toolbar-height: #{$toolbar-height};

	@media screen and (max-width: map-get($breakpoints, 'tablet-landscape')) {
		--border-gutter: #{$border-gutter-tablet};
	}

	@media screen and (max-width: map-get($breakpoints, 'mobile')) {
		--border-gutter: #{$border-gutter-mobile};

		--section-spacing: #{$section-spacing-mobile};
		--bloc-spacing: #{$bloc-spacing-mobile};
		--strate-spacing: #{$strate-spacing-mobile};

		--grid-gap: #{$grid-gap-mobile};
		--grid-gap-small: #{$grid-gap-mobile};

		--header-height: #{$header-height-mobile};
		--toolbar-height: #{$toolbar-height-mobile};
	}
}

