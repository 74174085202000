
.bloc-top {
  display: grid;
  grid-template-areas: "text image";
  grid-template-columns: 1fr 1fr;
  gap: var(--grid-gap);
  align-items: start;

  // -- modifiers
  // Texte à droite et photo à gauche -> inversion par rapport au DOM
  &.--text-right {
    grid-template-areas: "image text";

    @include min-desktop {
      .col-text {
        //  padding-right: 110px;
      }
    }
  }

  // Texte à gauche et photo à droite -> texte aligné à droite (seulement en tablet et >)
  &.--text-left {
    @include min-desktop {
      .col-text {
        //  padding-left: 110px;
      }
    }

    @include min-mobile {
      .col-text {
        text-align: right;

        // alignement à droite spécial pour les listes à puces
        ul:not(.meta-wrapper) {
          margin-left: 0;
          margin-right: 0;

          li {
            padding-left: initial;
            padding-right: 1.6em;

            &:before {
              left: initial;
              right: 4px;
            }
          }
        }

        .container,
        .meta-wrapper,
        .location {
          justify-content: flex-end;
        }

        .meta-wrapper .meta {
          margin-right: 0;
          margin-left: 5px;

          &:first-of-type {
            margin-left: 0;
          }
        }
      }
    }
  }

  // Toujours image en premier et texte aligné à gauche sur mobile
  &.--text-right,
  &.--text-left {
    @include mobile {
      grid-template-areas: "image"
                                 "text";
      grid-template-columns: 1fr;
    }
  }

  .col-text {
    grid-area: text;
    margin-top: auto; // texte centré verticalement seulement si plus petit que l'image
    margin-bottom: auto;

    @include mobile {
      padding-bottom: var(--grid-gap);
    }
  }

  .col-image {
    grid-area: image;
  }

  .image {
    figure {
      background-color: initial;
    }

    img {
      height: auto;
      width: 100%;
    }

  }


  // Champs de la colonne de texte

  .number {
    @include ft($f_spe, 100px, 800, $c_sub_light, 1);
    display: block;
    margin-bottom: -15px;

    @include tablet-portrait {
      font-size: 60px;
    }

    // décalage du nombre sur l'image en mobile
    @include mobile {
      position: relative;
      margin-top: -90px !important;
      margin-bottom: 25px;
      margin-left: 20px;
      color: rgba($c_white, 0.5);
    }

    & + .container {
      margin-top: 20px;
    }
  }


  .container {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .surtitre {
      margin: 0;
      padding: 0;

      & + .classement {
        margin-left: 5px;
        color: $c_main;
      }
    }

    .classement {
      display: flex;
      align-items: center;
    }

    & + h2 {
      margin-top: 0;
    }
  }

  .meta-wrapper {
    margin-top: 12px;
    margin-bottom: 25px;
    margin-left: 0;
    margin-right: 0;

    & + .location {
      margin-top: -10px;
    }

    & + p {
      margin-top: -10px;
    }

    .meta {
      display: flex;
      justify-content: center;
      align-items: center;

      [class^="icon-"], [class*=" icon-"] {
        & + span {
          margin-left: 10px;
        }
      }
    }
  }

  .location {
    display: flex;
    align-items: center;
    padding: 0;

    &:before {
      position: relative;
      margin-right: 10px;
      line-height: 1.25;
    }

    & + p {
      margin-top: 20px;
    }
  }

  &.--has-number {
    @include mobile {
      .image:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        display: block;
        width: 219px;
        height: 219px;
        background-image: url("../images/deco/radial-gradient-bottom-big.svg");
        background-position: right bottom;
        background-repeat: no-repeat;
        pointer-events: none;
        opacity: 0.40;
        transform: scaleX(-1);
      }
    }
  }
}
