.barre-single-sit {

	.container {
		height: fit-content;

		.content {
			max-width: 400px;
			padding-right:20px;
		}
		.content, .btn-map-sit {
			@include tablet-portrait {
				display: none;
			}
		}

		.content-container {
			display:flex;
			align-items:center;

			> * {
				color:$c_white;
			}
		}

		.classement {
			margin-left: 8px;
		}

		.surtitre {
			@include ft($f_txt, $s_link_small, 500, $c_white, 24px);
			text-transform:uppercase;
		}

		.title {
			margin-top:-5px;
			@include ft($f_hx, $s_small_title, 700, $c_white, 24);
			font-style:italic;
			@include ellipsis(1, 26px);
		}

		.date-event {
			> * {
				display:inline-block;
			}

			.day-and-month {
				display:inline-block;
			}

			@include ft('', $s_link_small, 400, $c_white, 1);
			color:$c_white;
		}

		figure {
			flex-shrink: 0;
			border-radius:0 $r_small $r_small 0;

			img {
				border-radius:0 $r_small $r_small 0;
			}
		}

		.btn-map-sit {
			& + .content {
				margin-left: 20px;
			}
		}

	}

	.right-side {
		margin-left: auto;
		display: flex;
		align-items: center;

		@include tablet-portrait {
			margin: 0 auto;
		}
	}


	.liens-rapides {
			display: flex;
			align-items: center;
			margin-left: auto;
			margin-right: 20px;

			li {
				margin-right: 20px;
				@include addTransition();
				border:1px solid $c_white;
				border-radius:50%;
				width: 40px;
				height:40px;

				@include mobile {
					width: 30px;
					height: 30px;
				}

				&:first-of-type {
					@include mobile {
						margin-left: -7px;
					}
				}

				&:last-of-type {
					margin-right: 0;
				}

				&:before {
					font-size: 18px;
					color:$c_white;

					@include mobile {
						font-size: 16px;
					}
				}

				&[data-icon="location"] {
					&:before {
						content: $icon-ico-location;
					}
				}

				&[data-icon="telephone"] {
					&:before {
						content: $icon-ico-phone;
					}
				}

				&[data-icon="mail"] {
					&:before {
						content: $icon-ico-email;
					}
				}

				&[data-icon="lien"] {
					&:before {
						content: $icon-ico-link;
					}
				}

				.gtm-event-mail {
					.tooltiptext {
						margin-left: -76px;
						width:initial;
					}
				}

				.tooltiptext {
					top: calc(-100% + 30px);
					margin-left: -58px;
				}


			@include can-hover {
				background-color:$c_white;

				&:before {
					color:$c_txt;
				}

				.tooltiptext {
					visibility: visible;
				}

			}
		}
	}

	.btn + .btn-favoris {
		margin-left:20px;

		@include mobile {
			margin-left: 10px;
			padding: 20px 5px;
		}
	}


	/* & + footer {
		padding-bottom: 120px;

		@include tablet-landscape {
			padding-bottom: 100px;
		}
	}*/
}
