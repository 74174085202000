.bloc-images {
	display: flex;
	flex-direction: column;

	.wrapper:first-of-type {
		@include desktop {
			margin: 0;
			width: 100%;
			max-width: none;
		}
	}

	.image-wysiwyg {
		&:not(.full-image) {
			margin-right: initial;
		}

		figure {
			background: initial;

			// supprime le voile par défaut de la classe image-wysiwyg
			&:after {
				display: none;
			}
		}

		/*img {
			min-height: 560px;

			@include mobile {
				min-height: 460px;
			}
		}*/

		.caption {
			p {
				line-height:24px;
				font-size:14px;
			}
		}
	}

	.img-wrapper {
		position: relative;
		display: block;
	}


	// gradient sur le caption wrapper
	.caption-wrapper {
		@include aspect-ratio(900, 470);
		position: absolute;     // Surcharge de la position relative définie par défaut dans le mixin d'aspect-ratio
		top: 0;
		left: 0;
		width: 100%;

		@media screen and (max-width: 420px) {
			@include aspect-ratio(380, 480);
			position: absolute;
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			background-image: linear-gradient(to right, rgba($c_bg, 1), rgba($c_bg, 0));
			opacity: 0.5;
			pointer-events: none;
		}

		.caption {
			bottom:57px;
		}
	}

	.image-wysiwyg.alignleft .caption-wrapper {
		&:after {
			background-image: linear-gradient(to right, rgba($c_bg, 1), rgba($c_bg, 0));
		}
	}

	.image-wysiwyg.alignright .caption-wrapper {
		&:after {
			background-image: linear-gradient(to left, rgba($c_bg, 1), rgba($c_bg, 0));
		}
	}

	.image-wysiwyg.aligncenter .caption-wrapper {
		&:after {
			background-image: linear-gradient(to top, rgba($c_bg, 1), rgba($c_bg, 0));
		}
	}


	.container {
		position: relative;
	}

	.nav-buttons {
		text-align:right
	}


	&.wrapper:not(.wrapper-small) {
		.th-slider {
			@include desktop {
				width: calc(((100vw - 100%) / 2) + 100%);
			}
			.image-wysiwyg {
				@include desktop {
					width: 100%;
				}
			}
		}
	}

	.th-slider {
		.image-wysiwyg {
			margin-left: ($small-margin / 2);
			margin-right: ($small-margin / 2);
			@include mobile {
				width: calc(100% - 20px);
			}

			figcaption {
				display: none;
			}
		}
	}
}