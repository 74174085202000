.strate-footer-social-wall {
	width: auto;

	@include mobile {
		width: 100%;
		overflow: hidden;
	}

	.wrapper.scroll-mode {
		margin: 0;
		width: 100%;

		.th-slider {
			overflow: auto;
			scroll-snap-type: x mandatory;
			width: calc(100%);
			padding-right: 40px;
			@include tablet-landscape {
				padding-left: 40px;
				padding-right: 40px;
				scroll-padding: 40px;
				&:after {
					content: '';
					min-width: 40px;
				}
			}
			@include mobile {
				padding-left: 20px;
				padding-right: 20px;
				scroll-padding: 20px;
				&:after {
					content: '';
					min-width: 20px;
				}
			}
		}
	}

	.text-wrapper {
		margin-bottom: 30px;
	}

	.social-links {
		margin-bottom: 40px;

		@include mobile {
			margin-bottom: 30px;
		}

		a {
			width: 50px;
			height: 50px;
			margin-right: 15px;
			// Lorsqu'on a plus de 5 liens sociaux, on ajoute au margin-top aux éléments qui vont aller à la ligne pour éviter d'avoir tout collé.
			// Le numéro 6 est à changer selon vos besoins
			@include mobile {
				&:nth-child(n+6) {
					margin-top: 5px;
				}
			}
			@include mobile-small {
				&:nth-child(n+5) {
					margin-top: 5px;
				}
			}
		}
	}

	.nav-buttons {
		position: absolute;
		bottom: 0;
		right: 0;
		height: auto;
		@include mobile {
			position: static;
			margin-bottom: 20px;
		}

		button {
			&:first-of-type {
				margin-right: 2px;
			}
		}
	}

	.slide {
		position: relative;
		width: 240px;
		height: 240px;
		border-radius: $r_small;
		margin-right: 20px;

		img, figure {
			border-radius: $r_small;
		}

		@include tablet-portrait {
			width: 160px;
			height: 160px;
			img {
				height: auto;
			}
		}
		@include mobile {
			margin-right: 14px;
		}

		&:before {
			@include icomoon($icon-ico-instagram);
			display: block;
			font-size: 20px;
			color: $c_white;
			position: absolute;
			top: 20px;
			right: 20px;
			z-index: 1;
			@include addTransition();
		}


		&:hover, &:focus {
			body:not(.no-hover) & {
				&:before {
					color: $c_main;
					transform: scale(1.1);
				}
			}
		}
	}
}
