.bloc-les-plus {
  background-color:$c_bg;
  padding:69px 95px 60px 95px;

  @include tablet-portrait {
    padding:50px 75px 40px 75px;
  }

  @include mobile {
    padding:30px 55px 20px 55px;
  }

  @include mobile {
    padding:30px 35px 30px 35px;
  }

  .text-styles {
    > * {
      color:$c_white;
    }

    p {
      color:$c_white;
    }
  }
}