/* ------------------
--- CSS GLOBAL + DES CLASSES UTILES ---
------------------ */

html, body {
	height: 100%;
}

html {
	font-size: 100%;
	margin-top: 0 !important;
}

body {
	font-family: $f_txt;
	font-size: 16px;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $c_txt;
	overflow-x: hidden;

	&.no-scroll {
		overflow: hidden;
	}

	&.fixed {
		@include absolute-stretch();
		position: fixed;
	}
}


main {
	position: relative;
}

[class^="icon-"], [class*=" icon-"] {
	@include addTransition();
}


// @extend
// ==========================================================================
%appearance {
	-webkit-appearance: none;
	resize: none;
	box-shadow: none;
	border-radius: 0;
	border: none;
	outline: none;
	&::-ms-clear{
		display: none;
	}
}
