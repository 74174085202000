
.overlay-menu {
  z-index: 10001;
  max-width: 100%;
  opacity: 1;
  visibility: visible;
  pointer-events: none;
  background-color: transparent;
  @include addTransition();

  > * {
    pointer-events: auto;
  }

  //Close
  .btn-close {
    position: absolute;
    top:40px;
    right: 40px;
    z-index: 30;

    @include tablet-portrait{
      top:40px;
      right: 40px;
    }

    @include mobile {
      top:20px;
      right: 20px;
    }
  }



  //Partie main menu
  &__right {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
   // width: 440px;
    width:33%;
    padding: 40px 40px 40px 55px;
    overflow: auto;
    display: flex;
    background-color:$c_main;
    z-index: 10;
    @media screen and (max-height: 680px) and (min-width: 981px){
      padding: 40px 40px 40px 55px;
    }
    @include desktop {
      width:50%;
    }
    @include tablet-portrait{
      width: 100%;
      padding: 0 40px;
      overflow: auto;
    }

    @include mobile {
      padding: 0 20px;
    }

    .container{
      margin: 0;
      width: 100%;
   //   display:block;
      flex-direction: column;
      display: flex;
      @include tablet-portrait{
        padding: 40px 0;
      }
      @include mobile{
        padding: 20px 0;
      }
    }
  }
  //Partie sub menu
  &__left {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 33%;
    right:33%;
    pointer-events: none;
    z-index: 20;
    @include desktop {
      width:50%;
      right:50%;
    }

    @include tablet-portrait{
      width: 100%;
      right:0;
    }
  }

  // barre de recherche
  .search-barre-container {
    height:60px;
    width: 100%;
    padding-right:100px;
    margin-bottom:25px;

    @media screen and (max-height: 680px) and (min-width: 981px){
      margin-bottom:20px;
    }

    .btn-search-barre {
      width: 100%;
      color:$c_white;
      position:relative;

      span {
        position:absolute;
        left:0;
        color:$c_white;
        top:50%;
        transform: translate(0, -50%);
      }

      .input-search {
        @include reset-appearance;
        background:none;
        width: 100%;
        height:60px;
        padding:20px 20px 20px 40px;
        border-bottom:1px solid rgba($c_white, 0.20);
        &::placeholder{
          color: $c_white;
        }
      }
    }
  }


  // Nav primary
  // ==========================================================================
  .nav-primary{
    display: block;
    //a et label
    &__link{
      @include ft($f_hx, 35px, 900, $c_white, 1);
      @include addTransition();
      position: relative;
      display: block;
      padding: 15px 0;
      cursor: pointer;
      transform:translate3d(0,0,0);
      @media screen and (max-height: 650px) and (min-width: 981px){
        font-size: 30px;
        line-height: 30px;
        padding: 10px 0;
      }
      @include tablet-portrait{
        text-align: center;
      }
      @include mobile{
        font-size: 35px;
        line-height: 30px;
        padding: 10px 0;
      }

      @include can-hover{
        color: $c_sub;
        font-style:italic;
      }

      &.actif{
        color: $c_sub;
        font-style:italic;
      }
    }


    &__input{
      display: none;
      &:checked{
        +.nav-primary__submenu-wrapper{
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

    //Sous-menus
    &__submenu-wrapper{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $c_txt;
      opacity: 0;
      display:flex;
      flex-direction: column;
      justify-content: center;
      pointer-events: none;
      padding: 140px 140px 60px 60px;
      overflow: auto;
      @include addTransition();
      @media screen and (max-height: 680px) and (min-width: 981px){
        padding: 80px 60px;
      }
      @media screen and (max-width: 1150px) {
        padding-left: 80px;
      }
      @include tablet-portrait{
        text-align: center;
        padding: 60px 40px;
      }
      @include mobile{
        padding: 60px 20px;
      }

      &__submenu-back{
        position: relative;
        display: none;
        text-transform: uppercase;
        @include ft($f_hx, 20px, 700, $c_white, 1);
        letter-spacing: 1px;
        margin-bottom: 20px;
        cursor:pointer;
        justify-content: center;
        text-align: center;
        span{
          font-size:15px;
          margin-right:5px;
          margin-top:2px;
        }

        @include can-hover{
          color:$c_sub;
        }

        @include tablet-portrait{
          display: flex;
        }

        @include mobile{
          font-size: 14px;
          span{
            margin-top:1px;
            font-size:12px;
          }
        }
      }
      &__link{
        position: relative;
        display: block;
        @include ft($f_hx, 28px, 700, $c_sub, 30px);
        padding: 15px 0;
        @include addTransition();
        @media screen and (max-height: 680px) and (min-width: 981px){
          font-size: 18px;
          line-height: 24px;
          padding: 10px 0;
        }
        @include tablet-portrait{
          text-align: center;
        }
        @include mobile{
          font-size: 18px;
          line-height: 24px;
          padding: 7px 0;
        }

        @include can-hover{
          font-style:italic;
          color: $c_white;
        }
      }
    }
  }


  // Nav secondary
  // ==========================================================================
  .nav-secondary{
    display: block;
    //ul
    &__menu{
      padding: 0;
      margin-top: 35px;
      @media screen and (max-height: 650px) and (min-width: 981px){
        margin-top: 15px;
      }
    }


    //a et label
    &__link{
      @include ft($f_hx, 22px, 700, $c_white, 24px);
      @include addTransition();
      position: relative;
      display: block;
      padding: 10px 0;
      @media screen and (max-height: 650px) and (min-width: 981px){
        font-size: 16px;
        line-height: 22px;
        padding: 5px 0;
      }
      @include tablet-portrait{
        text-align: center;
      }
      @include mobile{
        font-size: 16px;
        line-height: 22px;
        padding: 5px 0;
      }

      @include can-hover {
        color: $c_sub;
        font-style:italic;
      }


      &.nav-secondary__link__big {
        border:1px solid rgba($c_white, 0.30);
        padding:10px 30px 10px 10px;
        display:flex;
        align-items:center;
        margin-bottom:10px;

        @include can-hover {
          background-color:$c_white;
          > * {
            color:$c_txt;
          }
          .link__big_title {
            color:$c_txt;
            font-style:normal;
          }
        }

        .link__big {
          margin-left:18px;
          display:flex;
          text-align:left;
          flex-direction:column;
        }

        .link__big_title {
          @include ft($f_txt, 14px, 400, $c_white, 22px);
          margin-top:6px;
        }

        figure {
          background:none;
          img {
            max-width: 88px;
            height: auto;
          }
        }
      }
    }
  }


  //social et langues
  // ==========================================================================

  .social-links-lang {
    margin-top: auto;
    display: flex;
    padding:50px 0 0 0;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }


  // social
  // ==========================================================================
  .social-links {

  }

  // lang
  .lang-dropdown {
    a {
      &:hover, &:focus {
        color: $c_txt;
      }
    }
  }
}
