.bloc-actualites {

  .wrapper-cards-bg {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 50px;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $c_main;
      z-index: 0;
    }
  }

  .bloc-actualites-intro {
    padding-bottom: 65px;
    text-align: center;
  }

  .bloc-actualites-footer {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 80px;

    @include mobile {
      padding: 60px 0;
    }

    .btn {
      background-color: $c_white;
      border: $c_white;
      color: $c_txt;

      &:hover, &:focus {
        body:not(.no-hover) & {
          background-color: $c_txt;
          border: $c_txt;
          color: $c_white;
        }
      }
    }
  }

  .wrapper-cards {
    align-items: start;

    @media (min-width: $breakpoint_desktop) and (max-width: 1599px) {
      &.wrapper {
        max-width: 1120px;
        margin: 0 auto;
      }
    }
  }

  .post {
    margin-bottom: 0;
    @include min-wide {
      &:nth-child(2), &:nth-child(5) {
        margin-top: -30px;
      }
    }

    @include wide {
      &:nth-child(2n) {
        margin-top: -30px;
      }
    }

    @include tablet-small {
      margin-top: 0;
      &:nth-child(2n) {
        margin-top: 0;
      }
    }
  }
}