.strate-footer-mentions {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	border-top:1px solid rgba($c_main, 0.20);
	//min-height: 90px;
	padding:22px 0;

	@include mobile {
		justify-content: center;
	}

	span {
		@include ft($f_txt, 14px, 500, $c_txt, 24px);
	}

	ul {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		height: 100%;

		li {
			flex-shrink: 0;
			height: 100%;

			a {
				@include ft($f_txt, 14px, 500, $c_txt, 24px);
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				padding-right: 18px;

				&:hover, &:focus {
					body:not(.no-hover) & {
						color: $c_main;
					}
				}
			}

			&:last-of-type {
				a {
					padding-right: 0;
				}
			}
		}
	}
}