.strate-footer-comment-venir {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: -200px;
    left: 0;
    bottom: -40px;
    right: 0;
    background-color: $c_sub_light;
    z-index: -1;
    @include mobile {
      top: -140px;
    }
  }

  .wrapper {
    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .texte {
    display: block;
    width: calc(100% - 368px);
    @include min-tablet-portrait {
      position: absolute;
    }
    @include tablet-portrait {
      width: 100%;
    }
  }

  .map {
    display: block;
    margin-top: 50px;
    float: left;
    width: 907px;
    @include tablet-portrait {
      width: 100%;
      margin-top: 10px;
    }
    @include tablet-portrait {
      margin-top: 20px;
    }

    figure {
      background-color: transparent;

      &.desktop {
        display: block;
        @include mobile {
          display: none;
        }
      }

      &.mobile {
        display: none;
        @include mobile {
          display: block;
        }
      }

      img {
        margin: 0 auto;
        max-width: 100%;
        height: auto;
      }
    }
  }
}


