.strate-footer-widgets {
  background-color:$c_bg;
  position:relative;

  @include min-mobile {
    padding-bottom:100px;
  }

    &:before {
      content: '';
      position: absolute;
      top: -40px;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $c_bg;
      z-index: -1;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50px;
      left: 0;
      bottom: -40px;
      right: 0;
      z-index: -1;
      background-color: $c_bg;

      @include mobile {
        top:40px;
      }
  }

  .basic-grid {
    @include tablet-small {
      gap:0;
    }
  }


  // Désactivation de l'accordéon au-dessus de tablet-small
  @include min-tablet-small {
    .accordeon {
      border:initial;
      .title-accordeon {
        pointer-events: none;

        &:after, &:before {
          content: none;
        }
      }

      .item {
        max-height: initial !important;     // fix pour surcharger la lib th_accordeon
        overflow: initial !important;
      }
    }
  }


  .title {
    @include ft($f_hx, $s_small_title, bold, $c_white, 1.25);
    font-style:italic;

    & + * {
      margin-top: $small-margin;
    }

    @include mobile {
      font-size:20px;
      line-height:24px;
    }
  }

  .widget-menu {
    a {
      display: inline-block;
      @include ft($f_txt, $s_p, 400, $c_white, 1.125);
      padding: 7px 0;

      &:hover, &:focus {
        body:not(.no-hover) & {
          color: $c_main;
        }
      }
    }

    // exemple de menu niv2
    ul ul {
      padding-left: 20px;
      a {
        font-size: 14px;
      }
    }
  }

  .widget-contact {
    p {
      color:$c_white;
    }

    .btn + .btn {
      margin-top: ($small-margin / 2);
    }

    .text-styles {
      margin-bottom: 20px;
      color:$c_white;
    }
  }
}
