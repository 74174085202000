
// Overlay - Meteo
// ==========================================================================
.overlay-meteo {
  max-width: none;

  .scroller {
    position: relative;
    padding: 60px 0;
  }
}


.overlay-meteo__inner {
  position: relative;
  padding: 20px 0;

  input {
    height: 0;
    width: 0;
    display: none;
    position: absolute;
  }

  @for $i from 1 through 2 {
    #radio-city-#{$i}:checked {
      & ~ .toggle-meteo label[for='radio-city-#{$i}'] {
        color:$c_main;
        @include addTransition();
      }

      @if $i == 2 {
        & ~ .toggle-meteo .switch:before {
          left: 21px;
        }
      }

      & ~ .container-cities #item-city-#{$i} {
        display: flex;
      }
    }
  }


  .toggle-meteo {
    display: flex;
    justify-content: center;
    align-items: center;

    @include min-tablet_portrait {
      padding: 0 90px;
    }

    @include mobile {
      flex-direction: column;
    }

    label {
      @include ft($f_hx, 35px, 700, rgba($c_main, 0.40), 1);
      font-style: italic;
      padding: 0 20px;
      cursor: pointer;
      @include addTransition();

      @include can-hover {
        color: $c_main;
      }

      &:first-of-type {
        text-align: right;
      }

      &:last-of-type {
        order: 1;			// passe le switch au milieu
      }

      @include tablet_small {
        font-size: 26px;
      }

      @include mobile {
        padding: 20px 0;
        font-size: 22px;
      }
    }

    .switch {
      flex-shrink: 0;
      position: relative;
      display: inline-flex;
      width: 40px;
      border-radius: 50px;
      border:1px solid $c_sub;
      height: 20px;

      &:before {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        background-color: $c_main;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
        @include addTransition();
      }
    }
  }


  .container-cities {
    margin-top: 60px;

    @include mobile {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  .item-city {
    display: none;		// hidden by default

    > * {
      width: 50%;

      &:first-child {
        border-right: 1px solid rgba($c_main, 0.30);
      }
    }


    @include tablet_landscape {
      flex-direction: column;

      > * {
        width: 100%;

        &:first-child {
          border-right: initial;
        }
      }
    }
  }


  .jour-meteo {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 70px;
    color: $c_txt;
    text-align: center;

    @include mobile {
      padding-top: 0;
      padding-bottom: 40px;
    }
  }

  .day-title {
    @include ft($f_hx, 28px, 700, $c_txt, 35px);
    font-style:italic;

    @include mobile {
      font-size:25px;
    }
  }

  // Colonne jour (Jeudi / vendredi)
  .day-side {
    //@include bd();
    display: flex;
    margin-top: 40px;

    @include mobile {
      margin-top: 20px;
    }

    > * {
      width: 50%;

      &:first-child {
        border-right: 1px solid rgba($c_main, 0.30);
      }
    }
  }


  // Colonne matin / après-midi
  .day-part {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 40px;
  }

  .day-part-title {
    text-transform:uppercase;
    @include ft($f_txt, 14px, 400, $c_txt, 26px);
  }

  .data-icon {
    display: block;
    width: 60px;
    height: 60px;
    margin-top: 15px;

    @include mobile {
      margin-top: 10px;
    }

    > * {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      &:before, &:after {
        font-size: 60px;
        color: $c_main;
      }
    }
  }


  .temp {
    font-size: 20px;
    font-weight: 700;

    @include mobile {
      font-size: 18px;
    }

    &.min {
      display: none;
    }
  }

  .description {
    margin-top: -5px;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
  }

  .data-supp {
    font-size: 14px;
    line-height: 26px;

    &.humid {
      margin-top: 10px;
    }
  }

 /* .phrase-description {
    @include ft($f_hx, 20px, 900, $c_txt, 1.1);
    margin-top: 10px;
    font-style: italic;
    max-width: 180px;
  } */
}
