$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts/icomoon/fonts" !default;

$icon-ico-map: "\e931";
$icon-ico-group: "\e947";
$icon-ico-check: "\e92f";
$icon-ico-navigation: "\e901";
$icon-ico-phone: "\e91c";
$icon-ico-chapeau: "\e938";
$icon-ico-prm: "\e93a";
$icon-ico-location: "\e93b";
$icon-ico-horloge: "\e93c";
$icon-ico-tiktok: "\e93d";
$icon-ico-close-circle: "\e93e";
$icon-ico-arrow-up: "\e93f";
$icon-ico-menu: "\e944";
$icon-ico-tripadvisor: "\e945";
$icon-ico-linkedin: "\e946";
$icon-ico-person: "\e943";
$icon-ico-paiement: "\e942";
$icon-ico-document: "\e940";
$icon-ico-langues: "\e941";
$icon-ico-coeur-rempli: "\e939";
$icon-ico-ampoule: "\e937";
$icon-ico-moins: "\e935";
$icon-ico-plus: "\e936";
$icon-ico-credits: "\e934";
$icon-ico-email: "\e900";
$icon-ico-link: "\e902";
$icon-ico-refresh: "\e903";
$icon-ico-social-share: "\e904";
$icon-ico-filtres: "\e905";
$icon-ico-grille-parapluie: "\e906";
$icon-ico-grille-cheval: "\e907";
$icon-ico-grille-famille: "\e908";
$icon-ico-grille-couple: "\e909";
$icon-ico-grille-bateau: "\e90a";
$icon-ico-grille-rando: "\e90b";
$icon-ico-grille-velo: "\e90c";
$icon-ico-grille-dejeuner: "\e90d";
$icon-ico-grille-logement: "\e90e";
$icon-ico-agrandir: "\e90f";
$icon-ico-play: "\e910";
$icon-ico-velo: "\e911";
$icon-ico-rando: "\e912";
$icon-ico-printemps: "\e913";
$icon-ico-hiver: "\e914";
$icon-ico-automne: "\e915";
$icon-ico-soleil: "\e916";
$icon-ico-repas-emporter: "\e917";
$icon-ico-vegan: "\e918";
$icon-ico-boussole: "\e919";
$icon-ico-jeu-enfant: "\e91a";
$icon-ico-natation: "\e91b";
$icon-ico-wifi: "\e91d";
$icon-ico-animal: "\e91e";
$icon-ico-restaurant: "\e91f";
$icon-ico-epis: "\e920";
$icon-ico-cle: "\e921";
$icon-ico-etoile: "\e922";
$icon-ico-agenda: "\e923";
$icon-ico-youtube: "\e933";
$icon-ico-pinterest: "\e924";
$icon-ico-instagram: "\e925";
$icon-ico-twitter: "\e926";
$icon-ico-facebook: "\e927";
$icon-ico-close: "\e928";
$icon-ico-telechargement: "\e929";
$icon-ico-arrow-down: "\e92a";
$icon-ico-arrow-left-slider: "\e92b";
$icon-ico-arrow-right-slider: "\e92c";
$icon-ico-search: "\e92d";
$icon-ico-coeur: "\e92e";
$icon-ico-ticket: "\e930";
$icon-ico-nuageux: "\e932";

