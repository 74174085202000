// Custom Windows Map
// ==========================================================================
.custom-popup-thuria {
	width: 410px;
	margin-bottom: 50px;
	@include mobile {
		width: 300px;
	}

	.leaflet-popup-wrapper {
		padding: 0;
		@include shadow();

		.leaflet-popup-content {
			margin: 0;
			width: 100% !important;

			.card-map {
				position: relative;
				top: auto;
				right: auto;
				transform: none;
				box-shadow: none;
				display: block;
			}

			.card-map-mobile {
				display: none;
				@include mobile {
					display: block;
				}
			}


		}
	}
	.leaflet-popup-tip-container {
		width: 20px;
		height: 20px;
		transform: rotate(45deg);
		position: absolute;
		left: 50%;
		margin-left: -10px;
		bottom: -10px;
		overflow: hidden;
		pointer-events: none;

		.leaflet-popup-tip {
			padding: 0;
			box-shadow: none;
			background: $c_bg;
			margin: 0 auto;
			width: 26px;
			height: 26px;
			border-radius: 0;
			transform: none;
		}
	}

	a.leaflet-popup-close-button {
		position: absolute;
		top: 0;
		right: 0;
		display:flex;
		justify-content: center;
		align-items:center;
		transform: translate(50%, -50%);
		background: $c_main;
		border-radius:$r_small;
		color: $c_white;
		padding: 0;
		font-size: 15px;
		width: 30px;
		height: 30px;
		line-height: 1;

		&:after {
			content: none;
		}

		&:hover {
			body:not(.no-hover) & {
				cursor: pointer;
				background: darken($c_main,10%);
				color: $c_white;
			}
		}
	}

	.card {
		@include card-horizontale();
		margin-bottom:0;
		.caption {
			.meta-wrapper {
				.meta {
					display:none;
				}
			}
			.reservation {
				display:none;
			}
		}

		&:hover, &:focus {
			body:not(.no-hover) & {
				transform:none;
			}
		}
	}
}