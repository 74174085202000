.switch {
	position: relative;
	input {
		display: none;

		&:checked {
			~ label {
				.txt:first-of-type {
					opacity: 0.40;
				}

				.txt:last-of-type {
					opacity: 1;
				}

				.bouton-switch {
					&:before {
						left: 22px;
					}
				}
			}
		}
	}

	label {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		.txt {
			@include ft($f_txt, $s_medium, 700, $c_white, 24px);
			@include addTransition();

			@include mobile {
				font-size: 14px;
				line-height: 1;
				text-align: center;
			}
			@include mobile-small {
				font-size: 12px;
			}

			&:last-of-type {
				opacity: 0.40;
			}

			&:hover, &:focus {
				body:not(.no-hover) & {
					opacity: 0.70;
				}
			}
		}

		.bouton-switch {
			position: relative;
			display: inline-block;
			width: 40px;
			height: 20px;
			border-radius: 50px;
			background-color: $c_white;
			margin: 0 $small-margin;

			@include mobile {
				margin: 0 10px;
			}

			&:before {
				content: "";
				display: block;
				width: 12px;
				height: 12px;
				background-color: $c_main;
				border-radius: 50%;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 5px;
				@include addTransition();
			}
		}
	}
}