// BLOC VIDEO
// ==========================================================================
.bloc-video {

	.container {
		position: relative;
		cursor: pointer;

		.mask-video {
			@include absolute-stretch();
			height: 100%;
			z-index: 10;
			opacity: 1;
			transition: all 0.7s ease-in-out;

			@include mobile {
				height: 100%;
			}

			& ~ .embed-container {
				opacity: 0;
			}

			&.hide-mask {
				figure {
					opacity: 0;

					@media screen and (max-width: 750px) {
						opacity: 1;
					}
				}

				& + .embed-container {
					opacity: 1;
					z-index: 100;
					pointer-events: auto;
				}
			}

			figure {
				position: relative;
				height: auto;
				opacity: 1;
				transition: all 0.7s ease-in-out;

				@include mobile {
					height: 100%;
				}

				img {
					height: 100%;
				}
			}

			.caption {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				top: 0;
				display: flex;
				justify-content: center;
				align-items: center;


				[class^="icon-"], [class*=" icon-"] {
					background-color:$c_main;
					display: flex;
					justify-content: center;
					align-items: center;
					width:64px;
					height:64px;
					padding:20px;
					font-size: 16px;
					border-radius:50%;
					color: $c_white;
					@include addTransition();
				}
			}
		}

		.embed-container {
			@include aspect-ratio(1280, 720);
			height: 100%;
			min-height: 200px;
			transition: all 0.7s ease-in-out;

			iframe, video {
				@include absolute-stretch();
				width: 100%;
				height: 100%;
			}
		}
	}

	&:hover, &:focus {
		body:not(.no-hover) & {
			.caption {
				[class^="icon-"], [class*=" icon-"] {
					transform: scale(1.1);
					@include addTransition();
				}
			}
		}
	}

}