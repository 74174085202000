// =============================================
// Share button
// =============================================

// Share Button Wrapper
.social-share {
  z-index: z-index('social-share');
  width: 40px;
  height: 40px;
  @include addTransition();

  @include mobile {
    width: 30px;
    height: 30px;
  }

  input {
    display: none;
  }
}

// Toggle Button
.toggle {
  position: relative;
  width: 40px;
  height: 40px;
  text-align: center;
  display: inline-block;
  font-size: 20px;
  color: $c_bg;
  @include addTransition();

  @include mobile {
    width: 30px;
    height: 30px;
  }

  span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover, &:focus {
    body:not(.no-hover) & {
      cursor: pointer;
      color: $c_sub;
    }
  }

  // Hidden input to toggle state
  &-input {
    display: none;
  }

}

// =============================================
// Network List
// =============================================

.network-list {
  // List Itemsocial-shake
  position: absolute;
  top: 0;
  pointer-events: none;

  li {
    position: absolute;
    top: 0;
    left: 10px;
    display: block;
    width: 40px;
    height: 40px;
    margin: 0;
    padding: 0;
    list-style-type: none;
    opacity: 0;
    transition: all 0.25s cubic-bezier(0.43, 1.17, 0.69, 1.24);

    &:hover, &:focus {
      body:not(.no-hover) & {
        a {
          background-color: $c_white;

          &:before {
            color: $c_bg;
          }
        }
      }
    }
  }

  // Link
  a {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: $c_bg;
    overflow: hidden;
    text-indent: 120%;
    text-decoration: none;
    @include addTransition();

    &:hover, &:focus {
      body:not(.no-hover) & {
        background: $c_main;
      }
    }
  }

  // Network Icon
  a:before {
    @include icomoon();
    @include preserve-px-unit(14px); // font-size fixe pour les icônes
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    text-indent: 0;
    @include addTransition();
  }

  a:after {
    content: '';
    width: 30px;
    height: 30px;
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: -1;
    border-radius: 50%;
  }

  // Twitter
  .twitter {
    a:before {
      content: $icon-ico-twitter;
      color: $c_white;
    }
  }

  // Facebook
  .facebook {
    a:before {
      content: $icon-ico-facebook;
      color: $c_white;
    }
  }

  .linkedin {
    a:before {
      content: $icon-ico-linkedin;
      color: $c_white;
    }
  }

  // Mail
  .mail {
    a:before {
      content: $icon-ico-email;
      color: $c_white;
    }
  }
}

// =============================================
// Open Network List

input:checked ~ .network-list {
  pointer-events: initial;

  li {
    opacity: 1;
  }

  li:nth-child(1) {
    top: -90px;
  }

  li:nth-child(2) {
    top: -140px;
  }

  li:nth-child(3) {
    top: -190px;
  }

  li:nth-child(4) {
    top: -240px;
  }

  li:nth-child(5) {
    top: -290px;
  }

  li:nth-child(6) {
    top: -340px;
  }
}