.bloc-mise-en-avant {
  position:relative;

  @include mobile {
    display:flex;
    flex-direction:column;

    .container-nav {
      order:3;
    }
  }


  // Styles pour l'affichage des cards en format verticales
  // ==========================================================================

  .container-slider-mise-en-avant {
    &.scroll-mode-parent {
      margin: 0;
      max-width: none;
    }
  }

  .wrapper:last-of-type {
    &.scroll-mode-parent {
      margin: 0;
      width: 100%;
      max-width: none;
    }
  }

  > .container-nav {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 50px;
    @include mobile {
      flex-direction: column;
      justify-content: flex-start;
    }

    .nav-buttons {
      @include mobile {
        display:none;
      }
    }

    .btn, .btn-primary, .btn {
      margin-left: auto;
      @include mobile {
        margin-left: 0;
        width:100%;
        margin-right: auto;
      }
    }
  }

  a.card-mise-en-avant {
    &:hover, &:focus {
      body:not(.no-hover) & {
        @include rotate();
        @include min-tablet_portrait {
          .caption {
            .btn-plus {
              visibility: visible;
              opacity:1;
              @include addTransition();
            }
          }
        }
      }
    }
  }

  .card-mise-en-avant {
    width: 900px;
    padding-bottom:40px;
    margin-right: 40px;
    position:relative;
    @include addTransition();

    @include mobile {
      margin-right: 15px;
      width: 260px;
      //	width: calc(100% - 20px);
    }

    .credits {
      display: none;
    }

    .image {
      position: relative;
      flex-shrink: 0;
      width: 100%;
      height: auto;
      border-radius:$r_small;
      @include aspect-ratio(900, 530);
      overflow: initial;

      @include mobile {
        @include aspect-ratio(260, 342);
      }

      > figure {
        background: transparent;
        border-radius:$r_small;

        figcaption {
          display: none;
        }
      }

      > figure,
      > .no-photo {
        @include absolute-stretch();
        width: 100%;
        height: 100%;
      }

      img, figure {
        @include addTransition();
        border-radius:$r_small;
      }
    }

    .meta-wrapper {
      position:absolute;
      top:30px;
      left:30px;
      .meta {
        background:none;
        color:$c_white;
        border:1px solid rgba($c_white, 0.30);
      }
    }


    .caption {
      bottom:30px;
      left:30px;


      > * {
        max-width:630px;
      }

      .surtitre {
        font-size:12px;
        line-height:24px;
        margin-bottom:3px;
      }

      .title-h3 {
          @include mobile {
            font-size:20px;
            line-height:23px;
          }
      }

      p {
        @include ellipsis(3, "" );
        font-size:14px;
        line-height:24px;
      }

      .location {
        font-size:12px;
        &:before  {
          top:4px;
        }
      }

      .btn-plus {
        position:absolute;
        right:-30px;
        bottom:-70px;
        visibility: hidden;
        opacity:0;
        transform:rotate(3deg);
        @include addTransition();
      }
    }
  }

  &.bloc-mise-en-avant-reversed {
    .th-slider {
      @include min-tablet_portrait {
        direction: rtl;
        & > *:first-child {
          margin-left: 40px !important;
        }
        .card-mise-en-avant {
          margin-right: 0;
          margin-left: 40px;
          direction: ltr;
        }
      }
    }
  }
}
