.meta-wrapper {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin: 0;

	.meta {
		@include ft($f_txt, $s_link_small, 400, $c_main, 20px);
		border: 1px solid $c_sub;
		background-color:$c_white;
		padding: 3px 10px;
		//max-height: 23px;
		text-transform:uppercase;
		display:flex;
		align-items:center;
		margin-bottom: 5px;
		border-radius:$r_small;

		&:not(:last-of-type) {
			margin-right: 8px;
		}

		&:before {
			display: none;
		}

		[class^="icon-"], [class*=" icon-"] {
			font-size: 14px;
			margin-right: 4px;
		}

		&.with-icon {
			[class^="icon-"], [class*=" icon-"] {
				& + span {
					margin-left: 5px;
				}
			}
		}

		&.iti-difficile {
			position: relative;
			padding-left: 22px;

			&:before {
				content: "";
				display: block;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background-color: $c_dark;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 10px;
			}
		}

		.hide-on-mobile {
			@include mobile {
				display: none;
			}
		}
	}
}