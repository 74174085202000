.strate-footer-newsletter {
  position: relative;

  .text-wrapper {
    margin-bottom: 33px;

    &:before {
      content: '';
      position: absolute;
      top: -40px;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $c_sub_light;
      z-index: -1;
    }
  }

  .news-wrapper {
    position: relative;

    &:before {
      top: 0;
      z-index: -1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 55px;
      left: 0;
      bottom: -40px;
      right: 0;
      background-color: $c_bg;
      z-index: -1;
      @include mobile {
        top: 40px;
      }
    }
  }

  form {
    position: relative;
    width: 100%;

    .frm_error {
      @include ft($f_txt, 12px, 400, $c_white, 18px);
      background: $c_error;
      position: relative;
      display: inline-block;
      margin: 0 55px;
      padding: 4px 10px;
      border-radius: 4px;
    }

    .frm_error_style {
      display: none;
    }

    .frm_hidden, label {
      display: none;
    }

    input {
      @include reset-appearance;
      @include ft($f_txt, 22px, 500, $c_txt, 24px);
      display: inline-block;
      width: 100%;
      padding: 35px 220px 35px 40px;
      white-space: nowrap;
      overflow: hidden;
      border-radius: $r_small;
      text-overflow: ellipsis;
      vertical-align: top;
      background: $c_white;
      box-shadow: 0 0 30px rgba(#8B3C4E, 0.30);
      @include addTransition();

      @include mobile {
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        padding: 27px 30px;
      }

      &::placeholder {
        color: $c_txt;
      }

      &:hover, &:focus {
        body:not(.no-hover) & {
          outline: none;
          box-shadow: 0 0 30px rgba($c_bg, 0.05);
        }
      }

      & + .frm_error {
        margin-top: 10px;
      }
    }

    /*Change text in autofill textbox*/
    input:-webkit-autofill {
      -webkit-text-fill-color: $c_txt;
    }

    /* Change the white to any color ;) */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 100px $c_white inset !important;
    }

    .frm_top_container {
      position: relative;
    }

    .frm_submit {
      position: absolute;
      top: 25px;
      right: 55px;
      @include mobile {
        position: relative;
        margin-top: 20px;
        right: 0;
        top: 0;
      }

      .frm_button_submit {
        @extend .btn;
        @include mobile {
          width: 100%;
          height: auto;
          text-align: center;
        }
      }
    }

    /* Checkbox */
    .frm_opt_container {
      position: relative;
      margin-top: 32px;

      .frm_primary_label {
        display: none;
      }

      input {
        display: none;

        &:checked {
          ~ span:before {
            background: $c_main;
          }

          ~ span:after {
            left: 22px;
            background: $c_white;
          }
        }
      }

      label {
        @include ft($f_txt, $s_link_small, 300, $c_white, 1.5);
        position: relative;
        display: block;
        padding: 0 0 10px 60px;
        text-align: left;
        user-select: none;
        @include addTransition();

        &:hover {
          body:not(.no-hover) & {
            cursor: pointer;
            color: $c_main;

            a {
              color: $c_main;
            }
          }
        }

        a {
          position: relative;
          display: inline-block;
          text-decoration: underline;
          @include ft("", "", 700, $c_white, "");
        }
      }

      span {
        &:before {
          content: '';
          position: absolute;
          top: 8px;
          left: 0;
          width: 40px;
          height: 20px;
          border-radius: 50px;
          cursor: pointer;
          background: $c_white;
          @include addTransition();
        }

        &:after {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          position: absolute;
          top: 12px;
          left: 4px;
          background: $c_main;
          @include addTransition();
        }
      }
    }

  }
}