// Overlay - Pop-in
// ==========================================================================
.overlay-popin {
  width: 100vw;
  max-width: none;
  background: transparent;
  display: flex !important;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;

  &.open {
    visibility: visible;
    opacity: 1;
    overflow: visible;
  }

  .inner-container {
    width: 100%;
    max-width: 970px;
    position: relative;
    height: auto;
    border-radius: 5px;
    background-color: $c_white;
 //   overflow: hidden;
//    -webkit-backface-visibility: hidden;
  //  -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    @media screen and (max-width: 800px) {
      width: calc(100% - 80px);
      margin: 0 40px;
    }
    @include tablet_portrait {
      max-width: 559px;
    }
    @include mobile {
      width: calc(100% - 40px);
      margin: 0 20px;
    }
  }

  .content {
    max-height: calc(100vh - 150px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    display: flex;
    @include tablet_portrait {
      flex-direction: column;
    }

    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

  }

  .col-left {
    flex-shrink: 0;
    @include mobile {
      display: none;
    }

    .image {
      height: 100%;
      width: auto;
      @include tablet_portrait {
        @include aspect-ratio(980, 300);
      }

      figure {
        height: 100%;
        @include tablet_portrait {
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }

  .col-right {
    padding: 50px 60px 60px 60px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
    @include tablet_portrait {
      padding: 40px;
    }
    @include mobile {
      padding: 60px 20px;
    }

    .btn {
      align-self: flex-start;
    }

    h2 {
      @include min-desktop {
        font-size:40px;
        line-height:40px;
      }
    }

    .form-container {
      margin-top: 30px;

      input[type="email"] {
        border-color: $c_sub;
      }

      .form-field {
        margin-bottom: 20px;
      }

      .form-field .frm_message, .frm_forms .frm_message p {
        color: $c_white;
      }

      .form-field .frm_error {
        text-align: left;

        &:before {
          display: none !important;
        }
      }

      .frm_forms .frm_error_style {
        color: $c_main;
      }

      .form-field .frm_primary_label {
        top: 25px;
      }

      .form-field .frm_opt_container .frm_checkbox label {
        font-size: 11px;
        line-height: 14px;

        a {
          color: $c_txt;
          text-decoration: underline;
          font-weight: 700;

          &:hover, &:focus {
            body:not(.no-hover) & {
              color: $c_main;
            }
          }
        }
      }

      button {
        width: 100%;
      }
    }
  }
}
