.social-links {
	display: flex;
	margin: 0;
	
	@include mobile {
		flex-wrap: wrap;
	}

	a {
		margin-right: 15px;
		cursor: alias;

		&:last-of-type {
			margin-right: 0;
		}

		&:before {
			@include icomoon();
			font-size: 15px;

			@include mobile {
				font-size: 12px;
			}
			@include mobile-small {
				font-size: 10px;
			}
		}

		&[data-icon="facebook"] {
			&:before {
				content: $icon-ico-facebook;
			}
		}

		&[data-icon="twitter"] {
			&:before {
				content: $icon-ico-twitter;
			}
		}

		&[data-icon="instagram"] {
			&:before {
				content: $icon-ico-instagram;
			}
		}

		&[data-icon="pinterest"] {
			&:before {
				content: $icon-ico-pinterest;
			}
		}

		&[data-icon="youtube"] {
			&:before {
				content: $icon-ico-youtube;
			}
		}

		&[data-icon="linkedin"] {
			&:before {
				content: $icon-ico-linkedin;
			}
		}

		&[data-icon="tripadvisor"] {
			&:before {
				content: $icon-ico-tripadvisor;
			}
		}

		@include can-hover{
			background-color: $c_main;

			&:not([disabled]) {
				cursor: alias;
			}

			&:before {
				color: $c_white;
			}
		}
	}

	&.--text-white {
		a {
			border:1px solid $c_white;
			color: $c_white;

			@include can-hover{
				background-color: $c_white;
				[class^="icon-"], [class*=" icon-"] {
					color: $c_main;
				}
			}
		}
	}
}