.bloc-guides {
	.wrapper:first-of-type {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.container-nav {
		margin-bottom:35px;
	}

	.nav-buttons {
		button {
			&:first-of-type {
				margin-right: 2px;
			}
		}

		@include mobile {
			display:none;
		}
	}

	.th-slider {

		@include mobile {
			flex-wrap:wrap;
			overflow: initial;
			margin-left: calc(var(--border-gutter) / 2 * -1);
			margin-right: calc(var(--border-gutter) / 2 * -1);
			margin-top: calc(var(--grid-gap) / 2 * -1);
			padding-right:0  !important;
			scroll-padding:0;
		}

		.slide {
			margin-right: $small-margin;
			padding-bottom: $small-margin;
		}
	}


	.slide {
		width: 208px;
		height: 208px;
		border-radius:$r_small;
		background-color: $c_bg;
		@include mobile {
			margin:calc(var(--grid-gap)/2) !important;
			width:calc(50% - calc(var(--grid-gap)));
		}
	}

	.container {
		position: relative;
		padding: 40px 45px;
		display: flex;
		flex-direction: column;
		margin-bottom: $small-margin;
		align-items: center;
		justify-content: center;
		@include addTransition();
		@include mobile {
			padding:30px 27px;
		}

		[class^="icon-"], [class*=" icon-"] {
			display: block;
			font-size: 72px;
			color: $c_main;;
			@include addTransition();

			@media (max-width: 1100px) {
				font-size: 60px;
			}

			& + .title {
				margin-top: 14px;
			}
		}

		.title {
			@include ft($f_hx, 20px, 700, $c_white, 26px);
			text-align: center;
			font-style:italic;
			@include addTransition();

			@media (max-width: 1100px) {
				font-size: $s_p;
				line-height: 1;
			}
		}

		&:hover, &:focus {
			body:not(.no-hover) & {
				@include rotate;
				background-color:$c_main;

				[class^="icon-"], [class*=" icon-"], .title {
					color: $c_white;
				}
			}
		}
	}

	// Fix for th-slider
	.wrapper:not(.wrapper-small) {
		.scroll-mode {
			& > *:last-child {
				padding-right: 27px;
			}
		}
	}
}