// ==============================================

//        MIXINS / FUNCTIONS INDEX

// ==============================================

@import "mixins/colors";
@import "mixins/layout";
@import "mixins/typography";
@import "mixins/quantity-queries";
@import "mixins/others";
@import "mixins/debug";


//  Mixins theme specifics
// -----------------------------------------------

// Card à l'Horizontale
// ==========================================================================
@mixin card-horizontale() {
	width: 100%;
	min-height: 140px;
	display: flex;
	border-radius: $r_small;
	align-items: center;
	max-height: 140px;
	@include addTransition();

	@include mobile {
		min-height: 140px;
		padding: 0;
	}

	&:hover, &:focus {
		body:not(.no-hover) & {
			@include rotate();
			background-color: $c_bg;

			.image {
				.no-photo {
					background-color: $c_main;
				}
			}

			.caption {
				.meta-wrapper {
					.meta {
						color: $c_txt;
						background-color: $c_white;
					}
				}

				.info {
					.info-wrapper {
						color: $c_txt;
						background-color: $c_white;
					}
				}

				.btn-plus {
					visibility: hidden;
					opacity: 0;
				}
			}
		}
	}

	.image {
		position: relative;
		width: 140px;
		height: 140px;
		margin-bottom: 0;
		flex-shrink: 0;
		border-radius: $r_small 0 0 $r_small;
		@include aspect-ratio(1, 1);

		/*@include mobile {
			width: 90px;
			height: 90px;
		} */

		figure, .no-photo {
			border-radius: $r_small 0 0 $r_small;
		}

		&:after {
			width: 100%;
			height: 100%;
		}

		.btn-favoris {
			padding: 20px;
		}
	}

	.caption {
		overflow: hidden;
		@include mobile {
			padding: 20px;
		}

		.btn-plus {
			visibility: hidden;
			opacity: 0;
		}

		.surtitre-card {
			@include ellipsis(2, 18px);
		}

		.title-card {
			font-size: 18px;
			line-height: 20px;
			@include ellipsis(2, 24px);
		}

		.meta-wrapper{
			@include mobile{
				display: none;
			}
		}

		.location{
			@include mobile{
				font-size: 12px;
				line-height: 18px;
				&:before{
					font-size: 12px;
					top: 2px;
				}
			}
		}
	}

	.date-event {
		.day, .month {
			font-size: 14px;
		}

		.hour {
			font-size: 16px;

			@include mobile {
				font-size: 14px;
			}
		}
	}
}


// On définit la taille de la gouttière
@mixin wrapper-columns($tailleGouttiere) {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	box-sizing: content-box;
	margin-left: auto;
	margin-right: auto;
	max-width: $wrapper-width+$tailleGouttiere !important; // 1120 + 40 (gouttière intérieure des blocs)

	> * {
		box-sizing: border-box;
	}


	// Pour les écrans supérieurs à 1600px on agrandit le contenu (1600px - les bords de l'écran border-gutter à gauche et à droite)
	&.wrapper-large {
		@include wide {
			max-width: 1600px + $tailleGouttiere - $border-gutter - $border-gutter !important;
		}
	}

	// BLOC SMALL -> Force a 980px par défaut sur écran normaux
	@media screen and (min-width: 1240px) {
		&.wrapper-small {
			max-width: $wrapper-small-width+$tailleGouttiere !important; // 980px + 30 (goutière intérieur des blocs)
		}
		&.wrapper-extra-small {
			max-width: $wrapper-extra-small-width+$tailleGouttiere !important;
		}
	}

	// Résolution jusqu'à 1280px, on met le contenu par défaut à 40 px des bords (FLUID)
	@media screen and (max-width: $wrapper-width+$border-gutter+$border-gutter) {
		margin-left: ($border-gutter - ($tailleGouttiere / 2)) !important;
		margin-right: ($border-gutter - ($tailleGouttiere / 2)) !important;
	}

	// BLOC SMALL -> Doit rester centré tant qu'il est plus petit que le contenu normal, donc : margin:auto;
	// Une fois à la taille normale, il reprend le comportement d'un bloc normal (min-width IMPORTANT)
	@media screen and (max-width: $wrapper-width+$border-gutter+$border-gutter) and (min-width: $wrapper-small-width+$border-gutter+$border-gutter+$tailleGouttiere) {
		&.wrapper-small {
			max-width: $wrapper-small-width+$tailleGouttiere !important;
			margin-left: auto !important;
			margin-right: auto !important;
		}
	}

	@media screen and (max-width: $wrapper-width+$border-gutter+$border-gutter) and (min-width: $wrapper-extra-small-width+$border-gutter+$border-gutter+$tailleGouttiere) {
		&.wrapper-extra-small {
			max-width: $wrapper-extra-small-width+$tailleGouttiere !important;
			margin-left: auto !important;
			margin-right: auto !important;
		}
	}

	// Breakpoint mobile, on met les marge de l'écran pour tout

	@include tablet-landscape {
		margin-left: 40px - ($tailleGouttiere/2) !important;
		margin-right: 40px - ($tailleGouttiere/2) !important;
	}
	@include mobile {
		margin-left: 20px - ($tailleGouttiere/2) !important;
		margin-right: 20px - ($tailleGouttiere/2) !important;
	}

	&:after {
		content: '';
		display: block;
		clear: both;
	}

	> * {
		padding-left: $tailleGouttiere/2 !important;
		padding-right: $tailleGouttiere/2 !important;
	}

}


@mixin shadow() {
	box-shadow: 0 10px 20px rgba(#EBE4DA, 0.30);
}

@mixin rotate() {
	@include addTransition();
	transform: rotate(-3deg);
}


.pattern-pointilles {
	margin-bottom: 30px;
	position: relative;

	&:after {
		content: "";
		width: 100%;
		background-image: url("../images/deco/trait.svg");
		background-repeat: repeat-x;
		left: 0;
		height: 10px;
		background-position-x: center;
		margin-top: -38px;
		position: absolute;
		top: 0;
	}
}


%motif-tour {
	position: relative;

	&:after {
		content: "";
		width: 753px;
		background-color: $c_sub;
		mask-image: url("../images/deco/motif-tour.svg");
		mask-size: 101% 100%;
		background-repeat: no-repeat;
		bottom: 0;
		transform: translate(50%, 50%);
		height: 760px;
		position: absolute;
		z-index: -1;
		right: 0;

		@include tablet-small {
			mask-size: 50% 50%;
			transform: translate(75%, 75%);
		}
	}
}

%motif-cover {
	position: relative;

	&:after {
		content: "";
		width: 307px;
		background-color: $c_sub;
		mask-image: url("../images/deco/motif-cover.png");
		mask-size: 101% 100%;
		background-repeat: no-repeat;
		right: 0;
		height: 350px;
		position: absolute;
		top: 0;

		@include tablet-portrait {
			width: 200px;
			height: 228px;
		}

		@include tablet-small {
			width: 100px;
			height: 114px;
		}

		@include mobile {
			width: 90px;
			height: 102px;
		}
	}
}
