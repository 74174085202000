// Styles communes aux images + styles pour la classe image qui utilisé comme container pour les images afin de bien positionner les crédits en position absolute
// ==========================================================================

// Images
// ==========================================================================

.no-photo {
	height: 100%;
	background: $c_bg;
	border-radius:$r_small;
}

figure {
	background: $c_bg;
}

.img-wrapper {
	position: relative;
	display: block;
}

img {
	&.lazy {
		opacity: 0 !important;
		transition: opacity 0.3s ease;

		&.loaded {
			opacity: 1 !important;
		}
	}
}


// Opacity
// ==========================================================================
.o10 img {
	opacity: 0.1;

	&.lazy {
		opacity: 0 !important;

		&.loaded, &.loading {
			opacity: 0.1 !important;
		}
	}
}

.o20 img {
	opacity: 0.2;

	&.lazy {
		opacity: 0 !important;

		&.loaded, &.loading {
			opacity: 0.2 !important;
		}
	}
}

.o25 img {
	opacity: 0.25;

	&.lazy {
		opacity: 0 !important;

		&.loaded, &.loading {
			opacity: 0.25 !important;
		}
	}
}

.o30 img {
	opacity: 0.3;

	&.lazy {
		opacity: 0 !important;

		&.loaded, &.loading {
			opacity: 0.3 !important;
		}
	}
}

.o40 img {
	opacity: 0.4;

	&.lazy {
		opacity: 0 !important;

		&.loaded, &.loading {
			opacity: 0.4 !important;
		}
	}
}

.o50 img {
	opacity: 0.5;

	&.lazy {
		opacity: 0 !important;

		&.loaded, &.loading {
			opacity: 0.5 !important;
		}
	}
}

.o60 img {
	opacity: 0.6;

	&.lazy {
		opacity: 0 !important;

		&.loaded, &.loading {
			opacity: 0.6 !important;
		}
	}
}

.o70 img {
	opacity: 0.7;

	&.lazy {
		opacity: 0 !important;

		&.loaded, &.loading {
			opacity: 0.7 !important;
		}
	}
}

.o75 img {
	opacity: 0.75;

	&.lazy {
		opacity: 0 !important;

		&.loaded, &.loading {
			opacity: 0.75 !important;
		}
	}
}

.o80 img {
	opacity: 0.8;

	&.lazy {
		opacity: 0 !important;

		&.loaded, &.loading {
			opacity: 0.8 !important;
		}
	}
}

.o90 img {
	opacity: 0.9;

	&.lazy {
		opacity: 0 !important;

		&.loaded {
			opacity: 0.9 !important;
		}
	}
}

.o10, .o20, .o30, .o40, .o50, .o60, .o70, .o80, .o90 {
	img {
		&.lazy {
			visibility: hidden !important;
			transition: none;

			&.loaded, &.loading {
				visibility: visible !important;
			}
		}
	}
}


.o10, .o20, .o30, .o40, .o50, .o60, .o70, .o75, .o80, .o90 {
    .img-wrapper {
        background-color: $c_bg;
    }
}

// Ratios
// ==========================================================================
.ratio-16-9 {
	@include aspect-ratio(16, 9);
}

.ratio-16-10 {
	@include aspect-ratio(16, 10);
}

.ratio-4-3 {
	@include aspect-ratio(4, 3);
}

.ratio-16-9 {
	> img {
		@include absolute-stretch();
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

// Styles pour le figcaption et les crédits
figcaption {
	@include ft($f_txt, 12px, 400, rgba($c_txt, 0.50), 24px);
	margin-top: 10px;
}

.credits {
	@include ft($f_txt, 12px, 300, $c_white, 18px);
	position: absolute;
	left: 10px;
	top: 10px;
	z-index: z-index('credits-image');
	width: auto !important;
	height: 30px !important;
	border-radius: 30px;
	max-width: 0;
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	padding: 10px 25px 11px 5px;
	font-size: 0;
	// letter-spacing: -5px;
	@include addTransition();


	&:before {
		@include icomoon($icon-ico-credits);
		display: inline-block;
		vertical-align: middle;
		font-size: 12px;
		position: absolute;
		right: 8px;
		top: 50%;
		transform: translateY(-50%);
		@include addTransition();

	}

	&:after {
		content: '';
		background: $c_dark;
		z-index: -1;
		width: 0;
		transform-origin: left;
		@include addTransition();
	}

	&:hover, &:focus {
		letter-spacing: 0px;
		cursor: default;
		font-size: 12px;
		padding: 6px 35px 11px 10px;
		max-width: calc(100% - 30px);

		&:after {
			width: 100%;
		}

		&:before {
			opacity: 0;
			visibility: hidden;
		}
	}
}

// Container utilisé dans les blocs avec des colonnes pour contenir l'image et avoir le position relative pour bien positionner les crédits
.image {
	position: relative;
	display: block;
}
