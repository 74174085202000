.single-sit {
	.text-styles {
		p {
			font-size: 14px;
		}

		.title-h3 {
			margin-bottom: 20px;
		}

	}

	/* Styles spéciales pour la couverture */

	/* Début des règles spéciales pour améliorer l'affichage de la barre dockée */

	> .content {
		position: relative;
		margin-top: 41px;
		@include tablet-landscape {
			top: auto;
		}
	}


	/* Fin des règles spéciales */

	/* Styles pour la gestion du view more / view less au niveau du SIT */

	.view-more-active {
		.text {
			position: relative;
			max-height: 120px;
			overflow-y: hidden;
			@include addTransition();

			&:before {
				content: '';
				display: block;
				width: 100%;
				height: 60px;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				background: linear-gradient(to top, rgba(255, 255, 255, 0.99) 0%, rgba(255, 255, 255, 0.79) 20%, rgba(255, 255, 255, 0.59) 40%, rgba(255, 255, 255, 0.39) 60%, rgba(255, 255, 255, 0.19) 80%, rgba(255, 255, 255, 0.01) 100%);
				z-index: 1;
				opacity: 1;
			}

			&.toggle-height {
				max-height: none;

				&:before {
					opacity: 0;
				}

				& + .btn-view-more {
					&:after {
						transform: translate(-50%, -50%) rotate(180deg);
					}
				}
			}
		}

		.btn-view-more {
			display: block;
		}
	}

	/* Styles générales à tous les types de bloc sit */
	.bloc + .bloc {
		margin-top: 80px;
	}

	.meta-wrapper {
		margin: 0;

		.meta {
			color: $c_txt;
			font-weight: bold;
			border: 1px solid rgba($c_txt, 0.30);
		}
	}

	.small-title {
		display: block;
		font-size: 20px;
		line-height: 1;
		font-weight: 700;

		& + span {
			display: block;
			margin-top: 10px;
		}
	}

	.texte-supplementaire {
		font-style: italic;
	}

	.elements-separes {
		li {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&:after {
				content: "";
				display: block;
				width: 100%;
				height: 1px;
				background-color: rgba($c_main, 0.30);
				position: absolute;
				top: -20px;
				left: 0;
			}

			&:nth-of-type(1), &:nth-of-type(2) {
				&:after {
					display: none;

					@include mobile {
						display: block;
					}
				}
			}

			&:nth-of-type(1) {
				&:after {
					@include mobile {
						display: none;
					}
				}
			}
		}

		.content-container {
			span {
				display: block;
			}
		}
	}

	/*********
	**** Styles pour les différents parties SIT dans la page
	**********/

	/* Sit galerie */
	.sit-galerie {
		li {
			&:not(:last-of-type) {
				margin-right: 20px;
			}
		}

		.th-slider {
			li {
				@include tablet-portrait {
					max-width: calc(100% - 40px);
				}
				@include mobile {
					max-width: calc(100% - 20px);
				}
			}
		}

		.nav-buttons {

		}
	}

	/* Sit à propos */

	.sit-a-propos {

		.title {
			& + p:first-of-type {
				margin-top: 40px;
			}

			& + .liste-a-propos {
				margin-top: 0;
			}

			& + .meta-wrapper {
				@include mobile {
					margin-top: 0;
				}
			}
		}

		.meta-wrapper {
			//   margin: 40px 0 20px 0;
		}

		p {
			& + .liste-a-propos {
				margin-top: 0;
			}
		}

		.liste-a-propos {
			margin: 0;
			display: flex;
			flex-wrap: wrap;
			margin-top: -40px;

			> * {
				display: flex;
				align-items: baseline;

				[class^="icon-"], [class*=" icon-"] {
					flex-shrink: 0;
					margin-right: 10px;
					font-size: 20px;
				}

				p {
					margin: 0;
				}
			}
		}
	}


	/** sit label **/
	.sit-labels {

		.title-h3 {
			margin-top: 0;
		}

		.sit-labels-header {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.list-labels {
			.label {
				margin-right: 50px;
				@include mobile {
					margin-right: 30px;
				}
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				.content {
					display: flex;
					flex-direction: column;

					a {
						text-decoration: none;
					}
				}

				.title {
					font-family: $f_hx;
					font-size: 18px;
					font-style: italic;
				}

				> figure {
					margin-right: $small-margin;
					margin-top: 20px;
					background: none;

					@include mobile {
						width: 90px;
					}
				}

			}
		}
	}

	/* Sit info pratiques */
	.sit-info-pratiques {
		.title {
			& + ul {
				margin-top: 60px;

			}

			.small-title {
				& + .elements-separes {
					@include mobile {
						margin-top: 40px;
					}
				}
			}
		}

		ul {
			& + .small-title {
				margin-top: 40px;
			}

			&:not(:first-of-type) {
				margin-top: 0;
			}
		}

		.small-title {
			@include mobile {
				margin-bottom: 40px;
			}
		}

		.js-view-more-tag {
			[data-view-more-tag] {
				display: none;
			}

			.meta-more {
				border: solid 0.1rem orange;
				background-color: $c_white;
				cursor: pointer;
			}
		}

	}

	/* Sit a propos - info pratiques (règles communes aux deux afin d'éviter la répétition du css) */
	.sit-a-propos, .sit-info-pratiques, .sit-tarifs {
		ul:not(.meta-wrapper) {
			display: flex;
			flex-wrap: wrap;
			margin: 0;
			width: 100%;

			> * {
				width: calc(50% - 20px);
				padding: 0;
				font-size: 14px;
				line-height: 1.42;
				margin-top: 40px;
				flex-shrink: 0;
				align-self: flex-start;

				@include mobile {
					width: 100%;
				}

				&:first-of-type {
					@include mobile {
						margin-top: 0
					}
				}

				&:nth-of-type(even) {
					margin-left: 20px;

					@include mobile {
						margin-left: 0px;
					}
				}

				&:nth-of-type(odd) {
					margin-right: 20px;

					@include mobile {
						margin-right: 0px;
					}
				}

				&:before {
					display: none;
				}

				[class^="icon-"], [class*=" icon-"] {
					position: relative;
					top: 3px;
				}
			}
		}
	}

	/* Sit tarifs */

	.sit-tarifs {
		.elements-separes {
			flex-direction: column;

			li {
				width: 100%;
				max-width: none;
				margin-right: 0 !important;

				&:nth-of-type(even) {
					margin-left: 0;
				}

				&:nth-of-type(2) {
					&:after {
						display: block;
					}
				}
			}
		}

		.title {
			& + .meta-wrapper {
				margin-top: 50px;
			}
		}

		.title-h3 {
			margin-bottom: 0;
		}

		.meta-wrapper {
			margin-bottom: 10px;

			[class^="icon-"], [class*=" icon-"] {
				color: $c_main;
				margin-right: 10px;
				font-size: 16px;
			}

			.meta {
				border: none;
				margin-bottom: 0;
				padding: 0;
				text-transform: none;
				@include ft($f_txt, $s_p, 400, "", 26px);
			}
		}

		.container {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			margin-top: 40px;

			> .col {
				width: calc(50% - 20px);

				@include mobile {
					width: 100%;
				}

				&:nth-of-type(odd) {
					margin-right: 20px;

					@include mobile {
						margin-right: 0;
					}
				}

				&:nth-of-type(even) {
					margin-left: 20px;

					@include mobile {
						margin-left: 0;
					}
				}

				.single-meta {
					display: inline-block;
					border: 1px solid $c_sub;
					padding: 7px 10px;
					font-size: 12px;
					font-weight: 700;

					& + .text {
						margin-top: 15px;
					}
				}

				.title-section {
					@include ft($f_txt, $s_medium, 300, $c_txt, 1);
					text-transform: uppercase;

					& + * {
						margin-top: 30px;
					}
				}

				& + .texte-supplementaire {
					margin-top: 40px;
				}
			}
		}
	}

	/* Sit horaires */

	.sit-horaires {
		position: relative;

		&.th-slider-wrapper {
			@include min-mobile {
				padding-left: 40px;
				padding-right: 40px;
				box-sizing: content-box;
			}
		}

		.title {
			@include min-mobile {
				margin-top: 0;
				margin-bottom: 20px;
			}
		}

		.btn-primary {
			position: absolute;
			right: 0;
			top: 40px;
			margin-top: 0;

			@include mobile {
				position: static;
				margin-top: 0;
			}
		}

		.container {
			overflow: hidden;
		}

		.nav-buttons {
			width: calc(100% + 80px);
			position: relative;
			top: 27px;
			left: -40px;
			display: flex;
			justify-content: space-between;

			button {
				width: 30px;
				height: 30px;
				border: none;

				&:before {
					font-size: 16px;
				}

				&:hover, &:focus {
					body:not(.no-hover) & {
						color: $c_greydark;
					}
				}

			}
		}

		.th-slider {
			.slide-horaires {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;
				margin-right: 20px;

				.col {
					flex-grow: 1;

					@include mobile {
						margin-top: 40px;

						&:first-of-type {
							margin-top: 0;
						}
					}

					.date {
						display: block;
						margin-bottom: 70px;
						@include ft($f_hx, $s_medium, 700, $c_txt, 22px);
						font-style: italic;
					}

					ul {
						margin: 0;
						margin-top: -40px;

						li {
							position: relative;
							padding: 0;
							display: flex;
							align-items: center;
							justify-content: space-between;
							margin-top: 40px;
							max-width: none;

							&:after {
								content: "";
								display: block;
								width: 100%;
								height: 1px;
								background-color: rgba($c_main, 0.30);
								position: absolute;
								bottom: -20px;
								left: 0;
							}

							&:last-of-type {
								&:after {
									display: none;
								}
							}

							&:before {
								display: none;
							}
						}
					}

					& + .col {
						@include min-mobile {
							margin-left: 40px;
						}
					}
				}
			}

			// Slider en 2 colonnes si plusieurs périodes d'horaires
			.slide.slide-horaires {
				margin-right: 40px;

				@include min-tablet-small {
					width: calc(50% - 20px);
				}
			}

			& + .texte-supplementaire {
				margin-top: 40px;
			}
		}
	}

	/* Sit documentation */

	.sit-documentation {
		.sit-documentation-grid {
			display: flex;
			flex-wrap: wrap;
		}

		.doc-info {
			position: relative;
			display: flex;
			margin-top: $grid-gap-small;
			justify-content: center;
			flex-direction: column;
			padding: 20px 80px 20px 30px;
			width: calc(50% - 20px);
			min-height: 90px;
			border-radius: $r_small;
			text-decoration: none !important;
			border: 1px solid $c_sub;
			@include addTransition();

			@include mobile {
				width: 100%;
				padding: 20px 60px 20px 20px;
			}

			&:after {
				@include icomoon($icon-ico-telechargement);
				font-size: 18px;
				color: $c_main;
				display: block;
				position: absolute;
				right: 40px;
				@include addTransition();

				@include mobile {
					right: 20px;
				}
			}

			&:nth-child(2n) {
				@include min-mobile {
					margin-left: 40px;
				}
			}

			.doc-type-weight {
				@include ft($f_hx, $s_medium, 700, $c_txt, 22px);
				@include addTransition();

				@include mobile {
					font-size: 15px;
					line-height: 18px;

					& + .title {
						margin-top: 5px;
					}
				}

				.uppercase {
					text-transform: uppercase;
				}
			}

			.title {
				@include ft($f_txt, $s_p, 400, $c_txt, 26px);

				@include mobile {
					font-size: 12px;
					line-height: 18px;
				}
			}

			@include can-hover {
				background-color: $c_bg;
				border: 1px solid $c_bg;

				&:after {
					color: $c_white;
				}

				.doc-type-weight,
				.title {
					color: $c_white;
				}
			}

		}
	}

	/* Sit contact */

	.sit-contact {
		.title {
			@include min-mobile {
				margin-bottom: 40px;
			}
		}

		.container {
			display: flex;
			flex-direction: column;

			.col {
				flex-grow: 1;
				flex-basis: 0;

				@include mobile {
					width: 100%;
				}

				.contact-name, .adresse, .location {
					display: flex;
					padding-left: 30px;
					font-size: 16px;
					line-height: 1.42;
				}

				.contact-name {
					position: relative;
					align-items: center;
					padding-top: 20px;

					@include mobile {
						padding-top: 0;
					}

					&:before {
						@include icomoon($icon-ico-person);
						display: block;
						color: $c_main;
						font-size: 14px;
						margin-right: 10px;
						position: absolute;
						left: 0;
					}

					& + .adresse {
						@include min-mobile {
							margin-top: 40px;
						}
						@include mobile {
							margin-top: 20px;
						}
					}
				}

				.location {

					@include ft($f_hx, 16px, 400, $c_txt, 24px);
					position: relative;
					padding-left: 19px;
					margin-bottom: 20px;

					p, strong {
						font-size: 16px;
					}

					&:before {
						@include icomoon($icon-ico-location);
						font-size: 16px;
						color: $c_txt;
						position: absolute;
						top: 6px;
						left: 0;
					}
				}

				.adresse {
					position: relative;
					align-items: center;

					&:before {
						@include icomoon($icon-ico-boussole);
						display: block;
						color: $c_main;
						font-size: 14px;
						margin-right: 10px;
						position: absolute;
						left: 0;
						top: 4px;
					}
				}

				& + .col {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					margin-top: 32px;
					//flex-direction: column;

					@include mobile {
						margin-top: 20px;
					}

					> a {
						margin-top: 10px;
						margin-right: 20px;
						margin-bottom: 0;
					}

					.social-links {
						margin-bottom: 0;

						a {
							margin-top: 10px;

							&:not(:last-child) {
								margin-right: 20px;
							}
						}

					}
				}

				.geolocalisation {
					position: relative;
					display: flex;
					align-items: center;

					&:before {
						align-items: center;
						@include icomoon($icon-ico-navigation);
						display: block;
						color: $c_main;
						font-size: 14px;
						margin-right: 10px;
						position: absolute;
						left: 0;
					}

					> span {
						padding-left: 30px;
						display: flex;

						@include mobile {
							flex-direction: column;
						}

						> strong:nth-child(2n) {
							padding-left: 30px;

							@include mobile {
								padding-left: 0;
							}
						}

					}
				}
			}
		}
	}

	/* Sit carte */

	.sit-carte {
		.maps {
			position: relative;
			height: 500px;
			z-index: 1;
		}
	}


	/* bloc relation */
	.sit-relation {
		.container-nav {
			margin-top: 30px;
		}

		> .container-nav {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			@include mobile {
				flex-direction: column;
				justify-content: flex-start;
			}

			.btn, .btn-primary, .btn {
				margin-left: auto;
				@include mobile {
					margin-left: 0;
					margin-right: auto;
				}
			}
		}

		.nav-dots {
			@include min-mobile {
				display: none;
			}
		}

		.card {
			width: 320px;
			margin-right: $small-margin;
			@include mobile {
				width: 280px;
				flex-direction: column;
			}

			@include mobile-small {
				width: calc(100% - 20px);
			}

			.image {
				width: 100%;
				height: auto;
			}

			.caption {
				@include mobile {
					width: 100%;
					margin-top: 10px;
				}
			}
		}
	}
}