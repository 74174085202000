.bloc-focus {
	// Style pour le container avec seulement les informations mise en avant
	.container-focus {
		position: relative;
		padding: 0 0 0 100px;

		@include min-tablet-landscape {
		//	max-width: $wrapper-small-width + ($grid-gap * 2);
		//	margin-left: -$grid-gap;
		//	margin-right: -$grid-gap;
		}

		@media (max-width: 1140px) {
			padding-left: 60px;
		}

		@include mobile {
			padding-left: 20px;
			padding-right: 20px;
			text-align: center;
		}

		[class^="icon-"], [class*=" icon-"] {
			font-size: 72px;
			color: $c_main;
			position: absolute;
			top: 0;
			left: 0;

			@media (max-width: 1140px) {
				position: static;
				font-size: 65px;
				left: 0;
				top: 0;
			}
			@include mobile {
				top: 20px;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		.surtitre {
			@include mobile {
				margin-top:15px;
			}
		}
	}
}