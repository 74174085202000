// Styles générales à tous les types de bloc colonnes : à deux colonnes, trois et même la version 1/3 + 2/3 ou 2/3 + 1/3
.bloc-colonne {
    align-items: start;
    
    
    // Homogénisation des marges si 2 blocs colonnes se suivent
    & + .bloc-colonne {
        margin-top: var(--grid-gap);
    }
    
    // Force le passage en 2 colonnes en tablette et 1 colonne en mobile
    [class*='col-'] {
        @include tablet-small {
            grid-column: span 6;
        }
        @include mobile {
            grid-column: span 12;
        }
    }

    // **** Styles communs
    .item-colonne {
        .image {
            display: block;
            position: relative;
            width: 100%;

            figure {
                background: transparent;
                border-radius:$r_small;

                img {
                    width: 100%;
                    height: auto;
                    border-radius:$r_small;
                }

            }
        }
    }


    // **** Version avec image en format portrait
    .item-colonne.image-portrait {

    }

    // **** Version avec image en format portrait et caption par dessus
    .item-colonne.image-portrait.has-caption {
        // gradient sur le caption wrapper
        .caption-wrapper {
            @include aspect-ratio(1, 1);
            @include aspect-ratio-pct(var(--aspect-ratio));     // aspect-ratio variable selon nombre de colonnes
            position: absolute;     // Surcharge de la position relative définie par défaut dans le mixin d'aspect-ratio
            width: 100%;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                display: block;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
                pointer-events: none;
            }
        }


        .caption {
            --caption-spacing : 30px;
            position: absolute;
            bottom: var(--caption-spacing);
            left: var(--caption-spacing);
            right: var(--caption-spacing);
            z-index: 2;
            display: flex;
            flex-direction: column;

            @include mobile {
                --caption-spacing : 20px;
            }

            .btn, .btn-primary, .btn {
                align-self: flex-start;
            }

            .title-h3 {
                color: $c_white;
            }
        }


        // Gestion du no-photo quand on n'a pas d'image
        .no-photo {
            @include aspect-ratio(1, 1);
            @include aspect-ratio-pct(var(--aspect-ratio));
        }


        // Gestion des aspect-ratios pour les caption et no-photo
        // Colonne de 6/12 -> largeur 1/2
        &.col-6 {
            --aspect-ratio: 510/430;

            .caption {
                --caption-spacing : 40px;

                @include tablet-portrait {
                    --caption-spacing : 30px;
                }
            }
        }

        // Colonne de 4/12 -> largeur 1/3
        &.col-4 {
            --aspect-ratio: 404/273;
        }

        // Colonne de 8/12 -> largeur 2/3
        &.col-8 {
            --aspect-ratio: 404/587;
        }

        // En mobile on passe toutes les images en carrés
        &.col-6,
        &.col-4,
        &.col-8 {
            @media screen and (max-width: 420px) {
                --aspect-ratio: 1/1;
            }
        }
    }


    // **** Version avec image en format paysage et wysi en-dessous
    // Le texte est avant l'image dans le DOM -> inversion en flex
    .item-colonne.image-landscape {
        display: flex;
        flex-direction: column-reverse;

        .text-wrapper + .image {
            margin-bottom: 20px;
        }
    }




    // **** Exemple si on veut gérer une décoration en nth-child

    // Highlight du 1er et dernier de chaque rangée
    &[class*="--grid_2_col"] {
        @include min-mobile {
            .item-colonne:nth-child(odd),
            .item-colonne:nth-child(even) {
                .img-wrapper {
                    outline: 1px dashed var(--theme-color);
                    outline-offset: 8px;

                    &:after {
                        position: absolute;
                        top: 0;
                        display: block;
                        padding: 2px 4px;
                        font-size: 12px;
                        color: $c_white;
                        background-color: var(--theme-color);
                    }
                }
            }
        }
    }


    // Highlight du 1er et dernier de chaque rangée
    &[class*="--grid_3_col"] {
        @include min-mobile {
            .item-colonne:nth-child(odd),
            .item-colonne:nth-child(even) {
                .img-wrapper {
                    outline: 1px dashed var(--theme-color);
                    outline-offset: 8px;

                    &:after {
                        position: absolute;
                        top: 0;
                        display: block;
                        padding: 2px 4px;
                        font-size: 12px;
                        color: $c_white;
                        background-color: var(--theme-color);
                    }
                }
            }
        }

        @include min-tablet-small {
            .item-colonne:nth-child(odd),
            .item-colonne:nth-child(even) {
                .img-wrapper {
                    outline: none;      // reset

                    &:after {
                        display: none;
                    }
                }
            }

            .item-colonne:nth-child(3n+1),
            .item-colonne:nth-child(3n+3) {
                .img-wrapper {
                    outline: 1px dashed var(--theme-color);
                    outline-offset: 8px;

                    &:after {
                        position: absolute;
                        top: 0;
                        display: block;
                        padding: 2px 4px;
                        font-size: 12px;
                        color: $c_white;
                        background-color: var(--theme-color);
                    }
                }
            }
        }
    }
}
