.barre-listing-sit {
  .right-side {
    margin-left: auto;
    margin-right: 0;
    display: flex;
    align-items: center;
    @include tablet-portrait {
      order: 1;
      margin-left: 0;
    }

    button {
      margin-left: 10px;

      &:first-of-type {
        margin-left: 0;
      }
    }

    @include tablet-small {
      position: absolute;
      justify-content: space-between;
      padding: 0 40px;
      width: 100%;
      left: 0;
      right: 0;
    }

    @include mobile {
      padding: 0 20px;
    }
  }

  .container {
    padding: 22px 40px 22px 80px;

    @include tablet-landscape {
      padding: 18px 40px;
    }

    @include mobile {
      padding: 15px 20px;
    }

    button {
      height: 42px;
      @include addTransition();

      @include tablet-portrait {
        min-width: auto;
        width: 40px;
        height: 40px;
        padding: 0;
      } 

      @include mobile {
        width: 35px;
        height: 35px;
      }

      span:not(.counter):not(.filter-counter) {
        @include tablet-portrait {
          display: none;
        }
      }

      &[data-icon] {
        position: relative;
        flex-shrink: 0;

        &:before {
          content: "";
          display: block;
          @include icomoon();
          font-size: 20px;
          margin-right: 10px;
          position: relative;
          top: -1px;
          @include addTransition();

          @include tablet-portrait {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            margin-right: 0;
          }
        }
      }

      &[data-icon="location"] {
        &:before {
          content: $icon-ico-boussole;
        }
      }

      &[data-icon="filtres"] {
        position: relative;

        &:before {
          font-size: 15px;
          content: $icon-ico-filtres;
        }
      }
    }


    .resultats {
      @include ft($f_txt, $s_p, 400, $c_white, 1.5);

      @include tablet-portrait {
        order: 2;
        margin-left: auto;
        margin-right: auto;
        font-size: 13px;
        line-height: 14px;
      }

      @include mobile {
        font-size: 12px;
      }
    }

    .social-share {
      @include tablet-portrait {
        display: none;
      }
    }
  }

  //&.barre-listing-sit-agenda {
  /*	@include tablet-portrait {
          margin: 0;
      } */

  .date-picker-wrapper {
    top: -490% !important;
    transform: scale(0.90);
    left: 200px !important;

    .month-wrapper {
      display: flex;

      table {
        width: calc(20%);
        float: left;
      }

      .gap {
        visibility: hidden !important;
      }
    }
  }

  .facettes-wrapper {
    margin-right: 20px;
    @include tablet-small {
      display: none;
    }
  }

  .wrapper-date {
    @include ft("", "", "", $c_white, "");
    width: auto;
    border: 1px solid $c_white !important;
    text-decoration: none;
    max-width: 200px;
    min-width: 150px;
    max-height: 42px;
    text-align: center;

    @media (max-width: 1130px) {
      padding: 5px;
    }

    &:before {
      content: none;
    }

    input {
      @include ft("", "", "", $c_white, "");
      width: 135px;
      padding: 10px 3px 10px 3px;

      &::-webkit-inner-spin-button,
      &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }
    }

  }

  //}

  &.--listing-with-carte {
    .btn-carte {
      @include min-tablet-portrait {
        display: none;
      }
    }
  }
}