// ==============================================

//        CLASSES UTILITAIRES

// ==============================================


//  Typography
// -----------------------------------------------

// Alignement horizontal / vertical
.u-text-left {    text-align: left;   }
.u-text-right {   text-align: right;    }
.u-text-center {  text-align: center;   }

.u-align-top {        vertical-align: top;    }
.u-align-bottom {     vertical-align: bottom;   }
.u-align-middle {     vertical-align: middle;   }
.u-align-baseline {   vertical-align: baseline; }

// Casse
.u-lowercase {      text-transform: unset;    }
.u-uppercase {      text-transform: uppercase;    }
.u-capitalize {     text-transform: capitalize;   }


.u-truncate {
  @include ellipsis();
}

.u-break-word {
  word-wrap: break-word;
}

.u-nowrap {
  white-space: nowrap;
}

.u-color-primary {
  color: $c_main !important;
}

.u-color-secondary {
  color: $c_sub !important;
}



//  Others
// -----------------------------------------------

// Suppression de la scrollbar d'un élément
.u-no-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */

  &::-webkit-scrollbar {
    display: none;
  }
}

// Désactive le scroll (ex: sur le body quand un overlay est ouvert)
.u-no-scroll {
  overflow: hidden;
}

// Masqué graphiquement mais accessible pour les lecteurs d'écran
.u-visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}



//  Images
// -----------------------------------------------

.fit-cover {
	width: 100%;

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}



//  Génération de classes responsive pour masquer un élément
// -----------------------------------------------

// Élément masqué jusqu'à un breakpoint : hide-until@mobile...
@each $breakpoint-name in map-keys($breakpoints) {
    @include bp-down($breakpoint-name) {
        $prefix: get-breakpoint-prefix($breakpoint-name);
        .hide-until\@#{$prefix} {
            display: none !important;
        }
    }
}

// Élément masqué à partir d'un breakpoint : hide-from@mobile...
@each $breakpoint-name in map-keys($breakpoints) {
    @include bp-up($breakpoint-name) {
        $prefix: get-breakpoint-prefix($breakpoint-name);
        .hide-from\@#{$prefix} {
            display: none !important;
        }
    }
}

// Classe utilitaire utilisé surtout en js pour forcer un élément à ne pas être visible à cause de soucis d'affichage en ajax par exemple
.hide {
    display: none !important;
}
