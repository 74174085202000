// Styles pour l'affichage des cards en format horizontale
// ==========================================================================
.cards-horizontales {
	.card {
		@include card-horizontale();

		.image {
			.info-desktop {
				bottom: auto;
				top: 10px;
				display: none;
			}

			.info-mobile {
				display: block;
			}
		}

	/*	.caption {
			.meta-wrapper {
				display: none;
			}
		} */
	}
}

// Card - Default Styles
// ==========================================================================
.card {
	position: relative;
	display: block;
	width: 100%;
	margin-bottom: 20px;
	background-color:$c_bg;
	border-radius:$r_small;
	@include addTransition();

	@include mobile {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}

	&:last-of-type {
		@include mobile {
			margin-bottom: 0;
		}
	}

	&:hover, &:focus {
		body:not(.no-hover) & {
			@include rotate();
			background-color:$c_main;
			color:$c_white;

			.image {
				.no-photo {
					background-color:$c_bg;
					@include addTransition();
				}
			}
			.caption {
				.title-card {
					color:$c_white;
				}

				.meta-wrapper {
					justify-content: flex-start;
				}

				.info {
					.info-wrapper {
						background-color:$c_main;
					}
				}

				.btn-plus {
					visibility: visible;
					opacity:1;
					@include addTransition();
				}
			}
		}
	}

	.image {
		position: relative;
		border-radius:$r_small $r_small 0 0;
		flex-shrink: 0;
		@include aspect-ratio(320, 190);
		@include addTransition();

		@include mobile {
			width: 280px;
			height: 190px;
			margin-bottom: 0;
		}

		.credits {
			display: none;
		}

		> figure,
		> .no-photo {
			@include absolute-stretch();
			width: 100%;
			height: 100%;
		}

		> figure {
			border-radius:$r_small $r_small 0 0;
			background: transparent;

			figcaption {
				display: none;
			}
		}

		.no-photo {
			border-radius:$r_small $r_small 0 0;
			background-color:$c_main;
			@include addTransition();
		}

		.badge-price {
			position: absolute;
			color: $c_white;

			strong {
				font-size:20px;
				font-family:$f_spe;
			}

			&.--desktop {
				top: 20px;
				left: 25px;
				font-size: 16px;
				line-height:24px;
				font-weight: 400;
			}

			&.--mobile {
				top: 0;
				left: 0;
				padding: 3px 5px;
				font-size: 10px;
				line-height: 1.3;
				font-weight: 700;
			}
		}

		.btn-favoris {
			position: absolute;
			top: 0;
			right: 0;
			padding: 30px;

			&:after {
				content: "";
				display: block;
				width: 150px;
				height: 150px;
				background-image: url("../images/deco/radial-gradient.svg");
				position: absolute;
				opacity:0.5;
				top: 0;
				right: 0;
				z-index: -1;
				pointer-events: none;

				@include mobile {
					width: 90px;
					height: 90px;
				}
			}
		}

		.date-event{
			&:before{
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				top: 0;
				background: rgb(0,0,0);
				background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
				opacity: .4;
			}

			>*{
				position: relative;
				z-index: 10;
			}

		}
	}

	.caption {
		padding:30px;

		.container {
			display: flex;
			flex-wrap: wrap;
			align-items:center;
			@include ft($f_txt, 12px, 300, $c_txt, 1.25);

			.classement {
				display: inline-block;
				vertical-align: top;
				color:$c_white;
				margin-right: 5px;

				&:first-of-type {
					margin-left: 4px;
				}

				span {
					font-size: 12px;
					line-height: 1;

					@include mobile {
						font-size: 10px;
					}

					&.icon-ico-cle, &.icon-ico-epis {
						position: relative;
						top: 1px;
					}

					&.icon-ico-stars {
						& + .icon-ico-epis, & + .icon-ico-cle {
							margin-left: 5px;
						}
					}

					&.icon-ico-epis {
						@include mobile {
							top: 0;
							margin-left: -3px;
						}
					}
				}

				&.classement-epis {
					@include mobile {
						margin-left: 2px;
					}
				}
			}
		}

		& > * {
			&:last-child {
				margin-bottom: 0;
			}
		}

		.surtitre-card {
			@include ft($f_txt, $s_link_small, 500, $c_white, 18px);
		//	margin: 0 0 5px 0;
			text-transform:uppercase;
		}

		.title-card {
			@include ft($f_hx, $s_small_title, 700, $c_white, 24px);
			display: block;
			font-style:italic;
			margin: 0 0 5px 0;
			@include addTransition();

			@include mobile {
				font-size: 22px;
				line-height: 1.12;
				margin: 0 0 6px 0;
			}
		}

		.btn-plus {
			position:absolute;
			right:30px;
			bottom:-15px;
			visibility: hidden;
			opacity:0;
			transform:rotate(3deg);
			@include addTransition();
		}

		.meta-wrapper {
			justify-content: flex-start;

			.meta {
				color:$c_white;
				background:none;
				font-weight:700;
				border:1px solid rgba($c_white, 0.30);
			}
		}

		.reservation {
			color:$c_white;
			border:1px solid rgba($c_white, 0.30);
			padding: 3px 10px;
			max-height: 23px;
			text-transform:uppercase;
			width: max-content;
			display:flex;
			align-items:center;
			margin-bottom: 5px;
			justify-content: flex-start;
			border-radius:$r_small;
			@include ft($f_txt, $s_link_small, 700, $c_white, 24px);
		}

		.location {
			& + .meta-wrapper {
				margin-top: 7px;
			}
		}
	}

	.print-data{
		display: none;
	}

	.date-event {
		position: absolute;
		bottom:0;
		left: 0;
		right:15px;
		width: 100%;
		padding: 30px;
		z-index: 1;
		line-height: 1.2;
		text-transform:uppercase;
		font-family:$f_spe;
		@include mobile {
			padding: 18px;
		}

		> * {
			color: $c_white;
		}

		.day, .month {
			font-weight: 700;
			font-size: 16px;
			line-height: 1;
		}

		.hour {
			font-weight: 700;
			font-size: 20px;
			line-height: 24px;

			@include mobile {
				font-size: 14px;
			}
		}
	}
}
