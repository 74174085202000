// Overlay - Recherche
// ==========================================================================
.overlay-search {
	max-width: 100%;
	@extend %motif-tour;

	.btn-close {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translate(0, -50%);
		@include min-tablet-landscape {
			right: -30px;
		}
		@include min-desktop {
			right: -75px;
		}
	}

	.search-header {
		padding: 40px 0;
		@include shadow();
		@include mobile {
			padding: 25px 0;
		}

		.container-form {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		form {
			width: 100%;
			display: flex;
			align-items: center;
		}

		button[type="submit"] {
			margin-right: 35px;
			@include desktop {
				margin-right: 80px;
			}
			@include tablet-landscape {
				margin-right: 70px;
			}

			&:after {
				// margin-left: 0;
				//  content: "";
			}

			@include mobile {
				display: none;
			}
		}

		input {
			@extend %appearance;
			font-size: 20px;
			line-height: 24px;
			font-family: $f_txt;
			color: $c_txt;
			padding: 0 55px 0 15px;
			width: 100%;
			box-sizing: border-box;
			@include mobile {
				font-size: 16px;
				line-height: 24px;
				padding: 0 50px 0 10px;
			}

			&::placeholder {
				color: rgba($c_txt, 0.30);
			}
		}

		.results {
			display: inline-flex;
			margin-right: 10px;
			font-size: 16px;
			line-height: 24px;

			@include mobile{
				font-size: 12px;
				line-height: 16px;
				margin-right: 50px;
			}

			.nbresults_value {
				margin-right: 3px;
			}

			&.is-empty {
				display: none;
			}
		}


		.pagination {
			margin-top: 0;
		}
	}

	.listing-container {
		position: relative;
		overflow: auto;
		max-height: calc(100% - 120px);
		@include mobile {
			max-height: calc(100% - 60px);
		}

		.search-results {
			position: relative;
			padding: 30px 0;
			//	margin-left: -20px;
			//	margin-right: -20px;
			min-height: calc(100vh - 144px);

			@include tablet-portrait {
				//	margin-left: -10px;
				//	margin-right: -10px;
			}
			@include mobile {
				padding: 10px 0;
				//	margin-left: 0;
				//	margin-right: 0;
			}

			&:after {
				content: '';
				display: block;
				clear: both;
			}

			.card {
				width: calc(25% - 25px);
				margin: 10px 12.5px;
				float: left;

				&:nth-child(4n+1) {
					clear: left;
				}

				@include tablet-portrait {
					width: calc(33.3333% - 20px);
					margin: 10px 10px;
				}
				@media screen and (max-width: 800px) {
					width: calc(50% - 20px);
					&:nth-child(4n+1) {
						clear: none;
					}
					&:nth-child(odd) {
						clear: left;
					}
				}
				@include mobile {
					width: 100%;
					margin: 5px 0;
					@include card-horizontale();

					.caption {
						background-color: transparent;
					}
				}
			}

			> p, .loading-text p {
				display: block;
				clear: both;
				padding-top: 40px;
				text-align: center;
				font-weight: 700;
				font-size: 18px;
				line-height: 22px;
			}


			p[role="alert"] {
				position: absolute;
				top: 50%;
				left: 0;
				max-width: 953px;
				transform: translateY(-50%);
				width: 100%;
				display: flex;
				flex-direction: column;
				text-align: left;
				@include ft($f_hx, 65px, 900, rgba($c_txt, 0.30), 55px);


				i {
					font-size: 9px;
					border: 1px solid rgba($c_txt, 0.30);
					height: 40px;
					margin-bottom: 10px;
					width: 40px;
					display: flex;
					align-items: center;
					border-radius: 50%;
					justify-content: center;
					color: rgba($c_txt, 0.30);
				}

				@include tablet-portrait {
					font-size: 50px;
					line-height: 50px;
				}

				@include mobile {
					font-size: 24px;
					line-height: 24px;
				}
			}

		}

		// btn load-more
		.pagination {
			margin: 0 !important;
			background-color: transparent;

			.resultats, .pagination-number, .btn-arrow.--prev {
				display: none;
			}

			.--next {
				background-color: transparent;
				display: block;
				margin: 0 auto;
				text-align: center;
				@include mobile {
					width: 100%;
				}

				&:before {
					content: '\e947';
					color: $c_white;
					font-size: 40px;
					@include mobile {
						font-size: 26px;
					}
				}

				@include can-hover {
					transform: translate3d(0, 5px, 0);
				}
			}
		}
	}
}
