.page-blog {
  .listing-articles {
    .post {
      margin-bottom: 0;
    }
  }

  .section {
    .bloc-guides {
      .slide {
        background-color: transparent;
        border: 1px solid $c_main;
        width: 144px;
        height: 144px;
        padding: 10px;

        [class*="icon-"] {
          font-size: 60px;
          line-height: 60px;
        }

        .title {
          color: $c_main;
          font-size: 16px;
          line-height: 18px;
        }

        @include can-hover {
          background-color: $c_main;
          transform: rotate(0deg);
          span[class*="icon-"] {
            color: white;
          }
        }
      }

      .nav-buttons {
        display: none;
      }
    }
  }
}