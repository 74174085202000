@import "variables";

@font-face {
	font-family: '#{$icomoon-font-family}';
	src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?5d9kv7');
	src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?5d9kv7#iefix') format('embedded-opentype'),
	url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?5d9kv7') format('woff2'),
	url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?5d9kv7') format('truetype'),
	url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?5d9kv7') format('woff'),
	url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?5d9kv7##{$icomoon-font-family}') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: '#{$icomoon-font-family}' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-ico-map {
	&:before {
		content: $icon-ico-map;
	}
}
.icon-ico-group {
	&:before {
		content: $icon-ico-group;
	}
}
.icon-ico-check {
	&:before {
		content: $icon-ico-check;
	}
}
.icon-ico-navigation {
	&:before {
		content: $icon-ico-navigation;
	}
}
.icon-ico-phone {
	&:before {
		content: $icon-ico-phone;
	}
}
.icon-ico-chapeau {
	&:before {
		content: $icon-ico-chapeau;
	}
}
.icon-ico-prm {
	&:before {
		content: $icon-ico-prm;
	}
}
.icon-ico-location {
	&:before {
		content: $icon-ico-location;
	}
}
.icon-ico-horloge {
	&:before {
		content: $icon-ico-horloge;
	}
}
.icon-ico-tiktok {
	&:before {
		content: $icon-ico-tiktok;
	}
}
.icon-ico-close-circle {
	&:before {
		content: $icon-ico-close-circle;
	}
}
.icon-ico-arrow-up {
	&:before {
		content: $icon-ico-arrow-up;
	}
}
.icon-ico-menu {
	&:before {
		content: $icon-ico-menu;
	}
}
.icon-ico-tripadvisor {
	&:before {
		content: $icon-ico-tripadvisor;
	}
}
.icon-ico-linkedin {
	&:before {
		content: $icon-ico-linkedin;
	}
}
.icon-ico-person {
	&:before {
		content: $icon-ico-person;
	}
}
.icon-ico-paiement {
	&:before {
		content: $icon-ico-paiement;
	}
}
.icon-ico-document {
	&:before {
		content: $icon-ico-document;
	}
}
.icon-ico-langues {
	&:before {
		content: $icon-ico-langues;
	}
}
.icon-ico-coeur-rempli {
	&:before {
		content: $icon-ico-coeur-rempli;
	}
}
.icon-ico-ampoule {
	&:before {
		content: $icon-ico-ampoule;
	}
}
.icon-ico-moins {
	&:before {
		content: $icon-ico-moins;
	}
}
.icon-ico-plus {
	&:before {
		content: $icon-ico-plus;
	}
}
.icon-ico-credits {
	&:before {
		content: $icon-ico-credits;
	}
}
.icon-ico-email {
	&:before {
		content: $icon-ico-email;
	}
}
.icon-ico-link {
	&:before {
		content: $icon-ico-link;
	}
}
.icon-ico-refresh {
	&:before {
		content: $icon-ico-refresh;
	}
}
.icon-ico-social-share {
	&:before {
		content: $icon-ico-social-share;
	}
}
.icon-ico-filtres {
	&:before {
		content: $icon-ico-filtres;
	}
}
.icon-ico-grille-parapluie {
	&:before {
		content: $icon-ico-grille-parapluie;
	}
}
.icon-ico-grille-cheval {
	&:before {
		content: $icon-ico-grille-cheval;
	}
}
.icon-ico-grille-famille {
	&:before {
		content: $icon-ico-grille-famille;
	}
}
.icon-ico-grille-couple {
	&:before {
		content: $icon-ico-grille-couple;
	}
}
.icon-ico-grille-bateau {
	&:before {
		content: $icon-ico-grille-bateau;
	}
}
.icon-ico-grille-rando {
	&:before {
		content: $icon-ico-grille-rando;
	}
}
.icon-ico-grille-velo {
	&:before {
		content: $icon-ico-grille-velo;
	}
}
.icon-ico-grille-dejeuner {
	&:before {
		content: $icon-ico-grille-dejeuner;
	}
}
.icon-ico-grille-logement {
	&:before {
		content: $icon-ico-grille-logement;
	}
}
.icon-ico-agrandir {
	&:before {
		content: $icon-ico-agrandir;
	}
}
.icon-ico-play {
	&:before {
		content: $icon-ico-play;
	}
}
.icon-ico-velo {
	&:before {
		content: $icon-ico-velo;
	}
}
.icon-ico-rando {
	&:before {
		content: $icon-ico-rando;
	}
}
.icon-ico-printemps {
	&:before {
		content: $icon-ico-printemps;
	}
}
.icon-ico-hiver {
	&:before {
		content: $icon-ico-hiver;
	}
}
.icon-ico-automne {
	&:before {
		content: $icon-ico-automne;
	}
}
.icon-ico-soleil {
	&:before {
		content: $icon-ico-soleil;
	}
}
.icon-ico-repas-emporter {
	&:before {
		content: $icon-ico-repas-emporter;
	}
}
.icon-ico-vegan {
	&:before {
		content: $icon-ico-vegan;
	}
}
.icon-ico-boussole {
	&:before {
		content: $icon-ico-boussole;
	}
}
.icon-ico-jeu-enfant {
	&:before {
		content: $icon-ico-jeu-enfant;
	}
}
.icon-ico-natation {
	&:before {
		content: $icon-ico-natation;
	}
}
.icon-ico-wifi {
	&:before {
		content: $icon-ico-wifi;
	}
}
.icon-ico-animal {
	&:before {
		content: $icon-ico-animal;
	}
}
.icon-ico-restaurant {
	&:before {
		content: $icon-ico-restaurant;
	}
}
.icon-ico-epis {
	&:before {
		content: $icon-ico-epis;
	}
}
.icon-ico-cle {
	&:before {
		content: $icon-ico-cle;
	}
}
.icon-ico-etoile {
	&:before {
		content: $icon-ico-etoile;
	}
}
.icon-ico-agenda {
	&:before {
		content: $icon-ico-agenda;
	}
}
.icon-ico-youtube {
	&:before {
		content: $icon-ico-youtube;
	}
}
.icon-ico-pinterest {
	&:before {
		content: $icon-ico-pinterest;
	}
}
.icon-ico-instagram {
	&:before {
		content: $icon-ico-instagram;
	}
}
.icon-ico-twitter {
	&:before {
		content: $icon-ico-twitter;
	}
}
.icon-ico-facebook {
	&:before {
		content: $icon-ico-facebook;
	}
}
.icon-ico-close {
	&:before {
		content: $icon-ico-close;
	}
}
.icon-ico-telechargement {
	&:before {
		content: $icon-ico-telechargement;
	}
}
.icon-ico-arrow-down {
	&:before {
		content: $icon-ico-arrow-down;
	}
}
.icon-ico-arrow-left-slider {
	&:before {
		content: $icon-ico-arrow-left-slider;
	}
}
.icon-ico-arrow-right-slider {
	&:before {
		content: $icon-ico-arrow-right-slider;
	}
}
.icon-ico-search {
	&:before {
		content: $icon-ico-search;
	}
}
.icon-ico-coeur {
	&:before {
		content: $icon-ico-coeur;
	}
}
.icon-ico-ticket {
	&:before {
		content: $icon-ico-ticket;
	}
}
.icon-ico-nuageux {
	&:before {
		content: $icon-ico-nuageux;
	}
}

