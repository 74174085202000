// Logo du site
.logo {
  figure {
    background: none;
    position: relative;
  }

  --width-logo: 339px;
  --height-logo: 141px;

  @include mobile {
    --width-logo: 254px;
    --height-logo: 105px;
  }

  @include mobile-small {
    --width-logo: 200px;
    --height-logo: 90px;
  }

  &.logo-dark {
    position: absolute;
    z-index: 1;
    //  left: 50%;
    //top: 0;
    // transform: translate(-50%, -50%);

    // fix pour pas que le logo soit flou
    left: calc(50% - (var(--width-logo) / 2));
    top: calc((var(--height-logo) / 3) * -1);

    @include wide {
      top: calc((var(--height-logo) / 2) * -1);
    }

    @include tablet-landscape {
      top: calc((var(--height-logo) / 3) * -1);
    }

    .bg-logo {
      position: relative;
      width: var(--width-logo);
      height: var(--height-logo);
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: $c_sub;
        content: "";
        mask-image: url("../images/deco/bg-logo.png");
        mask-size: 100% 100%;
      }
    }

    img {
      height: 100%;
      @include mobile {
        width: 130px;
      }

      @include mobile-small {
        max-width: 90px;
      }
    }
  }

  &.logo-light {
    padding-bottom: 4px;

    img {
      margin: 0 auto;
    }
  }
}


