.bloc-relation {
	// Styles de base pour les inputs radio qui gèrent l'affichage des onglets
	> input {
		position: absolute;
		top: 0;
		left: 0;
		width: 10px;
		height: 10px;
		opacity: 0;
	}

	@for $i from 0 through 5 {
		@for $counter from 0 through 5 {
			#onglet-item-#{$i}-relation-#{$counter}:checked {
				& ~ .container-onglets label[for='onglet-item-#{$i}-relation-#{$counter}'] {
					font-weight:bold;

					&:before {
						opacity: 1;
						visibility:visible;
						@include addTransition();
					}
				}

				& ~ #container-onglet-#{$i}-relation-#{$counter} {
					display: block;
					opacity: 1;
				}
			}
		}
	}

	.container-onglets {
		display: flex;
		align-items: center;
		margin-bottom: 30px;
		scroll-snap-type: x mandatory;
		overflow-x: auto;
		overflow-y: hidden;
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */


		&::-webkit-scrollbar {
			display: none;
		}

		@include tablet-portrait {
			margin-left: 0;
			margin-right: 0;
			padding-left: 40px;
			padding-right: 40px;
		}
		@include mobile {
			padding-left: 20px;
			padding-right: 20px;
			scroll-padding: 20px;
		}
		// Styles de base pour les inputs radio qui gèrent l'affichage des onglets
		> input {
			position: absolute;
			top: 0;
			left: 0;
			width: 10px;
			height: 10px;
			opacity: 0;
		}

		&:empty {
			display: none;
		}

		label {
			@include ft($f_txt, $s_p, 400, $c_main, 24px);
			margin-right: 30px;
			white-space: nowrap;
			position:relative;
			scroll-snap-align: center;
			text-transform:uppercase;
			padding-bottom:25px;
			cursor: pointer;
			@include addTransition();

			&:last-of-type {
				margin-right: 0;
			}

			&:before {
				content:"";
				position:absolute;
				bottom:2px;
				width:12px;
				border-radius:50%;
				left:50%;
				height:12px;
				background-color:$c_main;
				opacity:0;
				transform: translate(-50%, 0);
				visibility:hidden;
				@include addTransition();
			}

			&:hover, &:focus {
				body:not(.no-hover) & {
					opacity: 1;
				}
			}
		}
	}

	.container-slider-relation {
		display: none;
		padding-bottom: 20px;

		&.scroll-mode-parent {
			margin: 0;
			max-width: none;
		}
	}

	.wrapper:last-of-type {
		&.scroll-mode-parent {
			margin: 0;
			width: 100%;
			max-width: none;
		}
	}

	> .container-nav {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		@include mobile {
			flex-direction: column;
			justify-content: flex-start;
		}

		.btn, .btn-primary, .btn {
			margin-left: auto;
			@include mobile {
				margin-left: 0;
				margin-right: auto;
			}
		}
	}

	.nav-dots {
		@include min-mobile {
			display: none;
		}
	}

	.card {
		width: 320px;
		margin-right: $small-margin;
		@include mobile {
			width: 280px;
			flex-direction: column;
		}

		@include mobile-small {
			width:calc(100% - 20px);
		}

		.image {
			width: 100%;
			height: auto;
		}

		.caption {
			@include mobile {
				width: 100%;
				margin-top: 10px;
			}
		}
	}

	&.bloc-relation-reversed {
		.th-slider {
			@include min-tablet-portrait {
				direction: rtl;
				& > *:first-child {
					margin-left: 20px !important;
				}
				.card {
					margin-right: 0;
					margin-left: 20px;
					direction: ltr;
				}
			}
		}

	}
}