.cover-home {
  position: relative;
  height: 100vh;
  width: 100%;

  max-height: calc(100vh - 105px); // espace en haut (40px) + espace en bas (65px)
  margin: 40px auto 64px auto;
  max-width: calc(100% - 260px); // 130px de chaque côté

  @include wide {
    max-width: calc(100% - 160px);
    margin: 65px auto;
    max-height: calc(100vh - 130px); // espace en haut (65px) + espace en bas (65px)
  }

  @include tablet-portrait {
    margin: 40px auto;
    max-width: calc(100% - 80px);
  }

  @include mobile {
    margin: 20px auto;
    max-width: calc(100% - 40px);
  }

  &:before {
    content: "";
    background-color: $c_sub;
    height: 75%;
    position: absolute;
    right: -130px;
    left: -130px;
    top: -40px;

    @include wide {
      right: -80px;
      left: -80px;
      top: -65px;
    }

    @include tablet-portrait {
      right: -40px;
      left: -40px;
    }
    @include mobile {
      right: -20px;
      left: -20px;
    }
  }


  &.th-slider-wrapper {
    overflow: initial;
  }

  .nav-buttons {
    position: static;
    height: 0;

    button {
      position: absolute;
      top: 50%;
      margin: 0;

      @include tablet-portrait {
        bottom: 100px;
      }

      &:before {
        color: $c_white;
      }

      &:first-of-type {
        left: 40px;
        margin-left: 0;
      }

      &:last-of-type {
        right: 40px;
        margin-right: 0;
      }
    }
  }

  .th-slider {
    height: 100%;

    .image-wysiwyg {
      margin-right: 30px;
      @include desktop {
        margin-right: 20px;
      }
    }

    &.scroll-mode {
      padding-left: 0;
      padding-right: 0;
      scroll-padding: 0;
    }
  }

  .image-wysiwyg {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
    @include addTransition();

    &:first-of-type {
      padding-left: 0;
    }

    &:not(.is-active) {
      @include min-tablet-landscape {
        margin-top: 20px;
        height: calc(100% - 40px) !important;
        @include addTransition();
      }
    }

    figure {
      height: 100%;
    }

    .caption {
      justify-content: center;

      @include mobile {
        margin: 0 auto;
        transform: translateY(-50%);
        width: calc(100% - 80px);
        max-width: 250px;
      }

      @include min-wide {
        .title-h1 {
          font-size: 140px;
          line-height: 130px;
        }
      }

      > * {
        color: $c_white;
      }

      p {
        font-weight: 400;
        margin-bottom: 15px;
      }

      .btn {
        align-self: flex-start;
      }
    }
  }
}