// card Article
.cards-horizontales {
  .post {
    @include card-horizontale();

    .image {
      .no-photo {
        background-color:$c_main;
      }
    }

    .caption {
      position:initial;
      flex-direction: column;
      .meta-wrapper {
        margin-top: 7px;
      }

      &:after {
        content:none;
      }
    }
  }
}

.post {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 20px;
  background-color:$c_bg;
  border-radius:$r_small;
  @include addTransition();

  @include mobile {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &:last-of-type {
    @include mobile {
      margin-bottom: 0;
    }
  }

  &:hover, &:focus {
    body:not(.no-hover) & {
      @include rotate();
      background-color:$c_main;
      color:$c_white;

      .image {
        .no-photo {
          background-color:$c_bg;
          @include addTransition();
        }
      }

      .caption {
        .btn-plus {
          visibility: visible;
          opacity:1;
          @include addTransition();
        }
      }
    }
  }


  .image {
    position: relative;
    border-radius:$r_small $r_small 0 0;
    flex-shrink: 0;
    @include aspect-ratio(540, 366);
    @include addTransition();

    @include mobile {
      width: 100%;
      height: 260px;
      margin-bottom: 0;
    }

    .credits {
      display: none;
    }

    > figure,
    > .no-photo {
      @include absolute-stretch();
      width: 100%;
      height: 100%;
    }

    > figure {
      border-radius:$r_small $r_small 0 0;
      background: transparent;

      figcaption {
        display: none;
      }
    }

    .no-photo {
      border-radius:$r_small $r_small 0 0;
      background-color:$c_bg;
      @include addTransition();
    }

    .btn-favoris {
      position: absolute;
      top: 0;
      right: 0;
      padding: 30px;

      &:after {
        content: "";
        display: block;
        width: 150px;
        height: 150px;
        background-image: url("../images/deco/radial-gradient.svg");
        position: absolute;
        opacity:0.5;
        top: 0;
        right: 0;
        z-index: -1;
        pointer-events: none;

        @include mobile {
          width: 90px;
          height: 90px;
        }
      }
    }
  }


  .caption {
    padding: 30px;
    position: absolute;
    bottom: 0;
    display: flex;
    height: 100%;
    flex-direction: column-reverse;
    left: 0;
    width: 100%;


    .container {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      @include ft($f_txt, 12px, 300, $c_txt, 1.25);
    }

    & > * {
      &:last-child {
        margin-bottom: 0;
      }
    }

    > * {
      z-index: 1;
    }

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 0;
      background: linear-gradient(-180deg, rgba(49, 5, 15, 0), rgba(149, 5, 15, 0.5));
      pointer-events: none;
    }

    .post-cat {
      @include ft($f_txt, $s_link_small, 500, $c_white, 24px);
      text-transform: uppercase;
      position: relative;
    }

    .title-card {
      @include ft($f_hx, $s_h3, 700, $c_white, 1);
      display: block;
      font-style: italic;
      position: relative;
      margin: 0 0 5px 0;

      @include tablet-portrait {
        font-size: 20px;
        line-height: 0.9;
        @include ellipsis(1, 26px);
      }
      @include mobile {
        font-size: 22px;
        line-height: 24px;
      }
      @include mobile-small {
        font-size: 20px;
        line-height: 22px;
      }
    }

    .btn-plus {
      position: absolute;
      right: 30px;
      bottom: -15px;
      visibility: hidden;
      opacity: 0;
      transform: rotate(3deg);
      @include addTransition();
    }

    .meta-wrapper {
      margin-bottom: auto;
      position: relative;
      justify-content: flex-start;

      .meta {
        color: $c_white;
        background: none;
        border: 1px solid rgba($c_white, 0.30);
      }
    }
  }


  // style des post (articles) comme celui d'une card pour le bloc relation et pour la recherche
  &.card {
    .image {
      @include aspect-ratio(320, 190);
      .no-photo {
        background-color:$c_main;
      }
    }

    .caption {
      position: initial;
      height:initial;
      display:block;

      &:after {
        content:none;
      }

      .title-card {
        @include ft($f_hx, $s_small_title, 700, $c_white, 24px);
        display: block;
        font-style:italic;
        margin: 0 0 5px 0;
        @include addTransition();

        @include mobile {
          font-size: 22px;
          line-height: 1.12;
          margin: 0 0 6px 0;
        }
      }

      .meta-wrapper {
        padding-top:5px;
      }
    }

  }
}
