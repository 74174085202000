.bloc-mosaique {
	.container {
		display: flex;
		flex-wrap: wrap;
		@include min-mobile {
			margin-left: -10px;
			margin-right: -10px;
		}
		@include mobile {
			flex-direction: column;
		}
	}
	.image {
		position: relative;
		margin-top: 20px;
		padding: 0 10px;
		@include addTransition();
		@include mobile {
			padding: 0;
			margin-top: 10px;
			margin-bottom: 10px;
		}

		&:hover {
			@include rotate();
		}

		figcaption {
			display: none;
		}
		@include min-mobile {
			width: 50%;
		}
		@include mobile {
			width: 100%;
		}

		&.img-up {
			@include min-mobile {
				margin-top:-263px;
			}
		}

		&.image1, &.image2, &.image3, &.image4 {
			overflow:hidden;
		}

		&.image1, &.image4 {
			max-height:317px;
		}

		&.image2 {
			max-height:602px;
		}

		&.image3 {
			max-height:440px;
		}


		// Remplacement par des fonds vides de même ratio si pas d'image
		&.--is-empty {
			&:hover {
				transform: none;
			}

			&.image1, &.image4 {
				@include aspect-ratio(440, 317);
				height:317px;
				&:before {
					padding-top:0;
					max-height:317px;
				}
				&:after {
					top:0;
					max-height:317px;
				}
			}
			&.image2 {
				@include aspect-ratio(440, 602);
				height:602px;
				&:before {
					padding-top:0;
					max-height:602px;
				}
				&:after {
					top:0;
					max-height:602px;
				}
			}
			&.image3 {
				@include aspect-ratio(440, 440);
				height:440px;
				&:before {
					padding-top:0;
					max-height:440px;
				}
				&:after {
					top:0;
					max-height:440px;
				}
			}
		}
	}


	figure {
		position: relative;
		background: transparent;
		border-radius:$r_small;
		img {
			border-radius:$r_small;
			width: 100%;
			object-fit: cover;
		}
	}

}
