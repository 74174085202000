.motif-parallax {
  overflow: hidden;
  z-index: 0;
  @include desktop {
    display: none;
  }

  &[data-parallax] {
    position: relative;
    background-color: transparent;
    mask-image: none;
    mask-size: 0;
    right: auto;
    top: auto;
    width: auto;
    height: auto;
    pointer-events: none;

    div {
      position: fixed;
      top: 0;
      left: 0;
      will-change: transform;
      opacity: 0;
      width: 1600px;
      background-color: $c_sub;
      mask-image: url("../images/deco/motif-parallax.png");
      mask-size: 100% 100%;
      background-repeat: no-repeat;
      height: 1600px;
      transition: opacity 250ms linear;

      @include wide {
        width: 900px;
        height: 900px;
      }

      &.visible {
        opacity: 1;
        transition: opacity 250ms linear;
      }
    }
  }

  &.--parallax-footer {
    &[data-parallax] {
      div {
        top: -50%;
        left: -1100px;

        @include wide {
          top: 0;
          left: -600px;
        }
      }
    }
  }

  &.--parallax-bloc-relation {
    z-index: -1;

    &[data-parallax] {
      div {
        left: -900px;
        top: auto;
        bottom: 0;

        @include wide {
          left: -600px;
          bottom: 50%;
        }
      }
    }
  }
  &.--parallax-bloc-carte {
    z-index: -1;

    &[data-parallax] {
      div {
        left: -900px;
        top: auto;
        bottom: 0;

        @include wide {
          left: -600px;
          bottom: 50%;
        }
      }
    }
  }


  &.--parallax-bloc-guides {
    z-index: -1;

    &[data-parallax] {
      div {
        left: auto;
        right: -500px;
        width: 850px;
        height: 850px;
        bottom: 100%;
        top: auto;
        mask-image: url("../images/deco/motif-parallax-guides.png");
      }
    }
  }
}


