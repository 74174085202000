.bloc-image-seule {
	display: flex;
	flex-direction: column;

	// ????????? non présent dans le DOM ????
	.wrapper:first-of-type {
		@include desktop {
			margin: 0;
			width: 100%;
			max-width: none;
		}
	}

	figure {
		background-color: transparent;
	}
}